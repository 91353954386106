var render = function render(){var _vm=this,_c=_vm._self._c;return _c('AppLayout',[_c('div',{staticClass:"campaigns-detail-main campaigns-detail-page page",class:`${_vm.$route.name}`},[_c('TitleBreadcrumbs',{attrs:{"databreadcrumbs":_vm.breadcrumbs,"title":_vm.lbls.create_account.title_page}}),(_vm.isShowSkeleton)?_c('a-card',[_c('a-skeleton')],1):_c('div',[_c('a-card',[_c('a-form',{attrs:{"form":_vm.form,"layout":"vertical"}},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-card',[_c('h3',[_vm._v("Account details")]),_c('hr'),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',[_c('template',{slot:"label"},[_c('span',[_vm._v(_vm._s(_vm.lbls.create_account.company_name.name || "Company Name"))])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'company_name',
                          {
                            initialValue: _vm.objDataSave.company_name,
                            rules: [
                              {
                                required: true,
                                message:
                                  _vm.lbls.create_account.company_name.required,
                              },
                              {
                                min: 1,
                                message: _vm.lbls.create_account.company_name.min,
                              },
                            ],
                          },
                        ]),expression:"[\n                          'company_name',\n                          {\n                            initialValue: objDataSave.company_name,\n                            rules: [\n                              {\n                                required: true,\n                                message:\n                                  lbls.create_account.company_name.required,\n                              },\n                              {\n                                min: 1,\n                                message: lbls.create_account.company_name.min,\n                              },\n                            ],\n                          },\n                        ]"}],attrs:{"allow-clear":"","placeholder":_vm.lbls.create_account.company_name.placeholder}})],2)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',[_c('template',{slot:"label"},[_c('span',[_vm._v(_vm._s(_vm.lbls.create_account.owner_email.name || "Owner Email"))])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'owner_email',
                          {
                            initialValue: _vm.objDataSave.owner_email,
                            rules: [
                              {
                                required: true,
                                message:
                                  _vm.lbls.create_account.owner_email.required,
                              },
                              {
                                min: 1,
                                message: _vm.lbls.create_account.owner_email.min,
                              },
                            ],
                          },
                        ]),expression:"[\n                          'owner_email',\n                          {\n                            initialValue: objDataSave.owner_email,\n                            rules: [\n                              {\n                                required: true,\n                                message:\n                                  lbls.create_account.owner_email.required,\n                              },\n                              {\n                                min: 1,\n                                message: lbls.create_account.owner_email.min,\n                              },\n                            ],\n                          },\n                        ]"}],attrs:{"allow-clear":"","placeholder":_vm.lbls.create_account.owner_email.placeholder}})],2)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',[_c('template',{slot:"label"},[_c('span',[_vm._v(_vm._s(_vm.lbls.create_account.providers_type.name || "Providers Type"))])]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'providers_type',
                          {
                            initialValue: _vm.objDataSave.provider_type,
                            rules: [
                              {
                                required: true,
                                message:
                                  _vm.lbls.create_account.providers_type.required,
                              },
                              {
                                min: 1,
                                message:
                                  _vm.lbls.create_account.providers_type.min,
                              },
                            ],
                          },
                        ]),expression:"[\n                          'providers_type',\n                          {\n                            initialValue: objDataSave.provider_type,\n                            rules: [\n                              {\n                                required: true,\n                                message:\n                                  lbls.create_account.providers_type.required,\n                              },\n                              {\n                                min: 1,\n                                message:\n                                  lbls.create_account.providers_type.min,\n                              },\n                            ],\n                          },\n                        ]"}],attrs:{"show-search":"","placeholder":_vm.lbls.create_account.providers_type.placeholder}},[_c('a-select-option',{staticStyle:{"width":"100%"},attrs:{"value":"office_365"}},[_c('img',{staticClass:"mr-1",staticStyle:{"max-width":"16px"},attrs:{"src":_vm.urlImage.office_365}}),_vm._v("Office 365 ")]),_c('a-select-option',{attrs:{"value":"hotmail_or_outlook"}},[_c('img',{staticClass:"mr-1",staticStyle:{"max-width":"16px"},attrs:{"src":_vm.urlImage.microsoft}}),_vm._v("Hotmail/Outlook ")]),_c('a-select-option',{attrs:{"value":"google"}},[_c('img',{staticClass:"mr-1",staticStyle:{"max-width":"16px"},attrs:{"src":_vm.urlImage.google}}),_vm._v("Google ")]),_c('a-select-option',{attrs:{"value":"local_account"}},[_c('img',{staticClass:"mr-1",staticStyle:{"max-width":"16px"},attrs:{"src":_vm.urlImage.local}}),_vm._v(" Local account ")])],1)],2)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',[_c('template',{slot:"label"},[_c('span',[_vm._v(_vm._s(_vm.lbls.create_account.description.name || "Description"))])]),_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'description',
                          {
                            initialValue: _vm.objDataSave.description,
                            rules: [
                              {
                                required: false,
                                message:
                                  _vm.lbls.create_account.description.required,
                              },
                              {
                                min: 1,
                                message: _vm.lbls.create_account.description.min,
                              },
                            ],
                          },
                        ]),expression:"[\n                          'description',\n                          {\n                            initialValue: objDataSave.description,\n                            rules: [\n                              {\n                                required: false,\n                                message:\n                                  lbls.create_account.description.required,\n                              },\n                              {\n                                min: 1,\n                                message: lbls.create_account.description.min,\n                              },\n                            ],\n                          },\n                        ]"}],attrs:{"allow-clear":"","placeholder":_vm.lbls.create_account.description.placeholder,"rows":4}})],2)],1)],1)],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-card',[_c('h3',[_vm._v("Subscription details")]),_c('hr'),_vm._l((_vm.dataPackage),function(item){return _c('a-card',{key:item.key},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',[_c('template',{slot:"label"},[_c('span',[_vm._v(_vm._s(_vm.lbls.create_account.package_list.name || "Packcage"))])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'packcage',
                            {
                              initialValue: _vm.objDataSave.packcage,
                              rules: [
                                {
                                  required: true,
                                  message:
                                    _vm.lbls.create_account.package_list.required,
                                },
                                {
                                  min: 1,
                                  message:
                                    _vm.lbls.create_account.package_list.min,
                                },
                              ],
                            },
                          ]),expression:"[\n                            'packcage',\n                            {\n                              initialValue: objDataSave.packcage,\n                              rules: [\n                                {\n                                  required: true,\n                                  message:\n                                    lbls.create_account.package_list.required,\n                                },\n                                {\n                                  min: 1,\n                                  message:\n                                    lbls.create_account.package_list.min,\n                                },\n                              ],\n                            },\n                          ]"}],attrs:{"allow-clear":"","placeholder":_vm.lbls.create_account.package_list.placeholder}})],2)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',[_c('template',{slot:"label"},[_c('span',[_vm._v(_vm._s(_vm.lbls.create_account.application_id.name || "Application Id"))])]),_vm._l((item.application),function(app,i){return _c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'application_id',
                            {
                              initialValue: _vm.objDataSave.application_id,
                              rules: [
                                {
                                  required: true,
                                  message:
                                    _vm.lbls.create_account.application_id
                                      .required,
                                },
                                {
                                  min: 1,
                                  message:
                                    _vm.lbls.create_account.application_id.min,
                                },
                              ],
                            },
                          ]),expression:"[\n                            'application_id',\n                            {\n                              initialValue: objDataSave.application_id,\n                              rules: [\n                                {\n                                  required: true,\n                                  message:\n                                    lbls.create_account.application_id\n                                      .required,\n                                },\n                                {\n                                  min: 1,\n                                  message:\n                                    lbls.create_account.application_id.min,\n                                },\n                              ],\n                            },\n                          ]"}],key:app.key + i,class:{ 'mt-3': i > 0 },attrs:{"allow-clear":"","placeholder":_vm.lbls.create_account.application_id.placeholder}})})],2)],1)],1)],1)})],2)],1)],1)],1)],1)],1),(_vm.isModalProcess)?_c('div',[_c('ModalProcess',{attrs:{"modalprocess":_vm.modalprocess},on:{"onsearch":function($event){return _vm.onSearchModal($event)},"actionModal":function($event){return _vm.actionModal($event)}}})],1):_vm._e(),(_vm.isModalResponse)?_c('div',[_c('ModalAlert',{attrs:{"modalalert":_vm.modalAlert,"alertdata":_vm.alertData},on:{"handlemodalok":_vm.handlemodalOk}})],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }