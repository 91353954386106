<template>
  <div class="box-loader" :class="{ loaded: !isShow }">
    <lottie-vue-player
      :src="Config.themeUrl + '/json/loading.json'"
      :background-color="'transparent'"
      :loop="true"
      :autoplay="true"
      class="loaderjson"
    />
  </div>
</template>
<script>
import { config } from "@/config/bzbsconfig.js";

export default {
  name: "Loading",
  props: {
    isShow: Boolean,
  },
  data() {
    return {
      Config: config,
    };
  },
};
</script>
<style lang="scss" scope>
.box-loader {
  display: none;
}
</style>
