<template>
  <AppLayout>
    <div
      :class="`${$route.name}`"
      class="campaigns-detail-main campaigns-detail-page page"
    >
      <TitleBreadcrumbs
        :databreadcrumbs="breadcrumbs"
        :title="lbls.create_account.title_page"
      />
      <a-card v-if="isShowSkeleton">
        <a-skeleton />
      </a-card>
      <div v-else>
        <a-card>
          <a-form :form="form" layout="vertical">
            <a-row :gutter="16">
              <a-col :span="12">
                <a-card>
                  <h3>Account details</h3>
                  <hr />
                  <a-row :gutter="16">
                    <a-col :span="24">
                      <a-form-item>
                        <template slot="label">
                          <span>{{
                            lbls.create_account.company_name.name ||
                            "Company Name"
                          }}</span>
                        </template>
                        <a-input
                          v-decorator="[
                            'company_name',
                            {
                              initialValue: objDataSave.company_name,
                              rules: [
                                {
                                  required: true,
                                  message:
                                    lbls.create_account.company_name.required,
                                },
                                {
                                  min: 1,
                                  message: lbls.create_account.company_name.min,
                                },
                              ],
                            },
                          ]"
                          allow-clear
                          :placeholder="
                            lbls.create_account.company_name.placeholder
                          "
                        />
                      </a-form-item>
                    </a-col>
                  </a-row>
                  <a-row :gutter="16">
                    <a-col :span="24">
                      <a-form-item>
                        <template slot="label">
                          <span>{{
                            lbls.create_account.owner_email.name ||
                            "Owner Email"
                          }}</span>
                        </template>
                        <a-input
                          v-decorator="[
                            'owner_email',
                            {
                              initialValue: objDataSave.owner_email,
                              rules: [
                                {
                                  required: true,
                                  message:
                                    lbls.create_account.owner_email.required,
                                },
                                {
                                  min: 1,
                                  message: lbls.create_account.owner_email.min,
                                },
                              ],
                            },
                          ]"
                          allow-clear
                          :placeholder="
                            lbls.create_account.owner_email.placeholder
                          "
                        />
                      </a-form-item>
                    </a-col>
                  </a-row>
                  <a-row :gutter="16">
                    <a-col :span="24">
                      <a-form-item>
                        <template slot="label">
                          <span>{{
                            lbls.create_account.providers_type.name ||
                            "Providers Type"
                          }}</span>
                        </template>
                        <a-select
                          v-decorator="[
                            'providers_type',
                            {
                              initialValue: objDataSave.provider_type,
                              rules: [
                                {
                                  required: true,
                                  message:
                                    lbls.create_account.providers_type.required,
                                },
                                {
                                  min: 1,
                                  message:
                                    lbls.create_account.providers_type.min,
                                },
                              ],
                            },
                          ]"
                          show-search
                          :placeholder="
                            lbls.create_account.providers_type.placeholder
                          "
                        >
                          <a-select-option
                            value="office_365"
                            style="width: 100%"
                          >
                            <img
                              :src="urlImage.office_365"
                              style="max-width: 16px"
                              class="mr-1"
                            />Office 365
                          </a-select-option>
                          <a-select-option value="hotmail_or_outlook">
                            <img
                              :src="urlImage.microsoft"
                              style="max-width: 16px"
                              class="mr-1"
                            />Hotmail/Outlook
                          </a-select-option>
                          <a-select-option value="google">
                            <img
                              :src="urlImage.google"
                              style="max-width: 16px"
                              class="mr-1"
                            />Google
                          </a-select-option>
                          <a-select-option value="local_account">
                            <img
                              :src="urlImage.local"
                              style="max-width: 16px"
                              class="mr-1"
                            />
                            Local account
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                  </a-row>
                  <a-row :gutter="16">
                    <a-col :span="24">
                      <a-form-item>
                        <template slot="label">
                          <span>{{
                            lbls.create_account.description.name ||
                            "Description"
                          }}</span>
                        </template>
                        <a-textarea
                          v-decorator="[
                            'description',
                            {
                              initialValue: objDataSave.description,
                              rules: [
                                {
                                  required: false,
                                  message:
                                    lbls.create_account.description.required,
                                },
                                {
                                  min: 1,
                                  message: lbls.create_account.description.min,
                                },
                              ],
                            },
                          ]"
                          allow-clear
                          :placeholder="
                            lbls.create_account.description.placeholder
                          "
                          :rows="4"
                        />
                      </a-form-item>
                    </a-col>
                  </a-row>
                </a-card>
              </a-col>
              <a-col :span="12">
                <a-card>
                  <h3>Subscription details</h3>
                  <hr />
                  <a-card v-for="item in dataPackage" :key="item.key">
                    <a-row :gutter="16">
                      <a-col :span="12">
                        <a-form-item>
                          <template slot="label">
                            <span>{{
                              lbls.create_account.package_list.name ||
                              "Packcage"
                            }}</span>
                          </template>
                          <a-input
                            v-decorator="[
                              'packcage',
                              {
                                initialValue: objDataSave.packcage,
                                rules: [
                                  {
                                    required: true,
                                    message:
                                      lbls.create_account.package_list.required,
                                  },
                                  {
                                    min: 1,
                                    message:
                                      lbls.create_account.package_list.min,
                                  },
                                ],
                              },
                            ]"
                            allow-clear
                            :placeholder="
                              lbls.create_account.package_list.placeholder
                            "
                          />
                        </a-form-item>
                      </a-col>
                      <a-col :span="12">
                        <a-form-item>
                          <template slot="label">
                            <span>{{
                              lbls.create_account.application_id.name ||
                              "Application Id"
                            }}</span>
                          </template>
                          <a-input
                            v-for="(app, i) in item.application"
                            :key="app.key + i"
                            v-decorator="[
                              'application_id',
                              {
                                initialValue: objDataSave.application_id,
                                rules: [
                                  {
                                    required: true,
                                    message:
                                      lbls.create_account.application_id
                                        .required,
                                  },
                                  {
                                    min: 1,
                                    message:
                                      lbls.create_account.application_id.min,
                                  },
                                ],
                              },
                            ]"
                            :class="{ 'mt-3': i > 0 }"
                            allow-clear
                            :placeholder="
                              lbls.create_account.application_id.placeholder
                            "
                          />
                        </a-form-item>
                      </a-col>
                    </a-row>
                  </a-card>
                </a-card>
              </a-col>
            </a-row>
          </a-form>
        </a-card>
      </div>
      <!-- Modal -->
      <div v-if="isModalProcess">
        <ModalProcess
          :modalprocess="modalprocess"
          @onsearch="onSearchModal($event)"
          @actionModal="actionModal($event)"
        >
        </ModalProcess>
      </div>
      <div v-if="isModalResponse">
        <ModalAlert
          :modalalert="modalAlert"
          :alertdata="alertData"
          @handlemodalok="handlemodalOk"
        >
        </ModalAlert>
      </div>
    </div>
  </AppLayout>
</template>
<script>
import _ from "lodash";
import Vue from "vue";
import AppLayout from "@/components/Layout/LayoutMaster.vue";
import manageCrossAccountAPI from "@/core/Page/ManageCrossAccountAPI";
import manageCrossAPI from "@/core/Page/ManageCrossApplicationAPI";
import ModalProcess from "@/module/Cross/ManageCrossAccountDetail/components/Modal/ModalProcess.vue";
import ModalAlert from "@/components/Modal/Alertmodal.vue";

export default {
  name: "ManageUser",
  components: {
    AppLayout,
    ModalProcess,
    ModalAlert,
  },
  data() {
    return {
      form: this.$form.createForm(this),
      objDataSave: {},
      urlImage: {
        office_365:
          Vue.bzbsConfig.extBlobUrl +
          "/custom-policies/templates-v1/src/idp_logos/colored/office-365.svg?v=" +
          new Date().getTime(),
        microsoft:
          Vue.bzbsConfig.extBlobUrl +
          "/custom-policies/templates-v1/src/idp_logos/colored/microsoft.svg?v=" +
          new Date().getTime(),
        google:
          Vue.bzbsConfig.extBlobUrl +
          "/custom-policies/templates-v1/src/idp_logos/colored/google.svg?v=" +
          new Date().getTime(),
        local:
          Vue.bzbsConfig.extBlobUrl +
          "/custom-policies/templates-v1/src/idp_logos/colored/local.svg?v=" +
          new Date().getTime(),
      },
      dataPackage: [
        {
          key: 1,
          packcage: "test01",
          application: [{ key: 1 }, { key: 2 }],
        },
        {
          key: 2,
          packcage: "test02",
          application: [{ key: 1 }],
        },
      ],
      permission_key: "cross",
      action_key: "existing-view",
      data: [],
      dataAccount: [],
      breadcrumbs: [],
      totalList: 0,
      nameSearch: "",
      nameSearchAccount: "",
      isShowSkeleton: false,
      isModalResponse: false,
      isModalProcess: false,
      idModal: "",
      isDelete: false,
      modalbtnok: "",
      contentModal: "",
      page: 0,
      page_size: 25,
      accountName: "",
      page_acc: 0,
      page_size_acc: 200,
      conditionTable: {},
      alertData: {},
      modalprocess: {
        visible: false,
        title: "",
        key: "",
        message: "",
        okText: "",
        cancelText: "",
      },
      modalAlert: {
        visible: false,
        id: "",
        title: "",
        message: "",
        okText: "",
        cancelText: "",
      },
    };
  },
  watch: {
    lbls() {
      this.setBreadcrumbs();
    },
    "$store.state.menu"() {
      this.handlePermissionPage(this.permission_key, this.action_key);
    },
  },
  created() {
    this.setBreadcrumbs();
    this.init();
  },
  methods: {
    init() {
      this.handleLoading(false);
    },
    setBreadcrumbs() {
      this.breadcrumbs = [];
      this.breadcrumbs.push({
        breadcrumbName: this.lbls.menu.crossaccount,
        name: "ManageCrossAccount",
      });
      this.breadcrumbs.push({
        breadcrumbName: this.lbls.create_account.title_page,
        name: "ManageCrossAccount",
      });
      console.log(this.breadcrumbs);
    },
    setCallGetList() {
      this.isShowSkeleton = true;
      this.page = 0;
      this.page_size = this.page_size;
      this.data = [];
      this.callGetList();
    },
    setCallGetListAccount() {
      this.page_acc = 0;
      this.page_size_acc = this.page_size_acc;
      this.dataAccount = [];
      this.callGetListAccount();
    },
    onSearch(searchData) {
      let text = searchData;
      if (text != this.nameSearch && !this.isShowSkeleton) {
        this.isShowSkeleton = true;
        this.nameSearch = text != undefined ? text : "";
        this.setCallGetList();
      } else {
        return;
      }
    },
    onSearchModal(searchData) {
      let text = searchData;
      if (text != this.nameSearchAccount) {
        this.nameSearchAccount = text != undefined ? text : "";
        this.setCallGetListAccount();
      } else {
        return;
      }
    },
    onSearchTable(data) {
      this.totalList = data.totalList >= 0 ? data.totalList : this.totalList;
      this.conditionTable.isFilters = data.isFilters;
    },
    callGetList() {
      return new Promise((resolve) => {
        let isCallDetailSuccess = false;
        this.page = this.page + 1;
        let params = {
          account_id: this.exAccount.account.id,
          cross_account_id: this.$route.query.id,
          page: this.page,
          page_size: this.page_size,
        };
        if (this.nameSearch) {
          params.filter = this.nameSearch;
        }
        manageCrossAccountAPI
          .getSearchApplication(params)
          .then((res) => {
            if (res.data && res.data.result) {
              isCallDetailSuccess = true;
              let item = res.data.result.items;
              let paging = res.data.result.paging;
              if (item.length > 0) {
                _.forEach(res.data.result.items, (item) => {
                  item.id = item.application.id;
                });
                this.data.push(...res.data.result.items);
              }
              this.totalList = this.data.length;
              this.conditionTable = {
                hasMoreData: paging.has_more_data,
                isFilters: false,
              };
              this.product = res.data.result;
              resolve(isCallDetailSuccess);
            }
            resolve(isCallDetailSuccess);
            this.handleLoading(false);
            this.isShowSkeleton = false;
          })
          .catch((error) => {
            this.idModal = "error";
            this.handleLoading(true);
            this.errorApi(error);
            resolve(isCallDetailSuccess);
          });
      });
    },
    callGetListAccount() {
      this.page_acc = this.page_acc + 1;
      let params = {
        account_id: this.exAccount.account.id,
        page: this.page_acc,
        page_size: this.page_size_acc,
      };
      if (this.nameSearchAccount) {
        params.filter = this.nameSearchAccount;
      }
      manageCrossAccountAPI
        .getSearch(params)
        .then((res) => {
          this.dataAccount = [];
          if (res.data && res.data.result) {
            let items = res.data.result.items;
            // let paging = res.data.result.paging;
            if (items.length > 0) {
              _.forEach(items, (item) => {
                item.id = item.account.id;
                item.is_select = false;
                if (item.owner_user) {
                  item.owner_user.name = item.owner_user.FirstName
                    ? item.owner_user.FirstName
                    : "";
                  if (item.owner_user.LastName) {
                    item.owner_user.name =
                      item.owner_user.name + " " + item.owner_user.LastName;
                  }
                }
              });
              this.dataAccount.push(...items);
            }
            this.showModalProcess();
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callPostRelocate(value) {
      this.handleLoading(true);
      var valueSave = {
        account_id: this.exAccount.account.id,
        source_account_id: this.$route.query.id,
        source_application_id: this.objRecord.id,
        target_account_id: value.target_account_id,
        target_package_id: value.target_package_id,
      };
      var valueSaveJson = JSON.stringify(valueSave);
      manageCrossAPI
        .postSaveRelocate(valueSaveJson)
        .then((res) => {
          if (res.status == "200" && res.data.result) {
            this.idModal = "success";
            this.closeModalProcess();
            setTimeout(() => {
              this.titleModal = this.lbls.role_page.modal.title_success;
              this.contentModal =
                this.lbls.crossaccountdetail_page.modal_process.title_success;
              this.showModalSuccess();
            }, 500);
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    handlemodalOk() {
      if (this.isDelete) {
        this.postDeleteData();
      }
      this.isModalResponse = false;
    },
    handleEdit(record) {
      this.handleLoading(true);
      this.objRecord = {};
      this.modalprocess = {};
      this.objRecord = record;
      this.titleModalProcess =
        this.lbls.crossaccountdetail_page.modal_process.title_modal;
      this.setCallGetListAccount();
    },
    showModalProcess() {
      this.isModalProcess = true;
      this.modalprocess = {
        visible: true,
        title: this.titleModalProcess,
        record: this.objRecord,
        key: "crossaccountdetail_page.",
        data: this.dataAccount,
      };
      this.handleLoading(false);
    },
    closeModalProcess() {
      this.isModalProcess = false;
    },
    showModalSuccess() {
      this.handleLoading(false);
      let secondsToGo = this.$store.state.secondstogo;
      const modal = this.$success({
        centered: true,
        autoFocusButton: null,
        title: this.contentModal,
        okType: "default",
        okText: `Ok (${secondsToGo})`,
      });
      const interval = setInterval(() => {
        secondsToGo -= 1;
        modal.update({
          okText: `Ok (${secondsToGo})`,
        });
      }, 1000);
      setTimeout(() => {
        clearInterval(interval);
        modal.destroy();
      }, secondsToGo * 1000);
      this.init();
    },
    actionModal(value) {
      if (value == "close") {
        this.isModalProcess = false;
      } else {
        this.callPostRelocate(value);
      }
    },
  },
};
</script>
<style lang="scss">
@import "@/style/_mixin.scss";
</style>
