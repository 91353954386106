<template>
  <div>
    <a-modal
      v-model="modalprocess.visible"
      centered
      class="modal-with-content custom-content"
      :closable="false"
      :mask-closable="false"
    >
      <template slot="title">
        <div>
          <span>{{ modalprocess.title }}</span>
          <span class="text-copy-custom"> {{ modalprocess.datacopy }}</span>
          <span v-if="modalprocess.isEdit" class="float-right">
            <span class="title-status">
              {{ lbls.role_page.tbl_colum_status }}
            </span>
            <span>
              <a-tooltip>
                <template slot="title">
                  <span v-if="objDataSave.is_active">
                    {{ lbls.role_page.title_status_active }}
                  </span>
                  <span v-else>
                    {{ lbls.role_page.title_status_inactive }}
                  </span>
                </template>
                <a-switch
                  :checked="objDataSave.is_active"
                  @change="changeSwitch"
                />
              </a-tooltip>
            </span>
          </span>
        </div>
      </template>
      <div>
        <a-form :form="form" layout="vertical">
          <a-form-item :validate-status="validate()">
            <template slot="label">
              <span>{{ lbls.role_page.input_name.name }}</span>
            </template>
            <a-input
              v-decorator="[
                'name',
                {
                  initialValue: objDataSave.name,
                  rules: [
                    {
                      required: true,
                      message: lbls.role_page.input_name.required,
                    },
                    {
                      min: 1,
                      message: lbls.role_page.input_name.min,
                    },
                  ],
                },
              ]"
              allow-clear
              :max-length="maxLengthName"
              :placeholder="lbls.role_page.input_name.placeholder"
              @change="changeName"
              @keyup="countChar"
            />
          </a-form-item>
          <div v-if="validate() == 'warning'" class="text-error-name-custom">
            {{
              lbls.role_page.input_name.duplicate
                ? lbls.role_page.input_name.duplicate
                : "Please change your role name, This role name is duplicate."
            }}
          </div>
          <p class="total-char" href="">
            {{
              objDataSave.name
                ? objDataSave.name.length
                : totalCharName.toLocaleString()
            }}
            /
            {{ maxLengthName.toLocaleString() }}
          </p>
          <a-form-item
            :validate-status="checkError('description') ? 'error' : ''"
            :help="checkError('description') || ''"
          >
            <template slot="label">
              <span>{{ lbls.role_page.input_description.name }}</span>
            </template>
            <a-textarea
              v-decorator="[
                'description',
                {
                  initialValue: objDataSave.description,
                  rules: [
                    {
                      required: true,
                      message: lbls.role_page.input_description.required,
                    },
                    {
                      min: 1,
                      message: lbls.role_page.input_description.min,
                    },
                  ],
                },
              ]"
              allow-clear
              :placeholder="lbls.role_page.input_description.placeholder"
              :max-length="maxLengthDescription"
              :rows="4"
              @keyup="countChar"
            />
          </a-form-item>
          <p class="total-char" href="">
            {{
              objDataSave.description
                ? objDataSave.description.length
                : totalCharDescription.toLocaleString()
            }}
            /
            {{ maxLengthDescription.toLocaleString() }}
          </p>
        </a-form>
        <div v-if="modalprocess.isCopy">
          <label>{{ lbls.role_page.drw_title_copy_data }}</label>
          <div class="show-box-copy mt-2">
            <a-card
              :class="{ 'custom-card-active': objDataSave.copy_permission }"
              @click="onChange('permission')"
            >
              <template slot="title">
                <a-checkbox :checked="objDataSave.copy_permission" />
                <span>
                  {{ lbls.role_page.drw_title_copy_permission }}
                </span>
              </template>
            </a-card>
            <a-card
              :class="{ 'custom-card-active': objDataSave.copy_user }"
              @click="onChange('user')"
            >
              <template slot="title">
                <a-checkbox :checked="objDataSave.copy_user" />
                <span>
                  {{ lbls.role_page.drw_title_copy_user }}
                </span>
              </template>
            </a-card>
          </div>
          <div v-if="modalprocess.isCopyNew" class="show-box-copy">
            <a-card
              :class="{ 'custom-card-active': objDataSave.copy_application }"
              @click="onChange('application')"
            >
              <template slot="title">
                <a-checkbox :checked="objDataSave.copy_application" />
                <span>
                  {{ lbls.role_page.drw_title_copy_application }}
                </span>
              </template>
            </a-card>
            <a-card
              :class="{
                'custom-card-active': objDataSave.copy_excould_application,
                'custom-card-disabled': !objDataSave.copy_application,
              }"
              @click="
                objDataSave.copy_application
                  ? onChange('excould_application')
                  : null
              "
            >
              <template slot="title">
                <a-checkbox
                  :disabled="!objDataSave.copy_application"
                  :checked="objDataSave.copy_excould_application"
                />
                <span>
                  {{ lbls.role_page.drw_title_copy_excould_application }}
                </span>
              </template>
            </a-card>
          </div>
        </div>
      </div>
      <template slot="footer">
        <a-button @click="handleClose">
          {{
            modalprocess.cancelText
              ? modalprocess.cancelText
              : lbls.role_page.btn.cancel
          }}
        </a-button>
        <a-button type="primary" :disabled="disabledok" @click="handleSave">
          {{
            modalprocess.okText ? modalprocess.okText : lbls.role_page.btn.save
          }}
        </a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import _ from "lodash";
export default {
  name: "ManageProduct",
  props: {
    modalprocess: {
      type: Object,
    },
    dataobjselect: {
      type: Object,
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
      totalCharName: 0,
      maxLengthName: 100,
      totalCharDescription: 0,
      maxLengthDescription: 4000,
      disabledok: true,
      objDataSave: {},
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.objDataSave = _.cloneDeep(this.dataobjselect);
      if (this.modalprocess.isCopy) {
        this.objDataSave.copy_user = false;
        this.objDataSave.copy_permission = false;
        this.objDataSave.copy_application = false;
        this.objDataSave.copy_excould_application = false;
      }
    },
    handleClose() {
      this.modalprocess.visible = false;
      this.$emit("actionModal", "close");
    },
    handleSave() {
      this.$emit("actionModal", this.objDataSave);
    },
    changeSwitch(checked) {
      this.disabledok = true;
      this.objDataSave.is_active = checked;
      this.checkBTN();
    },
    checkError(fild) {
      const { getFieldError, isFieldTouched } = this.form;
      return isFieldTouched(fild) && getFieldError(fild);
    },
    changeName(fild) {
      if (fild.type == "click") {
        this.objDataSave.name = fild.target.value;
      }
    },
    validate() {
      const { getFieldError } = this.form;
      let statuserr = "";
      if (getFieldError("name")) {
        statuserr = "error";
      }
      if (
        this.modalprocess.isCopy &&
        this.objDataSave.name &&
        this.objDataSave.name == this.dataobjselect.name
      ) {
        statuserr = "warning";
      }
      return statuserr;
    },
    countChar(e) {
      this.disabledok = true;
      if (e.target.id == "name") {
        this.objDataSave.name = e.target.value;
        this.totalCharName = e.target.value.length;
      }
      if (e.target.id == "description") {
        this.objDataSave.description = e.target.value;
        this.totalCharDescription = e.target.value.length;
      }
      e.preventDefault();
      this.form.validateFields((err) => {
        if (!err) {
          this.checkBTN();
        }
      });
    },
    checkBTN() {
      if (this.modalprocess.isCreate) {
        this.disabledok = false;
      }
      if (this.modalprocess.isEdit) {
        let newData = {
          id: this.objDataSave.id,
          name: this.objDataSave.name,
          description: this.objDataSave.description,
          is_active: this.objDataSave.is_active,
        };
        newData = JSON.stringify(newData);
        if (newData != this.dataobjselect.dataEditForCheck) {
          this.disabledok = false;
        }
      }
      if (this.modalprocess.isCopy) {
        let newData = {
          name: this.objDataSave.name,
          description: this.objDataSave.description,
        };
        if (newData.name != this.dataobjselect.dataEditForCheck.name) {
          this.disabledok = false;
        }
      }
    },
    onChange(checkedValues) {
      let values = checkedValues;
      if (values == "user") {
        this.objDataSave.copy_user = !this.objDataSave.copy_user;
      }
      if (values == "permission") {
        this.objDataSave.copy_permission = !this.objDataSave.copy_permission;
      }
      if (values == "application") {
        this.objDataSave.copy_application = !this.objDataSave.copy_application;
        if (!this.objDataSave.copy_application) {
          this.objDataSave.copy_excould_application = false;
        }
      }
      if (values == "excould_application") {
        this.objDataSave.copy_excould_application =
          !this.objDataSave.copy_excould_application;
      }
      console.log(this.objDataSave, " this.objDataSave");
      this.$forceUpdate();
    },
  },
};
</script>
<style lang="scss">
@import "@/style/_mixin.scss";
.custom-content {
  .show-box-copy {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .ant-card {
      border-radius: 8px;
      width: 48%;
      cursor: pointer;
      .ant-checkbox {
        margin-top: -3px;
        margin-right: 6px;
      }
    }
    .ant-card-head {
      border-bottom: 0;
    }
  }
  .custom-card-active {
    &.ant-card {
      background: var(--primary-gold-bzb-gold-9);
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.06);
    }
    &.ant-card-bordered {
      border: 1px solid var(--primary-gold-bzb-primary-bzb);
    }
  }
  .custom-card-disabled {
    cursor: no-drop !important;
    background-color: $color-eee;
  }
  .show-checkbox-copy {
    display: inline-block;
    .ant-form-item-label {
      padding: 0;
      label::after {
        display: none;
      }
    }
    .ant-form-item-control-wrapper {
      margin-top: -16px;
    }
  }
  .text-error-name-custom {
    color: #faad14;
    position: absolute;
    margin-top: -16px;
  }
  .text-copy-custom {
    color: var(--primary-gold-bzb-primary-bzb);
  }
  .ant-input-affix-wrapper {
    line-height: inherit;
  }
  .ant-form-vertical .ant-form-item-label,
  .ant-form-vertical .ant-form-item {
    padding: 0;
  }
  textarea.ant-input {
    margin-bottom: 0;
  }
  .total-char {
    margin-top: -16px;
  }
}
</style>
