import Vue from "vue";
import { store } from "./store";
import VueCookies from "vue-cookies";
import Antd from "ant-design-vue/lib";
import App from "./App.vue";
import router from "./router";
import Cache from "@/helper/Cache.js";
import Locale from "@/helper/locale/locale.js";
import AccountHelper from "./helper/AccountHelper";
import CommonMixin from "@/mixin/CommonMixin";
import ErrorApiMixin from "@/mixin/ErrorApiMixin.js";
import LottieVuePlayer from "@lottiefiles/vue-lottie-player";
import TitleBreadcrumbs from "@/components/Breadcrumbs/TitleBreadcrumbs.vue";
Vue.config.productionTip = false; // Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS; // Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "bootstrap/scss/bootstrap.scss";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap-vue/dist/bootstrap-vue-icons.min.css"; // import "ant-design-vue/dist/antd.min.css";
import "ant-design-vue/dist/antd.less";
import BzbsApi from "@/core/Http/BzbsApi.js";
import Config from "@/config/vueconfig.js";
Vue.use(Antd);
Vue.use(VueCookies);
Vue.use(Config);
Vue.use(LottieVuePlayer);
Vue.component("TitleBreadcrumbs", TitleBreadcrumbs);
Vue.mixin(CommonMixin);
Vue.mixin(ErrorApiMixin);
new Vue({
  router,
  store,
  i18n,
  created() {
    Vue.initFinished = false;
    if (Cache.get("BzbsLocale")) {
      Locale.getLbl();
      AccountHelper.initial();
    } else {
      Cache.set("BzbsLocale", "en");
      Locale.getLbl();
      AccountHelper.initial();
    }
    var url;
    if (window.location.hostname == "localhost") {
      url = "https://permission-management.buzzebees-dev.com/api/version";
    } else {
      url = window.location.origin + "/api/version";
    }
    let errorArrayAPI = {};
    let errorArrayMSAL = {};
    BzbsApi.connectBzbsGetBlob(url)
      .then((res) => {
        var responseApp = res.data.app_setting;
        let currentAppVersion = "";
        if (responseApp) {
          if (responseApp.app_name) {
            Vue.bzbsConfig.appName = responseApp.app_name;
          }
          if (responseApp.version) {
            currentAppVersion = responseApp.version;
          }
        }
        let appVersion = Cache.get("appVersion");
        if (
          responseApp.UseConfigPortal &&
          (!appVersion || appVersion < currentAppVersion)
        ) {
          Cache.set("appVersion", responseApp.version);
          var responseAPI = res.data.api_setting;
          var responseMSAL = res.data.msal_config;
          let catchConfig = {};
          if (responseAPI) {
            Object.keys(responseAPI).forEach((i) => {
              if (responseAPI[i]) {
                catchConfig[i] = responseAPI[i];
              } else {
                errorArrayAPI[i] = i;
              }
            });
          }
          if (responseMSAL) {
            Object.keys(responseMSAL).forEach((i) => {
              if (responseMSAL[i]) {
                catchConfig[i] = responseMSAL[i];
              } else {
                errorArrayMSAL[i] = i;
              }
            });
          }
          if (
            (Object.keys(errorArrayAPI).length > 0 ||
              Object.keys(errorArrayMSAL).length > 0) &&
            window.location.pathname !== "/errorconfig"
          ) {
            this.$router.push({
              name: "errorconfig",
              params: { APISetting: errorArrayAPI, MSALConfig: errorArrayMSAL },
            });
          } else {
            // Cache.set("bzbsConfig", catchConfig);
            if (responseAPI) {
              if (responseAPI.apiBaseUrl) {
                Vue.bzbsConfig.apiBaseUrl = responseAPI.apiBaseUrl;
              }
              if (responseAPI.apiVersion) {
                Vue.bzbsConfig.apiVersion = responseAPI.apiVersion;
              }
              if (responseAPI.bzbsBlobUrl) {
                Vue.bzbsConfig.bzbsBlobUrl = responseAPI.bzbsBlobUrl;
              }
            }
            if (responseMSAL) {
              if (responseMSAL.tokenRequest) {
                if (responseMSAL.tokenRequest.forceRefresh != null) {
                  Vue.bzbsConfig.tokenRequest.forceRefresh =
                    responseMSAL.tokenRequest.forceRefresh;
                }
              }
              if (responseMSAL.b2cScopes) {
                Vue.bzbsConfig.tokenRequest.scopes = [responseMSAL.b2cScopes];
                if (responseMSAL.loginRequest) {
                  if (responseMSAL.loginRequest.scopes) {
                    Vue.bzbsConfig.loginRequest.scopes = [
                      responseMSAL.loginRequest.scopes,
                      responseMSAL.b2cScopes,
                    ];
                  }
                }
              }
              if (responseMSAL.b2cPolicies) {
                if (responseMSAL.b2cPolicies.authorities) {
                  let authorities = responseMSAL.b2cPolicies.authorities;
                  if (authorities.signUpSignIn) {
                    Vue.bzbsConfig.b2cPolicies.authorities.signUpSignIn.authority =
                      authorities.signUpSignIn;
                    Vue.bzbsConfig.msalConfig.auth.authority =
                      responseMSAL.b2cPolicies.authorities.signUpSignIn;
                  }
                  if (authorities.editProfile) {
                    Vue.bzbsConfig.b2cPolicies.authorities.editProfile.authority =
                      authorities.editProfile;
                  }
                  if (authorities.changePassword) {
                    Vue.bzbsConfig.b2cPolicies.authorities.changePassword.authority =
                      authorities.changePassword;
                  }
                }
                if (responseMSAL.b2cPolicies.names) {
                  let names = responseMSAL.b2cPolicies.names;
                  if (names.signUpSignIn) {
                    Vue.bzbsConfig.b2cPolicies.names.signUpSignIn =
                      names.signUpSignIn;
                  }
                  if (names.editProfile) {
                    Vue.bzbsConfig.b2cPolicies.names.editProfile =
                      names.editProfile;
                  }
                  if (names.changePassword) {
                    Vue.bzbsConfig.b2cPolicies.names.changePassword =
                      names.changePassword;
                  }
                }
                if (responseMSAL.b2cPolicies.authorityDomain) {
                  Vue.bzbsConfig.msalConfig.auth.knownAuthorities = [
                    responseMSAL.b2cPolicies.authorityDomain,
                  ];
                }
              }
              if (responseMSAL.auth) {
                if (responseMSAL.auth.clientId) {
                  Vue.bzbsConfig.msalConfig.auth.clientId =
                    responseMSAL.auth.clientId;
                }
              }
              if (responseMSAL.cache) {
                if (responseMSAL.cache.cacheLocation) {
                  Vue.bzbsConfig.msalConfig.cache.cacheLocation =
                    responseMSAL.cache.cacheLocation;
                }
                if (responseMSAL.cache.storeAuthStateInCookie != null) {
                  Vue.bzbsConfig.msalConfig.cache.storeAuthStateInCookie =
                    responseMSAL.cache.storeAuthStateInCookie;
                }
              }
            }
            this.$router.go();
          }
        }
      })
      .catch((error) => {
        errorArrayAPI = {
          message: error.message,
        };
        this.$router.push({ name: "errorconfig", query: errorArrayAPI });
      });
  },
  render: (h) => h(App),
}).$mount("#app");
