<template>
  <AppLayout>
    <div
      :class="`${$route.name}`"
      class="campaigns-detail-main campaigns-detail-page page"
    >
      <div>
        <TitleBreadcrumbs
          :databreadcrumbs="breadcrumbs"
          :title="lbls.invitation_page.title_page"
        />
      </div>
      <div class="row">
        <PageHeader @onsearch="onSearch($event)" />
      </div>
      <a-card v-if="isShowSkeleton">
        <a-skeleton />
      </a-card>
      <div v-else>
        <TableList
          :datatable="data"
          :conditiontable="conditionTable"
          @onsearchtable="onSearchTable($event)"
          @callgetlist="callGetList"
          @handledelete="handleDelete($event)"
        >
        </TableList>
        <ShowTotal
          :datashowtitle="data"
          :conditiontable="conditionTable"
          :pagesize="page_size"
          :totallist="totalList"
          @callgetlist="callGetList"
          @setpagesize="setPageSize($event)"
        />
      </div>
    </div>
  </AppLayout>
</template>
<script>
import AppLayout from "@/components/Layout/LayoutMaster.vue";
import manageUsersAPI from "@/core/Page/ManageUserAPI";
import Cache from "@/helper/Cache.js";
import PageHeader from "@/module/User/InviteUsers/components/Layout/PageHeader.vue";
import ShowTotal from "@/components/Layout/ShowTotal";
import TableList from "@/module/User/InviteUsers/components/Table/TableList";
// import ModalAlert from "@/components/Modal/Alertmodal.vue";

export default {
  name: "ManageUser",
  components: {
    AppLayout,
    ShowTotal,
    PageHeader,
    TableList,
    // ModalAlert,
  },
  data() {
    return {
      permission_key: "user",
      action_key: "invite-view",
      breadcrumbs: [],
      isShowSkeleton: true,
      isShowModal: false,
      isModalResponse: false,
      isDelete: false,
      data: [],
      searchData: "",
      nameSearch: "",
      idModal: "",
      titleModal: "",
      modalbtnok: "",
      contentModal: "",
      conditionTable: {},
      alertData: {},
      modalAlert: {
        visible: false,
        id: "",
        title: "",
        message: "",
        okText: "",
        cancelText: "",
      },
      page: 0,
      page_size: 25,
      totalList: 0,
    };
  },
  watch: {
    lbls() {
      this.setBreadcrumbs();
    },
    "$store.state.menu"() {
      this.handlePermissionPage(this.permission_key, this.action_key);
    },
  },
  created() {
    this.setBreadcrumbs();
    this.init();
  },
  methods: {
    init() {
      this.exAccount = Cache.get("exAccount");
      this.setCallGetList();
      if (this.$store.state.menu?.length) {
        this.handlePermissionPage(this.permission_key, this.action_key);
      }
    },
    setBreadcrumbs() {
      this.breadcrumbs = [];
      this.breadcrumbs.push({
        breadcrumbName: this.lbls.menu.usermanagement,
        name: "ManageUsers",
      });
      this.breadcrumbs.push({
        breadcrumbName: this.lbls.user_page.btn.invitation,
        name: "InviteUsersDetail",
      });
    },
    onSearch(searchData) {
      let text = searchData;
      if (text != this.nameSearch) {
        this.nameSearch = text != undefined ? text : "";
        this.setCallGetList();
      } else {
        return;
      }
    },
    onSearchTable(data) {
      this.totalList = data.totalList >= 0 ? data.totalList : this.totalList;
      this.conditionTable.isFilters = data.isFilters;
    },
    handlemodalOk() {
      if (this.isDelete) {
        this.isShowSkeleton = true;
        this.callPostDeleteData();
      }
    },
    handleDelete(record) {
      this.idModal = "confirm";
      this.isDelete = true;
      this.modalbtnok = this.lbls.invitation_page.modal.btn.delete;
      this.titleModal = this.lbls.invitation_page.modal.title_confirm;
      this.contentModal = this.lbls.invitation_page.modal.content_delete + " ?";
      this.objRecord = record;
      this.$confirm({
        centered: true,
        autoFocusButton: null,
        title: this.lbls.invitation_page.modal.content_delete,
        content: this.objRecord.invited_email,
        okText: this.lbls.invitation_page.modal.btn.delete,
        okType: "danger",
        cancelText: this.lbls.invitation_page.btn.cancel,
        icon: "delete",
        onOk: () => {
          this.handleLoading(true);
          this.callPostDeleteData();
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },
    openNotificationWithIcon(type) {
      this.$notification[type]({
        message: this.titleModal,
        description: this.contentModal,
      });
      this.setCallGetList();
    },
    showModalSuccess() {
      this.handleLoading(false);
      let secondsToGo = this.$store.state.secondstogo;
      const modal = this.$success({
        centered: true,
        autoFocusButton: null,
        title: this.contentModal,
        okType: "default",
        okText: `Ok (${secondsToGo})`,
      });
      const interval = setInterval(() => {
        secondsToGo -= 1;
        modal.update({
          okText: `Ok (${secondsToGo})`,
        });
      }, 1000);
      setTimeout(() => {
        clearInterval(interval);
        modal.destroy();
      }, secondsToGo * 1000);
      this.setCallGetList();
    },
    setCallGetList() {
      this.isShowSkeleton = true;
      this.page = 0;
      this.page_size = this.page_size;
      this.data = [];
      this.callGetList();
    },
    setPageSize(value) {
      this.isShowSkeleton = true;
      this.page = 0;
      this.page_size = value;
      this.data = [];
      this.callGetList();
    },
    callGetList() {
      this.page = this.page + 1;
      let params = {
        account_id: this.exAccount.account.id,
        page: this.page,
        page_size: this.page_size,
      };
      if (this.nameSearch) {
        params.filter = this.nameSearch;
      }
      manageUsersAPI
        .getInvitation(params)
        .then((res) => {
          if (res.data) {
            if (res.data.result) {
              let item = res.data.result.items;
              let paging = res.data.result.paging;
              if (item.length > 0) {
                this.data.push(...res.data.result.items);
                this.data.forEach((elements) => {
                  let first_name = elements.first_name
                    ? elements.first_name + " "
                    : "-";
                  let last_name = elements.last_name ? elements.last_name : "";
                  let display_name = elements.display_name
                    ? elements.display_name
                    : "-";
                  elements.full_name = first_name + last_name;
                  elements.display_name = display_name;
                  elements.isShowMore = false;
                });
              }
              this.totalList = this.data.length;
              this.conditionTable = {
                hasMoreData: paging.has_more_data,
                isFilters: false,
              };
            }
          }
          this.handleLoading(false);
          this.isShowSkeleton = false;
        })
        .catch((error) => {
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callPostDeleteData() {
      let params = {
        account_id: this.exAccount.account.id,
        invitation_id: this.objRecord.id,
      };
      manageUsersAPI
        .deleteInvitation(params)
        .then((res) => {
          if (res.status == "200") {
            this.idModal = "success";
            this.isModalProcess = false;
            setTimeout(() => {
              this.titleModal = this.lbls.invitation_page.modal.title_success;
              this.contentModal =
                this.lbls.invitation_page.modal.content_delete_success;
              // this.openNotificationWithIcon("success");
              this.showModalSuccess();
              this.handleLoading(false);
            }, 500);
          }
        })
        .catch((error) => {
          this.isDelete = false;
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
  },
};
</script>
<style lang="scss">
@import "@/style/_mixin.scss";
</style>
