<template>
  <div class="title-breadcrumbs">
    <a-breadcrumb :routes="databreadcrumbs">
      <template slot="itemRender" slot-scope="{ route, params, routes }">
        <span
          v-if="
            routes.indexOf(route) === routes.length - 1 && routes.length > 1
          "
        >
          {{ route.breadcrumbName }}
        </span>
        <template v-else>
          <template v-if="routes.length > 4">
            <template v-if="routes.indexOf(route) === 0">
              <router-link v-if="route.name" :to="{ name: route.name }">
                {{ route.breadcrumbName }}
              </router-link>
              <span v-else>
                {{ route.breadcrumbName }}
              </span>
            </template>
            <template v-if="routes.indexOf(route) === routes.length - 2">
              <router-link v-if="route.name" :to="{ name: route.name }">
                {{ route.breadcrumbName }}
              </router-link>
              <span v-else>
                {{ route.breadcrumbName }}
              </span>
            </template>
            <span v-if="routes.indexOf(route) === routes.length - 3">
              ...
            </span>
          </template>
          <template v-else>
            <router-link
              v-if="route.name && routes.length > 1"
              :to="{
                name: route.name,
                query: {
                  id: $route.query.id,
                },
              }"
            >
              {{ route.breadcrumbName }}
            </router-link>
          </template>
        </template>
      </template>
    </a-breadcrumb>
    <a-page-header>
      <template slot="title">
        {{ title }}
        <span>
          <a-badge
            :count="totallist"
            :class="'count-list'"
            :overflow-count="totallist"
            show-zero
          />
        </span>
      </template>
    </a-page-header>
  </div>
</template>
<script>
export default {
  name: "TitleBreadcrumbs",
  props: {
    title: String,
    parent: Object,
    totallist: Number,
    current: String,
    databreadcrumbs: Array,
  },
  watch: {
    databreadcrumbs() {
      console.log(this.databreadcrumbs);
    },
  },
};
</script>
