<template>
  <div>
    <AppLayout>
      <div
        :class="`${$route.name}`"
        class="campaigns-detail-main campaigns-detail-page page"
      >
        <TitleBreadcrumbs
          :databreadcrumbs="breadcrumbs"
          :title="lbls.menu.assignrolemanagement"
        />
        <a-card v-if="isShowSkeleton">
          <a-skeleton />
        </a-card>
        <div
          v-if="!isShowSkeleton"
          class="manage-page-detail set-permission-app"
        >
          <div class="set-permission-top">
            <div class="flex-center">
              <div class="mr-3">
                <router-link
                  :to="{
                    name: 'ManageAssignRole',
                    query: {
                      id: $route.query.id,
                    },
                  }"
                >
                  <span class="icon-custom-add icon-arrow-nex">
                    <a-icon type="arrow-left" />
                  </span>
                  <span class="font-weight-bold"> BACK </span>
                </router-link>
              </div>
              <a-divider type="vertical" />
              <div class="ml-3 flex-center">
                <div class="logo-custom">
                  <img
                    :src="dataApplicationNew.logo_url"
                    style="width: 40px"
                    @error="handleError"
                  />
                </div>
                <div class="ml-2">
                  <div class="label-name-app">
                    {{ dataApplicationNew.name }}
                  </div>
                  <div v-if="dataApplicationNew.package" class="label-package">
                    <span class="label-name-package"
                      >Package: {{ dataApplicationNew.package.name }}
                    </span>
                    <span class="label-name-product ml-1">
                      Product: {{ dataProductsNew.length }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="dataProductsNew && dataProductsNew.length > 0"
            class="set-permission-body p-0"
          >
            <div class="box-left pl-3 pt-3">
              <div
                v-for="(item, i) in dataProductsNew"
                :key="item.id"
                class="item-button-href flex-center"
                :class="{ 'href-active': item.name == productActive }"
              >
                <a
                  class="w-100"
                  :href="'#' + item.name"
                  @click="clickMeTo(item.name, i + 1)"
                >
                  {{ item.display_name }}
                  <span v-if="item.isSetPerMission"
                    ><a-icon
                      type="star"
                      theme="twoTone"
                      two-tone-color="#f79443"
                  /></span>
                </a>
              </div>
            </div>
            <div class="box-right pt-3 pr-3">
              <div
                v-if="dataProductsNew.length > 1"
                class="mb-3 checkbox-all text-right box-right-top flex-center-between"
              >
                <div class="text-left" style="font-size: 12px">
                  <div>
                    <a>
                      <a-icon
                        theme="twoTone"
                        two-tone-color="#eb2f96"
                        type="profile"
                      />
                    </a>
                    Menu layer
                  </div>
                  <div>
                    <a>
                      <a-icon type="ordered-list" />
                    </a>
                    Feature layer
                  </div>
                </div>
                <div>
                  <a-tooltip v-if="checkCollapse()" placement="top">
                    <template slot="title">
                      {{ lbls.btn.collapse }}
                    </template>
                    <a-button
                      id="collapse"
                      size="small"
                      class="custom-btn-collapse"
                      @click="setCollapse($event, null)"
                    >
                      <span><a-icon type="shrink" /></span>
                    </a-button>
                  </a-tooltip>
                  <a-tooltip v-else placement="top">
                    <template slot="title">
                      {{ lbls.btn.expand }}
                    </template>
                    <a-button
                      id="expand"
                      size="small"
                      class="custom-btn-collapse"
                      @click="setCollapse($event, null)"
                    >
                      <span><a-icon type="arrows-alt" /></span>
                    </a-button>
                  </a-tooltip>
                </div>
              </div>
              <div class="item-product" @scroll="onScroll($event)">
                <div
                  v-for="item in dataProductsNew"
                  :id="item.name"
                  :key="item.id"
                  class="pt-2"
                >
                  <div
                    class="test001"
                    :class="{ 'test001-active': item.name == productActive }"
                  >
                    <div class="item-title-product mb-3">
                      <div>{{ item.display_name }}</div>
                      <div>
                        <a-tooltip
                          v-if="item.activeKey.length > 0"
                          placement="top"
                        >
                          <template slot="title">
                            {{ lbls.btn.collapse }}
                          </template>
                          <a-button
                            id="collapse"
                            size="small"
                            class="custom-btn-collapse"
                            @click="setCollapse($event, item)"
                          >
                            <span><a-icon type="shrink" /></span>
                          </a-button>
                        </a-tooltip>
                        <a-tooltip v-else placement="top">
                          <template slot="title">
                            {{ lbls.btn.expand }}
                          </template>
                          <a-button
                            id="expand"
                            size="small"
                            class="custom-btn-collapse"
                            @click="setCollapse($event, item)"
                          >
                            <span><a-icon type="arrows-alt" /></span>
                          </a-button>
                        </a-tooltip>
                      </div>
                    </div>
                    <div class="mt-3 set-border-bottom"></div>
                    <div class="mt-3">
                      <a-collapse
                        v-model="item.activeKey"
                        @change="changeActivekey($event, item)"
                      >
                        <a-collapse-panel
                          v-if="item.data_levels"
                          key="1"
                          :header="lbls.role_page.title_data_level"
                        >
                          <span
                            ><div>
                              <a-radio-group
                                v-model="item.idDataLv"
                                @change="onChangeDataLv($event, item)"
                              >
                                <a-radio value="none"> None </a-radio>
                                <a-radio
                                  v-for="lv in item.data_levels"
                                  :key="lv.id"
                                  :value="lv.id"
                                >
                                  {{ lv.display_name }}
                                </a-radio>
                              </a-radio-group>
                            </div></span
                          >
                        </a-collapse-panel>
                        <a-collapse-panel
                          v-if="item.newmenulist"
                          key="2"
                          header="Menu"
                        >
                          <span>
                            <div class="label-item">
                              <div
                                v-if="item.featuresAll.length > 1"
                                class="checkbox-all flex-center-between"
                              >
                                <div>
                                  <a-checkbox
                                    :disabled="!isPermissionSetting"
                                    :checked="setCheckAllMenu(item)"
                                    :indeterminate="setIndeterminatemenu(item)"
                                    @change="onCheckAllMenu($event, item)"
                                  >
                                    <span>
                                      <span>{{
                                        lbls.role_page.set_permission
                                          .select_all_menu
                                      }}</span>
                                      <span class="lable-length">
                                        {{ item.featuresSelect.length }}/{{
                                          item.featuresAll.length
                                        }}</span
                                      >
                                    </span>
                                  </a-checkbox>
                                </div>
                                <div>
                                  <a-tooltip
                                    v-if="expandAllMenu"
                                    placement="top"
                                  >
                                    <template slot="title">
                                      {{ lbls.btn.collapse }}
                                    </template>
                                    <a-button
                                      id="collapse"
                                      size="small"
                                      class="custom-btn-collapse"
                                      @click="setCollapseMenu(item)"
                                    >
                                      <span><a-icon type="shrink" /></span>
                                    </a-button>
                                  </a-tooltip>
                                  <a-tooltip v-else placement="top">
                                    <template slot="title">
                                      {{ lbls.btn.expand }}
                                    </template>
                                    <a-button
                                      id="expand"
                                      size="small"
                                      class="custom-btn-collapse"
                                      @click="setCollapseMenu(item)"
                                    >
                                      <span><a-icon type="arrows-alt" /></span>
                                    </a-button>
                                  </a-tooltip>
                                </div>
                              </div>
                              <div v-if="item.isShowMenu">
                                <a-tree
                                  v-if="item.newmenulist"
                                  v-model="item.menuChecked"
                                  checkable
                                  :tree-data="item.newmenulist"
                                  :default-expand-all="expandAllMenu"
                                  @check="onCheckMenuNew($event, item)"
                                >
                                  <template slot="title" slot-scope="text">
                                    <a v-if="!text.isFeature">
                                      <a-icon
                                        theme="twoTone"
                                        two-tone-color="#eb2f96"
                                        type="profile"
                                      />
                                    </a>
                                    <a v-if="text.isFeature">
                                      <a-icon type="ordered-list" />
                                    </a>
                                    {{ text.title }}
                                    <span
                                      v-if="!text.isFeature"
                                      class="lable-length"
                                      >({{ text.children.length }})</span
                                    >
                                    <div class="text-caption ml-3">
                                      {{ text.title_key }}
                                    </div>
                                  </template>
                                </a-tree>
                              </div>
                            </div>
                          </span>
                        </a-collapse-panel>
                        <a-collapse-panel
                          v-if="item.features"
                          key="4"
                          :header="lbls.role_page.title_features"
                        >
                          <span>
                            <div class="label-item">
                              <div
                                v-if="item.features.length > 1"
                                class="checkbox-all"
                              >
                                <a-checkbox
                                  :checked="setCheckAll(item.features)"
                                  :disabled="!isPermissionSetting"
                                  :indeterminate="
                                    setIndeterminate(item.features)
                                  "
                                  @change="
                                    onChangAllCheckBox($event, item.features)
                                  "
                                >
                                  <span>
                                    <span
                                      >{{
                                        lbls.role_page.set_permission
                                          .select_all_features
                                      }}
                                    </span>
                                    <span class="lable-length">{{
                                      setShowLengthItem(item.features)
                                    }}</span>
                                  </span>
                                </a-checkbox>
                              </div>
                              <div class="feature-list">
                                <a-checkbox
                                  v-for="feature in item.features"
                                  :key="feature.id"
                                  :checked="feature.is_selected"
                                  class="feature-item"
                                  :disabled="!isPermissionSetting"
                                  @change="onChangesCheckbox($event, feature)"
                                >
                                  <span>{{ feature.display_name }}</span>
                                </a-checkbox>
                              </div>
                            </div>
                          </span>
                        </a-collapse-panel>
                      </a-collapse>
                    </div>
                    <div class="mt-3 set-border-bottom" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <a-empty
              :image="imgEmpty"
              :image-style="{
                height: '150px',
              }"
            />
          </div>
        </div>
        <!-- Modal -->
        <div v-if="isModalProcess">
          <ModalProcess
            :modalprocess="modalprocess"
            :dataobjselect="dataObjSelect"
            @actionModal="actionModal($event)"
          >
          </ModalProcess>
        </div>
        <div v-if="isModalResponse">
          <ModalAlert
            :modalalert="modalAlert"
            :alertdata="alertData"
            @handlemodalok="handlemodalOk"
          >
          </ModalAlert>
        </div>
      </div>
    </AppLayout>
    <div v-if="!isShowSkeleton" class="custom-footer-setpermission">
      <a-button
        :disabled="disablebtnok || !isPermissionSetting"
        type="primary"
        @click="handleSave()"
      >
        {{ lbls.role_page.btn.save }}
      </a-button>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import { config } from "@/config/bzbsconfig.js";
import AppLayout from "@/components/Layout/LayoutMaster.vue";
import manageRoleAPI from "@/core/Page/ManageRoleAPI";
import Cache from "@/helper/Cache.js";
// import PageHeader from "@/module/Role/ManageSetRole/components/Layout/PageHeader.vue";
// import BodyProduct from "@/module/Role/ManageSetRole/components/Layout/BodyProduct.vue";
// import BodyApp from "@/module/Role/ManageSetRole/components/Layout/BodyApp.vue";
import ModalProcess from "@/module/Role/ManageSetRole/components/Modal/ModalProcess.vue";
import ModalAlert from "@/components/Modal/Alertmodal.vue";

export default {
  name: "ManageRole",
  components: {
    AppLayout,
    // PageHeader,
    // BodyApp,
    // BodyProduct,
    ModalProcess,
    ModalAlert,
  },
  data() {
    return {
      permission_key: "role",
      action_key: "view",
      breadcrumbs: [],
      logo: require("@/assets/images/bzbs-logo1.png"),
      imgEmpty: config.themeUrl + "/img/empty.svg",
      isShowSkeleton: true,
      data: [],
      dataRole: {},
      dataPackages: [],
      dataProduct: [],
      btndeletedisabled: false,
      isEdit: false,
      objSelect: [],
      dataObjSelect: [],
      valueDataLv: [],
      isModalProcess: false,
      isModalResponse: false,
      titleModalProcess: "",
      showBtnDelete: false,
      tabActive: "",
      tabItemActive: "1",
      totalList: 0,
      idModal: "",
      titleModal: "",
      isDelete: false,
      errtransaction_id: "",
      modalbtnok: "",
      contentModal: "",
      searchInput: null,
      valuecheckbox: [],
      listOfDatalv: [],
      listOfFeature: [],
      dataProductForSave: [],
      record: "",
      valueDataLvRadio: "",
      resetbgc: {
        backgroundColor: "",
      },
      disablebtnok: true,
      indeterminate: true,
      checkAll: false,
      dataPageBreadcrumb: {},
      myoffsetHeight: 0,
      alertData: {},
      modalAlert: {
        visible: false,
        id: "",
        title: "",
        message: "",
        okText: "",
        cancelText: "",
      },
      dataPageHeader: {},
      targetOffset: undefined,
      productActive: "",
      offsetTop: 0,
      dataApplicationNew: [],
      dataProductsNew: [],
      dataRoleNew: [],
      expandAllMenu: true,
      expandAllPage: true,
      logo_default: require("@/assets/images/bzbs-logo1.png"),
      isPermissionSetting: true,
    };
  },
  watch: {
    // offsetTop() {
    //   this.callbackFunc();
    // },
    lbls() {
      this.setBreadcrumbs();
    },
    "$store.state.menu"() {
      this.handlePermission();
    },
  },
  mounted() {
    this.targetOffset = window.innerHeight / 2;
  },
  created() {
    window.addEventListener("scroll", this.onScroll);
    this.setBreadcrumbs();
    this.init();
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    init() {
      if (this.$route.query.id) {
        this.exAccount = Cache.get("exAccount");
        Promise.all([this.callGetDetail()]).then((resgetdetail) => {
          let isCallListSuccess = resgetdetail[0];
          if (isCallListSuccess) {
            this.nameSearch = "";
            this.callPermissonApp();
          } else {
            this.handleLoading(false);
          }
        });
        this.handlePermission();
      } else {
        this.$router.push({ name: "ManageRoles" });
      }
    },
    handlePermission() {
      if (this.$store.state.menu?.length) {
        this.handlePermissionPage(this.permission_key, this.action_key);
        let permission_page = this.handlePermissionPage(this.permission_key);
        if (permission_page?.length) {
          this.isPermissionSetting = this.checkArrowPermission(
            "role-setting",
            permission_page
          );
        }
      }
    },
    setBreadcrumbs() {
      this.breadcrumbs = [];
      this.breadcrumbs.push({
        breadcrumbName: this.lbls.menu.rolemanagement,
        name: "ManageRoles",
      });
    },
    showModalProcess() {
      this.isModalProcess = true;
      this.handleLoading(false);
      this.modalprocess = {
        visible: true,
        title: this.titleModalProcess,
        key: "user_page.",
        isCreate: this.isCreate,
        isEdit: this.isEdit,
      };
    },
    closeModalProcess() {
      this.isModalProcess = false;
    },
    resetCondition() {
      this.isEdit = false;
      this.isDelete = false;
    },
    handleError(event) {
      event.target.src = this.logo;
    },
    handleEdit() {
      this.resetCondition();
      this.handleLoading(true);
      this.isEdit = true;
      this.titleModalProcess = this.lbls.role_page.drw_title_edit;
      this.callGetDetailEdit();
    },
    handleDelete() {
      this.resetCondition();
      this.idModal = "confirm";
      this.isDelete = true;
      this.modalbtnok = this.lbls.role_page.modal.btn.delete;
      this.titleModal = this.lbls.role_page.modal.title_confirm;
      this.contentModal =
        this.lbls.role_page.modal.content_delete + this.objSelect.name + " ?";
      this.modalAlert = {
        id: this.idModal,
        visible: true,
        title: this.titleModal,
        message: this.contentModal,
        okText: this.modalbtnok,
        cancelText: this.modalbtncancel,
        isDelete: this.isDelete,
      };
      this.isModalResponse = true;
    },
    callPermissonApp() {
      let params = {
        account_id: this.exAccount.account.id,
        role_id: this.$route.query.id,
        application_id: this.$route.query.appid,
      };
      manageRoleAPI
        .getPermissionApp(params)
        .then((res) => {
          if (res.data && res.data.result) {
            let result = res.data.result;
            let is_role_config = false;
            let role_name = result.role?.name.toLowerCase();
            let role_arrow = ["configpermission", "toonconfigpermissiontest"];
            role_name = role_name.replace(/ /g, "");
            is_role_config = role_arrow.includes(role_name);
            let dataset = {
              records: result.products,
            };
            let product_config = [
              "Product-Platform-External",
              "Product-Platform-Internal",
            ];
            this.dataProductsNew = [];
            if (is_role_config) {
              this.dataProductsNew = dataset.records.filter(function (item) {
                return product_config.includes(item.name);
              });
            } else {
              this.dataProductsNew = dataset.records.filter(function (item) {
                return !product_config.includes(item.name);
              });
            }
            if (result.application) {
              result.application.logo_url = result.application.logo_url
                ? result.application.logo_url
                : this.logo_default;
            }
            // this.dataProductsNew = dataset.records;
            this.dataApplicationNew = result.application;
            this.dataRoleNew = result.role;
            this.isSetDataForCheckSave = true;
            if (this.dataProductsNew && this.dataProductsNew.length > 0) {
              this.setDataProductsNew();
            }
            if (this.isShowSkeleton) {
              this.breadcrumbs.push({
                breadcrumbName: this.dataApplicationNew.name,
                name: "ManagePermissionApp",
              });
            }
            this.handleLoading(false);
            this.isShowSkeleton = false;
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callGetList() {
      let params = {
        account_id: this.exAccount.account.id,
        role_id: this.$route.query.id,
      };
      manageRoleAPI
        .getDetail(params)
        .then((res) => {
          if (res.data && res.data.result) {
            this.dataRole = res.data.result.role ? res.data.result.role : {};
            if (res.data.result.items) {
              let items = res.data.result.items;
              let dataPackages = [];
              _.forEach(items, (element) => {
                if (
                  element.products ||
                  element.applications ||
                  element.cross_applications
                ) {
                  dataPackages.push(element);
                  if (element.products) {
                    element.products.feature_all = [];
                    element.products.feature_selected_all = [];
                    _.forEach(element.products, (product) => {
                      product.isShow = true;
                      product.isEdit = false;
                      product.dataforcheckStrat = this.setValueCheck(product);
                      product.dataforcheck = _.cloneDeep(
                        product.dataforcheckStrat
                      );
                      let feature_selected = [];
                      _.forEach(product.features, (feature) => {
                        element.products.feature_all.push(feature);
                        if (feature.is_selected) {
                          feature_selected.push(feature);
                          element.products.feature_selected_all.push(feature);
                        }
                      });
                      product.feature_selected = feature_selected;
                    });
                  }
                  if (element.applications) {
                    element.applications.isShow = true;
                    element.applications.isEdit = false;
                    element.applications.dataforcheckStrat = this.setValueCheck(
                      element.applications
                    );
                    element.applications.dataforcheck = _.cloneDeep(
                      element.applications.dataforcheckStrat
                    );
                  }
                  if (element.cross_applications) {
                    element.cross_applications.isShow = true;
                    element.cross_applications.isEdit = false;
                  }
                }
                element.collapse = false;
              });
              this.dataPackages = dataPackages[0];
              this.dataProduct = this.dataPackages.products;
              // this.productActive = this.dataProduct[0].name;
              // if (dataPackages && this.tabActive == "") {
              //   this.tabActive = this.dataPackages[0].package.id;
              // }
            }
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callGetDetail() {
      return new Promise((resolve) => {
        let isCallDetailSuccess = false;
        let params = {
          account_id: this.exAccount.account.id,
          role_id: this.$route.query.id,
        };
        manageRoleAPI
          .getDetailRole(params)
          .then((res) => {
            if (res.data && res.data.result) {
              isCallDetailSuccess = true;
              this.dataPageHeader = res.data.result;
              this.breadcrumbs.push({
                breadcrumbName: this.dataPageHeader.name,
                name: "ManageAssignRole",
              });
              this.objSelect = res.data.result;
              resolve(isCallDetailSuccess);
            } else {
              resolve(isCallDetailSuccess);
            }
          })
          .catch((error) => {
            this.idModal = "error";
            this.handleLoading(true);
            this.errorApi(error);
            resolve(isCallDetailSuccess);
          });
      });
    },
    callGetDetailEdit() {
      let params = {
        account_id: this.exAccount.account.id,
        role_id: this.$route.query.id,
      };
      manageRoleAPI
        .getDetailRole(params)
        .then((res) => {
          if (res.data && res.data.result) {
            this.dataObjSelect = res.data.result;
            const newData = {
              id: this.objSelect.id,
              name: this.objSelect.name,
              description: this.objSelect.description,
              is_active: this.objSelect.is_active,
            };
            this.dataEditForCheck = JSON.stringify(newData);
            this.dataObjSelect.dataEditForCheck = this.dataEditForCheck;
            this.showModalProcess();
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callPostEditData() {
      var valueSave = {
        account_id: this.exAccount.account.id,
        role_id: this.objDataSave.id,
        role_name: this.objDataSave.name,
        role_description: this.objDataSave.description,
        is_active: this.objDataSave.is_active,
      };
      this.objForSave = JSON.stringify(valueSave);
      manageRoleAPI
        .postEditRole(this.objForSave)
        .then((res) => {
          if (res.status == "200") {
            if (res.data.result) {
              this.idModal = "success";
              this.objSelect = res.data.result;
              const newData = {
                id: this.objSelect.id,
                description: this.objSelect.description,
                is_active: this.objSelect.is_active,
                name: this.objSelect.name,
              };
              this.dataEditForCheck = JSON.stringify(newData);
              this.closeModalProcess();
              setTimeout(() => {
                this.titleModal = this.lbls.role_page.modal.title_success;
                this.contentModal =
                  this.lbls.role_page.modal.content_update_success;
                // this.openNotificationWithIcon("success");
                this.showModalSuccess();
                this.dataPageHeader = res.data.result;
              }, 500);
            }
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callPostDeleteData() {
      this.handleLoading(true);
      var valueSave = {
        account_id: this.exAccount.account.id,
        role_id: this.objSelect.id,
      };
      manageRoleAPI
        .deleteRole(valueSave)
        .then((res) => {
          if (res.status == "200") {
            this.idModal = "success";
            this.closeModalProcess();
            setTimeout(() => {
              this.titleModal = this.lbls.role_page.modal.title_success;
              this.contentModal =
                this.lbls.role_page.modal.content_delete_success;
              // this.openNotificationWithIcon("success");
              this.showModalSuccess();
            }, 500);
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    handlemodalOk() {
      if (this.idModal == "error") {
        this.isModalResponse = false;
        return;
      }
      this.handleLoading(true);
      if (this.isDelete) {
        this.callPostDeleteData();
      } else {
        this.callPostEditData();
      }
      this.isModalResponse = false;
    },
    handleSave() {
      this.handleLoading(true);
      let record = {
        account_id: this.exAccount.account.id,
        role_id: this.dataPageHeader.id,
        application_id: this.dataApplicationNew.id,
        products: this.dataProductForSave,
      };
      record = JSON.stringify(record);
      manageRoleAPI
        .postSetPermissionApp(record)
        .then((res) => {
          if (res.status == "200" && res.data.result) {
            this.idModal = "success";
            this.ispostPermission = true;
            this.titleModal = this.lbls.role_page.modal.title_success;
            this.contentModal =
              this.lbls.role_page.modal.content_update_success;
            this.isSetDataForCheckSave = true;
            this.settestcheckallproduct();
            // this.openNotificationWithIcon("success");
            this.showModalSuccess();
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    openNotificationWithIcon(type) {
      this.$notification[type]({
        message: this.titleModal,
        description: this.contentModal,
      });
      this.isShowSkeleton = false;
      if (this.isDelete) {
        setTimeout(() => {
          this.$router.push("manageroles");
        }, 500);
      } else {
        this.handleLoading(false);
      }
    },
    showModalSuccess() {
      this.handleLoading(false);
      let secondsToGo = this.$store.state.secondstogo;
      const modal = this.$success({
        centered: true,
        autoFocusButton: null,
        title: this.contentModal,
        okType: "default",
        okText: `Ok (${secondsToGo})`,
      });
      const interval = setInterval(() => {
        secondsToGo -= 1;
        modal.update({
          okText: `Ok (${secondsToGo})`,
        });
      }, 1000);
      setTimeout(() => {
        clearInterval(interval);
        modal.destroy();
      }, secondsToGo * 1000);
      if (this.isDelete) {
        setTimeout(() => {
          this.$router.push("manageroles");
        }, 500);
      }
      this.callPermissonApp();
    },
    actionModal(value) {
      if (value == "close") {
        this.isModalProcess = false;
        return;
      } else {
        this.handleLoading(true);
        if (this.isEdit) {
          this.objDataSave = value;
          this.callPostEditData();
        }
      }
    },
    onChangesCheckbox(e, feature) {
      let checked = e.target.checked;
      feature.is_selected = checked;
      this.setDataForSaveNew();
      this.$forceUpdate();
    },
    onChangeRadio(e, datas) {
      _.forEach(datas.data_levels, (datalevel) => {
        datalevel.is_selected = false;
        if (e.target.value == datalevel.id) {
          datalevel.is_selected = true;
        }
      });
      datas.idDataLv = e.target.value;
      datas.dataforcheck = this.setValueCheck(datas);
      this.settestcheckallproduct();
      this.$forceUpdate();
    },
    onChangeDataLv(e, item) {
      let datalv = e.target.value;
      _.forEach(item.data_levels, (element) => {
        element.is_selected = false;
        if (datalv == element.id) {
          element.is_selected = true;
        }
      });
      item.idDataLv = e.target.value;
      this.setDataForSaveNew();
      this.$forceUpdate();
    },
    setValueCheck(value) {
      let dataforcheck = [];
      if (value.data_levels) {
        let findobj = value.data_levels.find((o) => o.is_selected === true);
        if (findobj) {
          value.idDataLv = findobj.id;
        } else {
          value.idDataLv = "none";
        }
        dataforcheck.push(value.idDataLv);
      }
      let items = value.features ? value.features : value;
      _.forEach(items, (item) => {
        if (item.is_selected) {
          dataforcheck.push(item.id);
        }
      });
      value.dataforcheck = JSON.stringify(dataforcheck);
      return JSON.stringify(dataforcheck);
    },
    clickMeTo(refName, i) {
      console.log(refName, i);
      // this.isLastItem = false;
      // if (this.dataProductsNew.length == i) {
      //   this.isLastItem = true;
      // }
      // this.productActive = refName;
    },
    checkListofFeature(item) {
      console.log(item, "item");
      // let isdatalv = item.idDataLv ? item.idDataLv != "none" : false;
      // let menuSelected = item.menuSelected
      //   ? item.menuSelected.length > 0
      //   : false;
      // let pageSelected = item.pageSelected
      //   ? item.pageSelected.length > 0
      //   : false;
      // let featuresSelected = item.featuresSelected
      //   ? item.featuresSelected.length > 0
      //   : false;
      // let features = [];
      // if (item.features) {
      //   features = item.features.filter((item) => {
      //     return item.is_selected;
      //   });
      // }
      // let isCheck =
      //   isdatalv ||
      //   menuSelected ||
      //   pageSelected ||
      //   featuresSelected ||
      //   features.length > 0;      // return isCheck;
    },
    // onScroll(event) {
    //   console.log(refName.target.scrollTop);
    //   // this.offsetTop = refName.target.scrollTop;
    //   this.callbackFunc(event);
    // },
    isElementInViewport(el) {
      var rect = el.getBoundingClientRect();
      let tootest = rect.top >= 200;
      return tootest;
    },
    // callbackFunc() {
    //   let items = document.querySelectorAll(".test001");
    //   this.isToonTest = false;
    //   for (var i = 0; i < items.length; i++) {
    //     if (!this.isToonTest && this.isElementInViewport(items[i])) {
    //       this.productActive = items[i].id;
    //       this.isToonTest = true;
    //     }
    //   }
    // },
    setCheckAllProduct() {
      let feature_all = this.dataProductsNew.feature_all
        ? this.dataProductsNew.feature_all.length
        : 0;
      let feature_selected_all = this.dataProductsNew.feature_selected_all
        ? this.dataProductsNew.feature_selected_all.length
        : 0;
      return feature_all == feature_selected_all;
    },
    setIndeterminateAllProduct() {
      let feature_all = this.dataProductsNew.feature_all
        ? this.dataProductsNew.feature_all.length
        : 0;
      let feature_selected_all = this.dataProductsNew.feature_selected_all
        ? this.dataProductsNew.feature_selected_all.length
        : 0;
      return feature_all != feature_selected_all && feature_selected_all > 0;
    },
    onChangAllCheckBoxAll(e) {
      _.forEach(this.dataProductsNew, (element) => {
        this.onChangAllCheckBox(e, element.features);
      });
    },
    setCheckAll(features) {
      let featuresSelect = features.filter((item) => {
        return item.is_selected;
      });
      return featuresSelect.length == features.length;
    },
    setIndeterminate(features) {
      let featuresSelect = features.filter((item) => {
        return item.is_selected;
      });
      return (
        featuresSelect.length > 0 && featuresSelect.length != features.length
      );
    },
    setShowLengthItem(features) {
      let featuresSelect = features.filter((item) => {
        return item.is_selected;
      });
      let lengthItem =
        "(" + featuresSelect.length + "/" + features.length + ")";
      return lengthItem;
    },
    onChangAllCheckBox(e, features) {
      _.forEach(features, (item) => {
        this.onChangesCheckbox(e, item);
      });
      // this.settestcheckall(data);
      // this.dataProduct.feature_selected_all = [
      //   ...new Set(this.dataProduct.feature_selected_all),
      // ];
    },
    settestcheckall(data) {
      data.feature_selected = [];
      _.forEach(data.features, (feature) => {
        if (feature.is_selected) {
          data.feature_selected.push(feature);
        }
      });
      this.settestcheckallproduct();
    },
    settestcheckallproduct() {
      let feature_selected_all = [];
      let product_list = [];
      let itemproduct = {};
      this.dataProductsNew.feature_selected_all = [];
      _.forEach(this.dataProductsNew, (element) => {
        if (element.features) {
          let feature_list = [];
          _.forEach(element.features, (feature) => {
            if (feature.is_selected) {
              feature_selected_all.push(feature);
              feature_list.push(feature.id);
            }
          });
          if (feature_list.length > 0) {
            itemproduct = {
              id: element.id,
              feature_ids: feature_list,
            };
            product_list.push(itemproduct);
          }
          this.dataProductsNew.feature_selected_all = feature_selected_all;
        }
      });
      this.dataProductForSave = product_list;
      this.setDataForSave();
    },
    setDataForSave() {
      _.forEach(this.dataProductsNew, (element) => {
        if (element.menuSelected && element.menuSelected.length >= 0) {
          let findobj = this.dataProductForSave.find(
            (o) => o.id === element.id
          );
          if (findobj) {
            findobj.menu_ids = element.menuSelected;
          } else {
            this.dataProductForSave.push({
              id: element.id,
              menu_ids: element.menuSelected,
            });
          }
        }
        if (element.pageSelected && element.pageSelected.length >= 0) {
          let findobj = this.dataProductForSave.find(
            (o) => o.id === element.id
          );
          if (findobj) {
            findobj.page_ids = element.pageSelected;
          } else {
            this.dataProductForSave.push({
              id: element.id,
              page_ids: element.pageSelected,
            });
          }
        }
        if (element.featuresSelected && element.featuresSelected.length >= 0) {
          let findobj = this.dataProductForSave.find(
            (o) => o.id === element.id
          );
          if (findobj) {
            findobj.featuresSelected = element.featuresSelected;
          } else {
            this.dataProductForSave.push({
              id: element.id,
              featuresSelected: element.featuresSelected,
            });
          }
          let feature_ids = findobj.feature_ids ? findobj.feature_ids : [];
          if (findobj && findobj.featuresSelected) {
            _.forEach(element.featuresSelected, (feature) => {
              let findfeature = feature_ids.find((o) => o === feature);
              if (!findfeature) {
                feature_ids.push(feature);
                findobj.feature_ids = feature_ids;
              }
            });
          }
        }
        if (element.idDataLv && element.idDataLv != "none") {
          let findobj = this.dataProductForSave.find(
            (o) => o.id === element.id
          );
          if (findobj) {
            findobj.data_level_id = element.idDataLv;
          } else {
            this.dataProductForSave.push({
              id: element.id,
              data_level_id: element.idDataLv,
            });
          }
        }
      });
      if (this.isSetDataForCheckSave) {
        this.isSetDataForCheckSave = false;
        this.dataForCheckSave = JSON.stringify(this.dataProductForSave);
      }
      let dataProductForSave = JSON.stringify(this.dataProductForSave);
      this.disablebtnok = this.dataForCheckSave == dataProductForSave;
    },
    setDataProductsNew() {
      _.forEach(this.dataProductsNew, (element, index) => {
        element.activeKey = [];
        if (element.data_levels) {
          element.activeKey.push("1");
          let findobj = element.data_levels.find((o) => o.is_selected === true);
          if (findobj) {
            element.idDataLv = findobj.id;
          } else {
            element.idDataLv = "none";
          }
        }
        //set data menu
        if (element.menus && element.menus.length > 0) {
          element.menus.sort((a, b) =>
            a.display_name.localeCompare(b.display_name)
          );
          this.menuChecked = [];
          this.featuresAll = [];
          this.featuresSelect = [];
          element.newmenulist = this.onSetNewMenu(element.menus);
          element.menuChecked = this.menuChecked;
          element.featuresAll = this.featuresAll;
          element.featuresSelect = this.featuresSelect;
          element.isShowMenu = this.expandAllMenu;
          if (element.newmenulist) {
            element.activeKey.push("2");
          }
        }
        //set data features
        if (element.features) {
          element.activeKey.push("4");
        }
        if (index == 0) {
          this.productActive = element.name;
        }
        element.activeKeyStart = element.activeKey;
      });
      this.isSetDataForCheckSave = true;
      this.setDataForSaveNew();
    },
    onSetMenu(items) {
      let newmenu = [];
      _.forEach(items, (item) => {
        let children = [];
        if (item.menus && item.menus.length > 0) {
          let data = item.menus ? item.menus : item.features;
          children = this.onSetMenu(data);
        }
        this.checkedKeysMenuAll.push(item.id);
        if (item.is_menu_selected) {
          this.checkedKeysMenu.push(item.id);
        }
        newmenu.push({
          title: item.display_name,
          key: item.id,
          sequence: item.sequence,
          children: children,
        });
      });
      return newmenu;
    },
    onSetPage(items) {
      let newmenu = [];
      // let features = null;
      _.forEach(items, (item) => {
        let children = [];
        if (item.page_name != null && item.features) {
          let tempdisplay_name = item.display_name;
          let tempid = item.id;
          item.display_name =
            this.lbls.role_page.set_permission.select_all_features;
          item.id = item.id + 1;
          item = Object.fromEntries(
            Object.entries(item).filter(([key]) => !key.includes("page_name"))
          );
          item = { ...item, menus: [{ ...item }] };
          item = Object.fromEntries(
            Object.entries(item).filter(([key]) => !key.includes("features"))
          );
          item.isLevelPage = true;
          item.display_name = tempdisplay_name;
          item.id = tempid;
        }
        if (
          (item.menus && item.menus.length > 0) ||
          (item.features && item.features.length > 0)
        ) {
          let data = item.menus ? item.menus : item.features;
          children = this.onSetPage(data);
          if (item.features) {
            this.checkItemSelect(item);
          }
        }
        let isDisablecheck =
          (item.page_display_name == null && item.is_selected == null) ||
          item.isLevelPage;
        newmenu.push({
          title: item.display_name,
          label: item.isLevelPage,
          value: item.has_page,
          is_selected: item.is_selected,
          is_page_selected: item.is_page_selected,
          featureselect: item.featureselect,
          key: item.id,
          sequence: item.sequence,
          children: children,
          disableCheckbox: isDisablecheck,
          isLevelPage: item.isLevelPage,
          has_page: item.has_page,
          page_display_name: item.page_display_name,
        });
        if ((item.is_page_selected && item.isLevelPage) || item.is_selected) {
          this.checkedKeysPage.push(item.id);
        }
        if (item.is_page_selected && item.isLevelPage) {
          this.pageSelected.push(item.id);
        }
        if (item.isLevelPage) {
          this.checkedKeysPageAll.push(item.id);
        }
        if (item.is_selected != null) {
          this.featuresAll.push(item.id);
          if (item.is_selected) {
            this.featuresSelected.push(item.id);
          }
        }
      });
      return newmenu;
    },
    onSetNewMenu(items) {
      let newmenulist = [];
      _.forEach(items, (item) => {
        let children = [];
        let data = item.menus ? item.menus : item.features;
        let isFeature = false;
        let isFeatureChildren = item.features && item.features.length > 0;
        if (data && data.length > 0) {
          data.sort((a, b) => a.display_name.localeCompare(b.display_name));
          children = this.onSetNewMenu(data);
        }
        // this.checkedKeysMenuAll.push(item.id);
        if (item.is_selected != null) {
          isFeature = true;
          this.featuresAll.push(item.id);
          if (item.is_selected) {
            this.menuChecked.push(item.id);
            this.featuresSelect.push(item.id);
          }
        }
        newmenulist.push({
          title: item.display_name,
          title_key: item.name,
          key: item.id,
          id: item.id,
          sequence: item.sequence,
          children: children,
          isFeature: isFeature,
          isFeatureChildren: isFeatureChildren,
        });
      });
      return newmenulist;
    },
    onCheckMenuNew(checkedKeys, item) {
      item.featuresSelect = [];
      _.forEach(checkedKeys, (element) => {
        let findobj = item.featuresAll.find((id) => id == element);
        if (findobj) {
          item.featuresSelect.push(findobj);
        }
      });
      this.setDataForSaveNew();
      this.$forceUpdate();
    },
    onCheckMenu(checkedKeys, item) {
      console.log(checkedKeys);
      item.menuSelected = checkedKeys;
      this.$forceUpdate();
      this.setDataForSave();
    },
    onCheckFeatures(checkedKeys, item) {
      item.checkedKeysPage = this.checkKeys(checkedKeys, item);
      let featuresSelected = item.checkedKeysPage.filter((item) => {
        return item.length == 36;
      });
      if (item.pageSelected.length > 0)
        _.forEach(item.pageSelected, (element) => {
          featuresSelected = featuresSelected.filter((item) => {
            return item != element;
          });
        });
      item.featuresSelected = featuresSelected;
      this.$forceUpdate();
      // this.setDataForSave();
      this.settestcheckallproduct();
    },
    onChange(e) {
      console.log(`checked = ${e.target.checked}`);
    },
    onclickSelectPage(selectedKeys, info) {
      let clickcheckbox = selectedKeys.target.type == "checkbox";
      if (clickcheckbox) {
        this.onCheckDataPage(info, selectedKeys.target.id, info);
        this.setDataForSave();
      }
      this.$forceUpdate();
    },
    onCheckDataPage(items, idcheck, info) {
      let dataforcheck = items.newpage ? items.newpage : items.children;
      let findobj = dataforcheck.find((o) => o.key === idcheck);
      if (findobj) {
        findobj.is_page_selected = !findobj.is_page_selected;
        if (findobj.is_page_selected) {
          info.checkedKeysPage.push(findobj.key);
          info.pageSelected.push(findobj.key);
        } else {
          info.checkedKeysPage = info.checkedKeysPage.filter(
            (x) => x != findobj.key
          );
          info.pageSelected = info.pageSelected.filter((x) => x != findobj.key);
        }
        return;
      } else {
        _.forEach(dataforcheck, (item) => {
          if (item.children && item.children.length > 0) {
            this.onCheckDataPage(item, idcheck, info);
          }
        });
      }
    },
    onCheckDataPages(items, idcheck, info, checked) {
      let dataforcheck = items.newpage ? items.newpage : items.children;
      let findobj = dataforcheck.find((o) => o.key === idcheck);
      if (findobj) {
        findobj.is_page_selected = checked;
        this.$forceUpdate();
      } else {
        _.forEach(dataforcheck, (item) => {
          if (item.children && item.children.length > 0) {
            this.onCheckDataPages(item, idcheck, info, checked);
          }
        });
      }
    },
    checkItemSelect(item) {
      let featureselect = [];
      _.forEach(item.features, (feature) => {
        if (feature.is_selected) {
          featureselect.push(feature);
        }
      });
      if (featureselect.length == item.features.length) {
        this.checkedKeysPage.push(item.id);
      }
    },
    setCollapse(e, item) {
      if (item) {
        if (e.target.id == "collapse") {
          item.activeKey = [];
        }
        if (e.target.id == "expand") {
          item.activeKey = item.activeKeyStart;
        }
      } else {
        _.forEach(this.dataProductsNew, (item) => {
          if (e.target.id == "collapse") {
            item.activeKey = [];
          }
          if (e.target.id == "expand") {
            item.activeKey = item.activeKeyStart;
          }
        });
      }
      this.$forceUpdate();
    },
    changeActivekey(key, item) {
      item.activeKey = key;
      this.$forceUpdate();
    },
    checkCollapse() {
      let activeKey = [];
      _.forEach(this.dataProductsNew, (item) => {
        if (item.activeKey && item.activeKey.length > 0) {
          activeKey.push(item);
        }
      });
      return activeKey.length;
    },
    setCollapseMenu(item) {
      item.isShowMenu = !item.isShowMenu;
      this.expandAllMenu = !this.expandAllMenu;
      this.$forceUpdate();
      setTimeout(() => {
        item.isShowMenu = !item.isShowMenu;
        this.$forceUpdate();
      });
    },
    setCheckAllMenu(item) {
      return item.featuresSelect.length == item.featuresAll.length;
    },
    setIndeterminatemenu(item) {
      return (
        item.featuresAll.length != item.featuresSelect.length &&
        item.featuresSelect.length > 0
      );
    },
    onChangAllMenu(e, item) {
      let checked = e.target.checked;
      if (checked) {
        item.menuSelected = item.checkedKeysMenuAll;
      } else {
        item.menuSelected = [];
      }
      this.$forceUpdate();
      this.setDataForSave();
    },
    onCheckAllMenu(e, item) {
      let checked = e.target.checked;
      if (checked) {
        item.menuChecked = item.featuresAll;
        item.featuresSelect = item.featuresAll;
      } else {
        item.menuChecked = [];
        item.featuresSelect = [];
      }
      this.setDataForSaveNew();
      this.$forceUpdate();
    },
    setCollapsePage(item) {
      item.isShowPage = !item.isShowPage;
      this.expandAllPage = !this.expandAllPage;
      this.$forceUpdate();
      setTimeout(() => {
        item.isShowPage = !item.isShowPage;
        this.$forceUpdate();
      });
    },
    setCheckAllPage(item) {
      return item.checkedKeysPageAll.length == item.pageSelected.length;
    },
    setIndeterminatepage(item) {
      return (
        item.checkedKeysPageAll.length != item.pageSelected.length &&
        item.pageSelected.length > 0
      );
    },
    onChangAllPage(e, item) {
      let checked = e.target.checked;
      if (checked) {
        item.pageSelected = item.checkedKeysPageAll;
      } else {
        item.pageSelected = [];
      }
      _.forEach(item.checkedKeysPageAll, (id) => {
        this.onCheckDataPages(item, id, item, checked);
      });
      this.$forceUpdate();
      this.setDataForSave();
    },
    setCheckAllFeaturesInPage(item) {
      return item.featuresAll.length == item.featuresSelected.length;
    },
    setIndeterminatefeaturesinpage(item) {
      return (
        item.featuresAll.length != item.featuresSelected.length &&
        item.featuresSelected.length > 0
      );
    },
    onChangAllFeaturesInPage(e, item) {
      let checked = e.target.checked;
      if (checked) {
        item.featuresSelected = item.featuresAll;
        item.checkedKeysPage = item.featuresAll;
      } else {
        item.featuresSelected = [];
        item.checkedKeysPage = [];
      }
      this.$forceUpdate();
      this.settestcheckallproduct();
    },
    checkKeys(checkedKeys, item) {
      _.forEach(checkedKeys, (element) => {
        let findobj = item.checkedKeysMenuAll.find((o) => o === element);
        checkedKeys = checkedKeys.filter((item) => {
          return item != findobj;
        });
      });
      return checkedKeys;
    },
    onScroll(event) {
      var scrollDiv = null;
      this.scrollData = [];
      _.forEach(this.dataProductsNew, (item) => {
        scrollDiv = document.getElementById(item.name);
        if (scrollDiv) {
          scrollDiv = scrollDiv.offsetTop;
          this.scrollData.push({
            offsetTop: scrollDiv,
            id: item.id,
            name: item.name,
          });
        }
      });
      let header = document.querySelector(".ant-layout-header").offsetHeight;
      let breadcrumbs =
        document.querySelector(".title-breadcrumbs").offsetHeight;
      let topPage = document.querySelector(".set-permission-top").offsetHeight;
      let topBox = document.querySelector(".box-right-top").offsetHeight;
      this.startBoxData = header + breadcrumbs + topPage + topBox + 56;
      var scrollTop = event.target.scrollTop + this.startBoxData;
      this.dataObjSelectscroll = [];
      this.dataObjSelectscroll = this.scrollData.filter((data) => {
        return data.offsetTop < scrollTop;
      });
      if (this.isLastItem) {
        this.dataObjSelectscroll = this.scrollData;
        this.isLastItem = false;
      }
      if (this.dataObjSelectscroll.length > 0) {
        this.productActive = this.dataObjSelectscroll.slice(-1)[0].name;
      }
      this.isSetCollapseAll = false;
    },
    setDataForSaveNew() {
      this.dataProductForSave = [];
      _.forEach(this.dataProductsNew, (element) => {
        let dataItemForSave = {};
        let featuresSelectAll = [];
        element.isSetPerMission = false;
        if (element.idDataLv && element.idDataLv != "none") {
          dataItemForSave.data_level_id = element.idDataLv;
        }
        if (element.featuresSelect && element.featuresSelect.length > 0) {
          _.forEach(element.featuresSelect, (feature) => {
            featuresSelectAll.push(feature);
          });
          dataItemForSave.feature_ids = featuresSelectAll;
        }
        if (element.features) {
          _.forEach(element.features, (feature) => {
            if (feature.is_selected) {
              featuresSelectAll.push(feature.id);
            }
          });
          if (featuresSelectAll.length > 0) {
            dataItemForSave.feature_ids = featuresSelectAll;
          }
        }
        if (isEmpty(dataItemForSave)) {
          element.isSetPerMission = isEmpty(dataItemForSave);
          dataItemForSave.id = element.id;
          this.dataProductForSave.push(dataItemForSave);
        }
        function isEmpty(obj) {
          return Object.keys(obj).length > 0;
        }
      });
      console.log(this.dataProductForSave, "this.dataProductForSave");
      if (this.isSetDataForCheckSave) {
        this.isSetDataForCheckSave = false;
        this.dataForCheckSave = JSON.stringify(this.dataProductForSave);
      }
      let dataProductForSave = JSON.stringify(this.dataProductForSave);
      this.disablebtnok = this.dataForCheckSave == dataProductForSave;
    },
    // onSelect(selectedKeys, info, item) {
    //   let isSelect = info.selected;
    //   let selectedNodes = info.node.dataRef.key;
    //   if (isSelect) {
    //     item.menuChecked.push(selectedNodes);
    //   } else {
    //     item.menuChecked = item.menuChecked.filter((data) => {
    //       return data != selectedNodes;
    //     });
    //   }
    //   this.onCheckMenuNew(selectedKeys, item);
    //   this.$forceUpdate();
    // },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/_mixin.scss";
.manage-page-detail {
  background: #fff;
  overflow: hidden;
  margin-top: 0;
  height: calc(100vh - 250px);
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }
}
.set-permission-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  margin: 0 16px;
  border-bottom: 1px solid $grey-grey-7;
  .ant-divider,
  .ant-divider-vertical {
    top: 0 !important;
    height: 30px;
  }
  .icon-arrow-nex {
    width: 15px;
    cursor: pointer;
    img {
      rotate: 180deg;
      width: 12px;
      margin-top: -2px;
    }
  }
  .logo-custom {
    border: 1px solid var(--grey-grey-8);
    border-radius: 4px;
    img {
      border-radius: 4px;
      width: 40px;
    }
  }
  .label-name-app {
    font-weight: 600;
    font-size: 14px;
    line-height: 1;
  }
  .label-package {
    color: $grey-grey-5;
    font-size: 12px;
    font-weight: normal;
  }
  .label-name-product {
    border: 1px solid;
    border-radius: 25px;
    padding: 0px 8px;
  }
  a {
    color: $color-body;
  }
}
.set-permission-body {
  padding: 16px;
  display: flex;
  a {
    color: $color-body;
  }
  .box-left {
    width: 250px;
    margin-right: 16px;
    border-right: 1px solid $grey-grey-7;
    overflow-y: auto;
    height: calc(100vh - 330px);
    .item-button-href {
      opacity: 0.7;
      padding: 4px;
      &.href-active {
        border-right: 2px solid $color-primary;
        background: var(--primary-gold-bzb-gold-9);
        font-weight: 500;
        opacity: 1;
        a {
          color: $color-primary;
        }
      }
    }
  }
  .box-right {
    width: calc(100% - 250px);
    .item-product {
      height: calc(100vh - 405px);
      padding-right: 8px;
      padding-top: 8px;
      overflow: auto;
      display: block;
    }
    .test001 {
      background: #f9f9f9;
      padding: 16px;
      margin-bottom: 16px;
      &.test001-active {
        border: 1px solid $color-primary;
      }
    }
    .lable-check-all {
      color: #000;
    }
    .feature-list {
      padding-left: 16px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
    .in-view {
      border: 1px solid $color-primary;
    }
    .set-border-bottom {
      border-bottom: 1px solid $grey-grey-7;
    }
    .item-title-product {
      color: #000;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .checkbox-all {
      border-bottom: 1px solid $grey-grey-10;
      padding-bottom: 12px;
      margin-bottom: 12px;
    }
    .item-title-feature {
      .title-item {
        font-size: 12px;
        opacity: 0.7;
      }
      .label-item {
        background: #fff;
        padding: 12px;
      }
    }
    .title-item-list {
      cursor: pointer;
    }
    a {
      color: var(--primary-gold-bzb-primary-bzb);
      text-decoration: none;
      .anticon {
        margin-top: -2px;
      }
    }
    a:hover {
      color: var(--primary-gold-bzb-gold-6);
      text-decoration: none;
    }
  }
}
.lable-length {
  opacity: 0.7;
  font-size: 12px;
}
.custom-footer-setpermission {
  position: absolute;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: right;
  height: 63px;
  background: #fff;
  bottom: 0;
  width: 100%;
}
.text-caption {
  line-height: 1;
}
</style>
<style lang="scss">
@import "@/style/_mixin.scss";
.set-permission-app {
  .ant-tree {
    li {
      white-space: pre-wrap;
      padding: 3px 10px 3px 5px;
      .ant-tree-switcher {
        line-height: 18px;
        width: 16px;
      }
      .ant-tree-node-content-wrapper {
        width: calc(100% - 50px);
        height: auto;
        cursor: default;
        &.ant-tree-node-selected,
        &:hover {
          background: none;
        }
      }
    }
    &.main-selected {
      .ant-tree-switcher {
        display: none;
      }
      .ant-tree-node-content-wrapper {
        padding-right: 0;
        width: calc(100% - 22px);
        // line-height: 1.2em;
        .ant-tree-title {
          @include flex(flex-end, right);
          vertical-align: top;
          span {
            @include flexcol-100();
          }
        }
        .app-name {
          padding-top: 2px;
          text-align: right;
          color: $color-grey-b4;
          line-height: 1.2em;
          padding-left: 10px;
        }
      }
    }
    .ant-tree-checkbox-disabled {
      display: none;
    }
  }
  .custom-title-checkbox {
    cursor: default;
    font-weight: normal;
  }
}
</style>
