var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{staticClass:"modal-with-content",attrs:{"centered":"","closable":false,"mask-closable":false},model:{value:(_vm.modalprocess.visible),callback:function ($$v) {_vm.$set(_vm.modalprocess, "visible", $$v)},expression:"modalprocess.visible"}},[_c('template',{slot:"title"},[_c('div',[_c('span',[_vm._v(_vm._s(_vm.modalprocess.title)+" : ")]),_c('span',{staticClass:"name-content"},[_vm._v(_vm._s(_vm.lbls.application_page.drw_title_package)+" - "+_vm._s(_vm.objDataSave.package_display_name)+" ")])])]),_c('div',[_c('a-form',{attrs:{"form":_vm.form,"layout":"vertical"}},[_c('div',{staticClass:"config-gallery mb-3",on:{"dragover":function($event){$event.preventDefault();},"drop":function($event){$event.preventDefault();}}},[_c('input',{ref:"file",staticClass:"d-none",attrs:{"type":"file","accept":"image/jpeg, image/png","multiple":""},on:{"change":_vm.handleChange}}),_c('label',[_vm._v(" "+_vm._s(_vm.lbls.application_page.img_upload.name)+" ")]),_c('div',{staticClass:"card-image-list"},[_c('draggable',{staticClass:"draggable-list w-100",model:{value:(_vm.fileListData),callback:function ($$v) {_vm.fileListData=$$v},expression:"fileListData"}},[_vm._l((_vm.fileListData),function(item){return _c('div',{key:item.picture_id,staticClass:"card-item"},[_c('div',{staticClass:"card-image"},[_c('img',{staticClass:"image",attrs:{"src":item.picture.includes(';base64') ||
                      item.picture.includes('http')
                        ? item.picture
                        : 'data:' +
                          item.file_type +
                          ';base64, ' +
                          item.picture},on:{"error":_vm.handleError}}),(_vm.fileListData.length > 0)?_c('div',{staticClass:"actions"},[_c('a',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.handleOpenPreview()}}},[_c('a-icon',{attrs:{"type":"eye"}})],1),_c('a-popconfirm',{attrs:{"title":_vm.lbls.application_page.modal.content_delete_img,"ok-text":_vm.lbls.application_page.modal.btn.delete,"cancel-text":_vm.lbls.btn.cancel},on:{"confirm":function($event){return _vm.handledelete(item)}}},[_c('a',[_c('a-icon',{attrs:{"type":"delete"}})],1)])],1):_vm._e()])])}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.fileListData.length < 1),expression:"fileListData.length < 1"}],staticClass:"upload-image-btn",attrs:{"slot":"footer"},on:{"click":function($event){return _vm.clickUpload()},"drop":_vm.dragFile},slot:"footer"},[_c('div',[_c('span',{staticClass:"icon"},[_c('a-icon',{staticClass:"fs-30",attrs:{"type":"plus"}})],1),_c('span',{staticClass:"text"},[_vm._v("Upload")])])])],2)],1),_c('div',{staticClass:"text-secondary-custom mt-1 text-right"},[_vm._v(" "+_vm._s(_vm.lbls.application_page.img_upload.placeholder)+" ")])]),_c('a-form-item',{attrs:{"label":_vm.lbls.application_page.input_appid.name}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'applicationprefix',
              {
                rules: [
                  {
                    required: true,
                    message: _vm.lbls.application_page.input_appid.required,
                  },
                  {
                    pattern: new RegExp(/^[A-Za-z0-9_-]{1,100}$/),
                    message: _vm.lbls.application_page.input_appid.pattern,
                  },
                  {
                    min: 4,
                    message: _vm.lbls.application_page.input_appid.min,
                  },
                ],
              },
            ]),expression:"[\n              'applicationprefix',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: lbls.application_page.input_appid.required,\n                  },\n                  {\n                    pattern: new RegExp(/^[A-Za-z0-9_-]{1,100}$/),\n                    message: lbls.application_page.input_appid.pattern,\n                  },\n                  {\n                    min: 4,\n                    message: lbls.application_page.input_appid.min,\n                  },\n                ],\n              },\n            ]"}],attrs:{"placeholder":_vm.lbls.application_page.input_appid.placeholder,"max-length":100},on:{"keyup":_vm.countChar}})],1),_c('p',{staticClass:"total-char"},[_vm._v(" "+_vm._s(_vm.totalCharAppID.toLocaleString())+" / "+_vm._s(_vm.maxLengthAppID.toLocaleString())+" ")]),_c('a-form-item',{attrs:{"label":_vm.lbls.application_page.tbl_colum_name,"validate-status":_vm.is_duplicate}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'name',
              {
                rules: [
                  {
                    required: true,
                    message: _vm.lbls.application_page.input_name.required,
                  },
                  {
                    min: 1,
                    message: _vm.lbls.application_page.input_name.min,
                  },
                ],
              },
            ]),expression:"[\n              'name',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: lbls.application_page.input_name.required,\n                  },\n                  {\n                    min: 1,\n                    message: lbls.application_page.input_name.min,\n                  },\n                ],\n              },\n            ]"}],attrs:{"max-length":_vm.maxLengthName,"placeholder":_vm.lbls.application_page.input_name.placeholder},on:{"blur":_vm.callCheckDuplicateName,"keyup":_vm.countChar}})],1),_c('p',{staticClass:"total-char",attrs:{"href":""}},[_vm._v(" "+_vm._s(_vm.totalCharName.toLocaleString())+" / "+_vm._s(_vm.maxLengthName.toLocaleString())+" ")]),_c('a-form-item',{attrs:{"label":_vm.lbls.application_page.tbl_colum_description,"validate-status":_vm.checkError('description') ? 'error' : '',"help":_vm.checkError('description') || ''}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'description',
              {
                rules: [
                  {
                    required: true,
                    message: _vm.lbls.application_page.input_description.required,
                  },
                  {
                    min: 1,
                    message: _vm.lbls.application_page.input_description.min,
                  },
                ],
              },
            ]),expression:"[\n              'description',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: lbls.application_page.input_description.required,\n                  },\n                  {\n                    min: 1,\n                    message: lbls.application_page.input_description.min,\n                  },\n                ],\n              },\n            ]"}],attrs:{"placeholder":_vm.lbls.application_page.input_description.placeholder,"rows":4,"max-length":_vm.maxLengthDescription},on:{"keyup":_vm.countChar}})],1),_c('p',{staticClass:"total-char",attrs:{"href":""}},[_vm._v(" "+_vm._s(_vm.totalCharDescription.toLocaleString())+" / "+_vm._s(_vm.maxLengthDescription.toLocaleString())+" ")])],1)],1),_c('template',{slot:"footer"},[_c('a-button',{on:{"click":_vm.handleClose}},[_vm._v(" "+_vm._s(_vm.modalprocess.cancelText ? _vm.modalprocess.cancelText : _vm.lbls.user_page.btn.cancel)+" ")]),_c('a-button',{attrs:{"type":"primary","disabled":_vm.disabledok},on:{"click":_vm.handleSave}},[_vm._v(" "+_vm._s(_vm.modalprocess.okText ? _vm.modalprocess.okText : _vm.lbls.user_page.btn.save)+" ")])],1)],2),_c('a-modal',{staticClass:"preview-image",attrs:{"centered":"","footer":""},model:{value:(_vm.modal2Visible),callback:function ($$v) {_vm.modal2Visible=$$v},expression:"modal2Visible"}},[_c('div',{staticClass:"p-3"},_vm._l((_vm.fileListData),function(item,key){return _c('div',{key:key},[_c('img',{staticClass:"image",attrs:{"src":item.picture.includes(';base64') || item.picture.includes('http')
              ? item.picture
              : 'data:' + item.file_type + ';base64, ' + item.picture}})])}),0)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }