<template>
  <div class="col-12 d-md-flex justify-content-between align-items-center mb-4">
    <div class="col-lg-6 col-md-6 col-sm-12 text mb-4 mb-md-0 pl-0 pr-0">
      <a-input-search
        v-model="searchData"
        :placeholder="lbls.crossaccount_page.input_search"
        class="input-search"
        size="large"
        @blur="onSearch"
        @search="onSearch"
      >
        <template slot="suffix">
          <a-icon
            v-if="isSearchData"
            style="margin-right: 5px; font-size: 10px"
            type="close-circle"
            theme="filled"
            class="icon-close-search-input"
            @click="closeSearchData()"
          />
        </template>
      </a-input-search>
    </div>
    <div
      class="col-lg-6 col-md-6 col-sm-12 button-action-list d-flex justify-content-end box-action-campaignlist pl-0 pr-0"
    >
      <a-button
        type="primary"
        ghost
        :disabled="!isPermissionCrossApp"
        @click="goToPage('Cross-Application')"
      >
        <a-icon type="swap" />
        {{ lbls.menu.crossapplication }}
      </a-button>

      <a-button
        type="primary"
        class="ml-3"
        :disabled="!isPermissionCrossApp"
        @click="goToPage('InvitationAccount')"
      >
        <a-icon type="unordered-list" />
        {{ lbls.menu.invitation_account }}
      </a-button>
    </div>
  </div>
</template>
<script>
import Cache from "@/helper/Cache.js";
import { config } from "@/config/bzbsconfig.js";
export default {
  name: "PageHeader",
  props: {
    datapageheader: {
      type: Object,
    },
  },
  data() {
    return {
      permission_key: "cross",
      data: {},
      searchData: "",
      isSearchData: false,
      isPermissionCrossApp: true,
      isPermissionCreatV2: !config.local,
    };
  },
  watch: {
    "$store.state.menu"() {
      this.handlePermission();
    },
  },
  created() {
    this.init();
    console.log(this.isPermissionCreatV2, "isPermissionCreatV2");
  },
  methods: {
    init() {
      this.data = this.datapageheader;
      this.exAccount = Cache.get("exAccount");
      this.handlePermission();
    },
    handlePermission() {
      if (this.$store.state.menu?.length) {
        let permission_page = this.handlePermissionPage(this.permission_key);
        if (permission_page?.length) {
          this.isPermissionCrossApp = this.checkArrowPermission(
            "cross-application-view",
            permission_page
          );
        }
      }
    },
    onSearch() {
      this.isSearchData = this.searchData != "";
      this.$emit("onsearch", this.searchData);
    },
    closeSearchData() {
      this.searchData = "";
      this.onSearch();
      this.$forceUpdate();
    },
    createOwnerAccount() {
      window.location = window.location.origin + "/createaccount";
    },
    createAccount() {
      this.$emit("createaccount");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/_mixin.scss";
.btn-detail-user {
  margin-right: 0 !important;
}
</style>
