<template>
  <div class="box-list-item-apps">
    <div>
      <a-input-search
        v-model="searchData"
        :placeholder="lbls.role_page.modal_add_app.input_search"
        class="w-100"
        size="large"
        @search="onSearch"
      >
        <template slot="suffix">
          <a-icon
            v-if="isSearchAPI"
            style="margin-right: 5px; font-size: 10px"
            type="close-circle"
            theme="filled"
            class="icon-close-search-input"
            @click="closeSearchData()"
          />
        </template>
      </a-input-search>
    </div>

    <hr />

    <div class="box-item-apps-body">
      <div v-for="(item, i) in data" :key="item.id" class="mb-2 mr-2">
        <div class="d-flex align-items-start item-app">
          <div class="ml-2 title-app-name">
            <div class="item-app-custom">
              <div class="app-img">
                <img
                  :src="`${item.logo_url}?v=${datetimestamp}`"
                  @error="handleError"
                />
              </div>
              <div class="ml-3">
                <div>{{ i + 1 }}.{{ item.name }}</div>
                <div class="text-secondary-custom mt-1">
                  {{ item.description }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box-item-apps-footer">
      <span>
        <span v-if="data.length && data.length > 0">Showing 1 to </span>
        <span>{{ data.length }} items</span>
        <span
          v-if="hasmoredataapp"
          class="custom-title-load-more"
          @click="callGetList()"
        >
          Load More</span
        >
      </span>
      <span v-if="isSearchAPI" class="font-weight-bold">
        <span> out of </span>
        <span>{{ dataapp.length }}</span>
        <span> items </span>
      </span>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
export default {
  name: "PageBreadcrumb",
  props: {
    dataapp: {
      type: Array,
    },
    hasmoredataapp: {
      type: Boolean,
    },
  },
  data() {
    return {
      innerHeight: 0,
      lastscrollTop: 0,
      searchData: "",
      data: [],
      itemSelect: [],
      isSearchAPI: false,
      hasMoredata: false,
      logo: require("@/assets/images/bzbs-logo1.png"),
      datetimestamp: moment().format("x"),
    };
  },
  watch: {
    dataapp() {
      this.data = _.cloneDeep(this.dataapp);
      this.hasMoredata = _.cloneDeep(this.hasmoredataapp);
      this.$forceUpdate();
    },
  },
  mounted() {
    this.innerHeight = window.innerHeight;
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    this.tableContent = document.querySelector(".box-item-apps-body");
    this.onScroll();
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.data = _.cloneDeep(this.dataapp);
      this.hasMoredata = _.cloneDeep(this.hasmoredataapp);
    },
    handleError(event) {
      event.target.src = this.logo;
    },
    onSearch() {
      this.isSearchAPI = false;
      if (this.searchData != "") {
        this.isSearchAPI = true;
      }
      this.$emit("onsearch", this.searchData);
    },
    onScroll() {
      if (this.tableContent) {
        this.tableContent.addEventListener("scroll", (event) => {
          let maxScroll = event.target.scrollHeight - event.target.clientHeight;
          let currentScroll = event.target.scrollTop;
          let isScrollDown = this.lastscrollTop !== event.target.scrollTop;
          let scrollPersent = (currentScroll / maxScroll) * 100;
          if (scrollPersent >= 95 && this.hasMoredata && isScrollDown) {
            this.hasMoredata = false;
            this.callGetList();
          } else {
            return;
          }
          this.lastscrollTop = event.target.scrollTop;
        });
      }
    },
    closeSearchData() {
      this.searchData = "";
      this.onSearch();
      this.$forceUpdate();
    },
    callGetList() {
      this.$emit("loadmore");
    },
    onResize() {
      this.innerHeight = window.innerHeight;
      this.$forceUpdate();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/_mixin.scss";
.modal-show-apps-body {
  .box-list-item-apps {
    border: 2px solid var(--primary-gold-bzb-primary-bzb);
    padding: 7px;
    font-weight: 500;
    background: #fff;
    .box-item-apps-body {
      height: calc(100vh - 450px);
      overflow: auto;
      .anticon {
        vertical-align: 5px;
      }
    }
    .box-item-apps-footer {
      border-top: 1px solid #dcdddf;
      padding-top: 8px;
      margin-top: 16px;
      min-height: 30px;
      font-weight: normal;
    }
    .title-app-name {
      line-height: 1;
      cursor: pointer;
    }
    .item-app {
      cursor: pointer;
      border: 1px solid var(--primary-gold-bzb-gold-8);
      padding: 8px;
      border-radius: 4px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.06);
    }
    .item-app:hover {
      border: 1px solid var(--primary-gold-bzb-primary-bzb);
      background: var(--primary-gold-bzb-gold-9);
    }
    .item-app-custom {
      display: flex;
      .app-img {
        border: 1px solid var(--grey-grey-8);
        border-radius: 4px;
        width: 50px;
        img {
          border-radius: 4px;
        }
      }
    }
  }
  .body-danger {
    border: 2px solid var(--red-red-6);
  }
  .show-border-bottom {
    border-bottom: 1px solid #dcdddf;
    padding-bottom: 8px;
    margin-bottom: 16px;
  }
  .text-secondary-custom {
    line-height: 1.2;
  }
}
</style>
