<template>
    <div>
      <a-modal
        v-model="modalprocess.visible"
        centered
        class="modal-with-content"
        :closable="false"
        :mask-closable="false"
      >
        <template slot="title">
          <div>
            <span>{{ modalprocess.title }} : </span>
            <span class="name-content"
              >{{ lbls.application_page.drw_title_package }} -
              {{ objDataSave.package_display_name }}
            </span>
          </div>
        </template>
        <div>
          <a-form :form="form" layout="vertical">
            <div class="config-gallery mb-3" @dragover.prevent @drop.prevent>
              <input
                ref="file"
                type="file"
                class="d-none"
                accept="image/jpeg, image/png"
                multiple
                @change="handleChange"
              />
              <label> {{ lbls.application_page.img_upload.name }} </label>
              <div class="card-image-list">
                <draggable v-model="fileListData" class="draggable-list w-100">
                  <div
                    v-for="item in fileListData"
                    :key="item.picture_id"
                    class="card-item"
                  >
                    <div class="card-image">
                      <img
                        class="image"
                        :src="
                          item.picture.includes(';base64') ||
                          item.picture.includes('http')
                            ? item.picture
                            : 'data:' +
                              item.file_type +
                              ';base64, ' +
                              item.picture
                        "
                        @error="handleError"
                      />
                      <div v-if="fileListData.length > 0" class="actions">
                        <a type="primary" @click="handleOpenPreview()">
                          <a-icon type="eye" />
                        </a>
                        <a-popconfirm
                          :title="lbls.application_page.modal.content_delete_img"
                          :ok-text="lbls.application_page.modal.btn.delete"
                          :cancel-text="lbls.btn.cancel"
                          @confirm="handledelete(item)"
                        >
                          <a><a-icon type="delete" /></a>
                        </a-popconfirm>
                      </div>
                    </div>
                  </div>
                  <div
                    v-show="fileListData.length < 1"
                    slot="footer"
                    class="upload-image-btn"
                    @click="clickUpload()"
                    @drop="dragFile"
                  >
                    <div>
                      <span class="icon"
                        ><a-icon type="plus" class="fs-30"
                      /></span>
                      <span class="text">Upload</span>
                    </div>
                  </div>
                </draggable>
              </div>
              <div class="text-secondary-custom mt-1 text-right">
                {{ lbls.application_page.img_upload.placeholder }}
              </div>
            </div>
            <a-form-item :label="lbls.application_page.input_appid.name">
              <a-input
                v-decorator="[
                  'applicationprefix',
                  {
                    rules: [
                      {
                        required: true,
                        message: lbls.application_page.input_appid.required,
                      },
                      {
                        pattern: new RegExp(/^[A-Za-z0-9_-]{1,100}$/),
                        message: lbls.application_page.input_appid.pattern,
                      },
                      {
                        min: 4,
                        message: lbls.application_page.input_appid.min,
                      },
                    ],
                  },
                ]"
                :placeholder="lbls.application_page.input_appid.placeholder"
                :max-length="100"
                @keyup="countChar"
              />
            </a-form-item>
            <p class="total-char">
              {{ totalCharAppID.toLocaleString() }} /
              {{ maxLengthAppID.toLocaleString() }}
            </p>
            <a-form-item
              :label="lbls.application_page.tbl_colum_name"
              :validate-status="is_duplicate"
            >
              <a-input
                v-decorator="[
                  'name',
                  {
                    rules: [
                      {
                        required: true,
                        message: lbls.application_page.input_name.required,
                      },
                      {
                        min: 1,
                        message: lbls.application_page.input_name.min,
                      },
                    ],
                  },
                ]"
                :max-length="maxLengthName"
                :placeholder="lbls.application_page.input_name.placeholder"
                @blur="callCheckDuplicateName"
                @keyup="countChar"
              />
            </a-form-item>
            <p class="total-char" href="">
              {{ totalCharName.toLocaleString() }} /
              {{ maxLengthName.toLocaleString() }}
            </p>
            <a-form-item
              :label="lbls.application_page.tbl_colum_description"
              :validate-status="checkError('description') ? 'error' : ''"
              :help="checkError('description') || ''"
            >
              <a-textarea
                v-decorator="[
                  'description',
                  {
                    rules: [
                      {
                        required: true,
                        message: lbls.application_page.input_description.required,
                      },
                      {
                        min: 1,
                        message: lbls.application_page.input_description.min,
                      },
                    ],
                  },
                ]"
                :placeholder="lbls.application_page.input_description.placeholder"
                :rows="4"
                :max-length="maxLengthDescription"
                @keyup="countChar"
              />
            </a-form-item>
            <p class="total-char" href="">
              {{ totalCharDescription.toLocaleString() }} /
              {{ maxLengthDescription.toLocaleString() }}
            </p>
          </a-form>
        </div>
        <template slot="footer">
          <a-button @click="handleClose">
            {{
              modalprocess.cancelText
                ? modalprocess.cancelText
                : lbls.user_page.btn.cancel
            }}
          </a-button>
          <a-button type="primary" :disabled="disabledok" @click="handleSave">
            {{
              modalprocess.okText ? modalprocess.okText : lbls.user_page.btn.save
            }}
          </a-button>
        </template>
      </a-modal>
      <!-- Modal Preview -->
      <a-modal v-model="modal2Visible" class="preview-image" centered footer="">
        <div class="p-3">
          <div v-for="(item, key) in fileListData" :key="key">
            <img
              class="image"
              :src="
                item.picture.includes(';base64') || item.picture.includes('http')
                  ? item.picture
                  : 'data:' + item.file_type + ';base64, ' + item.picture
              "
            />
          </div>
        </div>
      </a-modal>
      <!-- Modal Preview -->
    </div>
  </template>
  <script>
  import _ from "lodash";
  import ManageApplicationAPI from "@/core/Page/ManageApplicationAPI";
  import Cache from "@/helper/Cache.js";
  import fileReader from "@/helper/fileReader";
  import draggable from "vuedraggable";
  export default {
    name: "ManageProduct",
    components: { draggable },
    props: {
      modalprocess: {
        type: Object,
      },
      dataobjselect: {
        type: Object,
      },
    },
    data() {
      return {
        form: this.$form.createForm(this),
        logo: require("@/assets/images/bzbs-logo1.png"),
        disabledok: true,
        objDataSave: {},
        is_duplicate: "",
        helpmsg: "",
        maxLengthAppID: 100,
        totalCharAppID: 0,
        maxLengthName: 100,
        totalCharName: 0,
        maxLengthDescription: 4000,
        totalCharDescription: 0,
        fileReader,
        fileListData: [],
        modal2Visible: false,
      };
    },
    created() {
      this.init();
    },
    methods: {
      init() {
        this.exAccount = Cache.get("exAccount");
        this.objDataSave = this.dataobjselect;
      },
      handleClose() {
        this.modalprocess.visible = false;
        this.$emit("actionModal", "close");
      },
      handleSave(e) {
        this.objDataSave = [];
        e.preventDefault();
        this.form.validateFields((err, values) => {
          if (!err) {
            this.idModal = "confirm";
            this.objDataSave.name = values.name;
            this.objDataSave.description = values.description;
            this.objDataSave.buzzebees_application_Id = values.applicationprefix;
            if (this.fileListData.length > 0) {
              this.objDataSave.application_logo =
                this.fileListData[0].picture_file;
            }
            this.$emit("actionModal", this.objDataSave);
          }
        });
      },
      handleCheckFormat(e) {
        var text = e.currentTarget.value;
        if (e.currentTarget.id == "applicationprefix") {
          text = text.replace(/[^a-zA-Z0-9 ]/g, "");
          e.target.value = text.toUpperCase();
        }
      },
      autogen() {
        let autogen = Math.random().toString(36).substr(2);
        this.totalCharAppID = autogen.length;
        this.form.setFieldsValue({
          applicationprefix: autogen.toUpperCase(),
        });
        this.checkBTN();
      },
      countChar(e) {
        this.disabledok = true;
        if (e.target.id == "name") {
          this.is_duplicate = "";
          this.helpmsg = "";
          this.totalCharName = e.target.value.length;
          if (e.target.value.length < 1) {
            this.is_duplicate = "error";
          }
        }
        if (e.target.id == "description") {
          this.totalCharDescription = e.target.value.length;
        }
        if (e.target.id == "applicationprefix") {
          this.totalCharAppID = e.target.value.length;
        }
        this.checkBTN();
      },
      checkBTN() {
        this.disabledok = true;
        if (
          this.totalCharName >= 4 &&
          this.totalCharDescription >= 4 &&
          this.totalCharAppID >= 4 &&
          this.is_duplicate != "error"
        ) {
          this.disabledok = false;
        }
      },
      callCheckDuplicateName(e) {
        this.is_duplicate = "";
        if (e.target.value.length < 1) {
          this.form.validateFields(["name"]);
          this.is_duplicate = "error";
        } else {
          let params = {
            account_id: this.exAccount.account.id,
            application_name: e.target.value,
          };
          ManageApplicationAPI.getCheckDuplicateName(params)
            .then((res) => {
              if (res.data) {
                if (res.data.is_success) {
                  if (res.data.result.is_duplicate) {
                    this.is_duplicate = "error";
                    this.helpmsg = this.lbls.application_page.drw_title_helpmsg;
                    this.disabledok = true;
                  }
                }
              }
              this.handleLoading(false);
            })
            .catch((error) => {
              this.idModal = "error";
              this.handleLoading(true);
              this.errorApi(error);
            });
        }
      },
      checkError(fild) {
        const { getFieldError, isFieldTouched } = this.form;
        return isFieldTouched(fild) && getFieldError(fild);
      },
      handleChange(event) {
        _.forEach(event.target.files, (item) => {
          if (this.checkValidate(item)) {
            fileReader.getBase64(item).then((imageUrl) => {
              this.fileListData.push({
                picture: imageUrl,
                picture_file: item,
              });
            });
          }
        });
      },
      checkValidate(file) {
        const isImg = file.type === "image/jpeg" || file.type === "image/png";
        if (!isImg) {
          this.$error({
            title: this.lbls.application_page.modal_error.title_error,
            content: this.lbls.application_page.modal_error.wrong_type,
            centered: true,
            okText: this.lbls.application_page.modal_error.btn.ok,
            okType: "default",
          });
        }
        const isSize = file.size < 500 * 1024;
        if (!isSize) {
          this.$error({
            title: this.lbls.application_page.modal_error.title_error,
            content: this.lbls.application_page.modal_error.more_than,
            centered: true,
            okText: this.lbls.application_page.modal_error.btn.ok,
            okType: "default",
          });
        }
        return isImg && isSize;
      },
      dragFile(e) {
        _.forEach(e.dataTransfer.files, (item) => {
          if (this.checkValidate(item)) {
            fileReader.getBase64(item).then((imageUrl) => {
              this.fileListData.push({
                picture: imageUrl,
                picture_file: item,
              });
            });
          }
        });
      },
      clickUpload() {
        let fileInputElement = this.$refs.file;
        if (fileInputElement.value != "") {
          fileInputElement.value = "";
        }
        fileInputElement.click();
      },
      handleOpenPreview() {
        this.modal2Visible = true;
      },
      handledelete() {
        this.fileListData = [];
      },
      handleError(event) {
        event.target.src = "";
        this.fileListData = [];
      },
    },
  };
  </script>
  <style lang="scss">
  @import "@/style/_mixin.scss";
  </style>
  