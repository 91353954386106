<template>
  <AppLayout>
    <div
      :class="`${$route.name}`"
      class="campaigns-detail-main campaigns-detail-page page"
    >
      <TitleBreadcrumbs
        :databreadcrumbs="breadcrumbs"
        :title="lbls.crossaccountdetail_page.title_page"
      />
      <div class="row">
        <PageHeader @onsearch="onSearch($event)" />
      </div>
      <div v-if="isShowSkeleton" class="background-white p-5">
        <a-skeleton />
      </div>
      <div v-else>
        <TableList
          :datatable="data"
          :conditiontable="conditionTable"
          @callgetlist="callGetList"
          @handleedit="handleEdit($event)"
          @onsearchtable="onSearchTable($event)"
        />
        <ShowTotal
          :datashowtitle="data"
          :conditiontable="conditionTable"
          :pagesize="page_size"
          :totallist="totalList"
          @callgetlist="callGetList"
          @setpagesize="setPageSize($event)"
        />
      </div>
      <!-- Modal -->
      <div v-if="isModalProcess">
        <ModalProcess
          :modalprocess="modalprocess"
          @onsearch="onSearchModal($event)"
          @actionModal="actionModal($event)"
        >
        </ModalProcess>
      </div>
      <div v-if="isModalResponse">
        <ModalAlert
          :modalalert="modalAlert"
          :alertdata="alertData"
          @handlemodalok="handlemodalOk"
        >
        </ModalAlert>
      </div>
    </div>
  </AppLayout>
</template>
<script>
import _ from "lodash";
import AppLayout from "@/components/Layout/LayoutMaster.vue";
import manageCrossAccountAPI from "@/core/Page/ManageCrossAccountAPI";
import manageCrossAPI from "@/core/Page/ManageCrossApplicationAPI";
import Cache from "@/helper/Cache.js";
import ShowTotal from "@/components/Layout/ShowTotal";
import PageHeader from "@/module/Cross/ManageCrossAccountDetail/components/Layout/PageHeader.vue";
import TableList from "@/module/Cross/ManageCrossAccountDetail/components/Table/TableList";
import ModalProcess from "@/module/Cross/ManageCrossAccountDetail/components/Modal/ModalProcess.vue";
import ModalAlert from "@/components/Modal/Alertmodal.vue";

export default {
  name: "ManageUser",
  components: {
    AppLayout,
    ShowTotal,
    PageHeader,
    TableList,
    ModalProcess,
    ModalAlert,
  },
  data() {
    return {
      permission_key: "cross",
      action_key: "existing-view",
      data: [],
      dataAccount: [],
      breadcrumbs: [],
      totalList: 0,
      nameSearch: "",
      nameSearchAccount: "",
      isShowSkeleton: true,
      isModalResponse: false,
      isModalProcess: false,
      idModal: "",
      isDelete: false,
      modalbtnok: "",
      contentModal: "",
      page: 0,
      page_size: 25,
      accountName: "",
      page_acc: 0,
      page_size_acc: 200,
      conditionTable: {},
      alertData: {},
      modalprocess: {
        visible: false,
        title: "",
        key: "",
        message: "",
        okText: "",
        cancelText: "",
      },
      modalAlert: {
        visible: false,
        id: "",
        title: "",
        message: "",
        okText: "",
        cancelText: "",
      },
    };
  },
  watch: {
    lbls() {
      this.setBreadcrumbs();
    },
    "$store.state.menu"() {
      this.handlePermissionPage(this.permission_key, this.action_key);
    },
  },
  created() {
    this.setBreadcrumbs();
    this.init();
  },
  methods: {
    init() {
      if (this.$route.query.id && this.$route.query.name) {
        this.accountName = this.$route.query.name;
        this.exAccount = Cache.get("exAccount");
        Promise.all([this.setCallGetList()]).then((resgetdetail) => {
          let isCallListSuccess = resgetdetail[0];
          if (isCallListSuccess) {
            this.moreData = true;
            _.forEach(this.columns, (column) => {
              if (column.keys)
                column.title = this.mylbl(
                  "crossaccountdetail_page." + [column.keys]
                );
            });
            _.forEach(this.routes, (column) => {
              column.breadcrumbName = this.mylbl("menu." + [column.keys]);
            });
            console.log(this.routes, "this.routes");
          } else {
            this.handleLoading(false);
          }
        });
      } else {
        this.$router.push({ name: "ManageCrossAccount" });
      }
    },
    setBreadcrumbs() {
      this.breadcrumbs = [];
      this.breadcrumbs.push({
        breadcrumbName: this.lbls.menu.crossaccount,
        name: "ManageCrossAccount",
      });
      this.breadcrumbs.push({
        breadcrumbName: this.$route.query.name,
        name: "ManageCrossAccount",
      });
    },
    setCallGetList() {
      this.isShowSkeleton = true;
      this.page = 0;
      this.page_size = this.page_size;
      this.data = [];
      this.callGetList();
    },
    setCallGetListAccount() {
      this.page_acc = 0;
      this.page_size_acc = this.page_size_acc;
      this.dataAccount = [];
      this.callGetListAccount();
    },
    onSearch(searchData) {
      let text = searchData;
      if (text != this.nameSearch && !this.isShowSkeleton) {
        this.isShowSkeleton = true;
        this.nameSearch = text != undefined ? text : "";
        this.setCallGetList();
      } else {
        return;
      }
    },
    onSearchModal(searchData) {
      let text = searchData;
      if (text != this.nameSearchAccount) {
        this.nameSearchAccount = text != undefined ? text : "";
        this.setCallGetListAccount();
      } else {
        return;
      }
    },
    onSearchTable(data) {
      this.totalList = data.totalList >= 0 ? data.totalList : this.totalList;
      this.conditionTable.isFilters = data.isFilters;
    },
    callGetList() {
      return new Promise((resolve) => {
        let isCallDetailSuccess = false;
        this.page = this.page + 1;
        let params = {
          account_id: this.exAccount.account.id,
          cross_account_id: this.$route.query.id,
          page: this.page,
          page_size: this.page_size,
        };
        if (this.nameSearch) {
          params.filter = this.nameSearch;
        }
        manageCrossAccountAPI
          .getSearchApplication(params)
          .then((res) => {
            if (res.data && res.data.result) {
              isCallDetailSuccess = true;
              let item = res.data.result.items;
              let paging = res.data.result.paging;
              if (item.length > 0) {
                _.forEach(res.data.result.items, (item) => {
                  item.id = item.application.id;
                });
                this.data.push(...res.data.result.items);
              }
              this.totalList = this.data.length;
              this.conditionTable = {
                hasMoreData: paging.has_more_data,
                isFilters: false,
              };
              this.product = res.data.result;
              resolve(isCallDetailSuccess);
            }
            resolve(isCallDetailSuccess);
            this.handleLoading(false);
            this.isShowSkeleton = false;
          })
          .catch((error) => {
            this.idModal = "error";
            this.handleLoading(true);
            this.errorApi(error);
            resolve(isCallDetailSuccess);
          });
      });
    },
    callGetListAccount() {
      this.page_acc = this.page_acc + 1;
      let params = {
        account_id: this.exAccount.account.id,
        page: this.page_acc,
        page_size: this.page_size_acc,
      };
      if (this.nameSearchAccount) {
        params.filter = this.nameSearchAccount;
      }
      manageCrossAccountAPI
        .getSearch(params)
        .then((res) => {
          this.dataAccount = [];
          if (res.data && res.data.result) {
            let items = res.data.result.items;
            // let paging = res.data.result.paging;
            if (items.length > 0) {
              _.forEach(items, (item) => {
                item.id = item.account.id;
                item.is_select = false;
                if (item.owner_user) {
                  item.owner_user.name = item.owner_user.FirstName
                    ? item.owner_user.FirstName
                    : "";
                  if (item.owner_user.LastName) {
                    item.owner_user.name =
                      item.owner_user.name + " " + item.owner_user.LastName;
                  }
                }
              });
              this.dataAccount.push(...items);
            }
            this.showModalProcess();
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callPostRelocate(value) {
      this.handleLoading(true);
      var valueSave = {
        account_id: this.exAccount.account.id,
        source_account_id: this.$route.query.id,
        source_application_id: this.objRecord.id,
        target_account_id: value.target_account_id,
        target_package_id: value.target_package_id,
      };
      var valueSaveJson = JSON.stringify(valueSave);
      manageCrossAPI
        .postSaveRelocate(valueSaveJson)
        .then((res) => {
          if (res.status == "200" && res.data.result) {
            this.idModal = "success";
            this.closeModalProcess();
            setTimeout(() => {
              this.titleModal = this.lbls.role_page.modal.title_success;
              this.contentModal =
                this.lbls.crossaccountdetail_page.modal_process.title_success;
              this.showModalSuccess();
            }, 500);
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    handlemodalOk() {
      if (this.isDelete) {
        this.postDeleteData();
      }
      this.isModalResponse = false;
    },
    handleEdit(record) {
      this.handleLoading(true);
      this.objRecord = {};
      this.modalprocess = {};
      this.objRecord = record;
      this.titleModalProcess =
        this.lbls.crossaccountdetail_page.modal_process.title_modal;
      this.setCallGetListAccount();
    },
    showModalProcess() {
      this.isModalProcess = true;
      this.modalprocess = {
        visible: true,
        title: this.titleModalProcess,
        record: this.objRecord,
        key: "crossaccountdetail_page.",
        data: this.dataAccount,
      };
      this.handleLoading(false);
    },
    closeModalProcess() {
      this.isModalProcess = false;
    },
    showModalSuccess() {
      this.handleLoading(false);
      let secondsToGo = this.$store.state.secondstogo;
      const modal = this.$success({
        centered: true,
        autoFocusButton: null,
        title: this.contentModal,
        okType: "default",
        okText: `Ok (${secondsToGo})`,
      });
      const interval = setInterval(() => {
        secondsToGo -= 1;
        modal.update({
          okText: `Ok (${secondsToGo})`,
        });
      }, 1000);
      setTimeout(() => {
        clearInterval(interval);
        modal.destroy();
      }, secondsToGo * 1000);
      this.init();
    },
    actionModal(value) {
      if (value == "close") {
        this.isModalProcess = false;
      } else {
        this.callPostRelocate(value);
      }
    },
  },
};
</script>
<style lang="scss">
@import "@/style/_mixin.scss";
</style>
