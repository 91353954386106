<template>
  <div class="campaign-list-table box-icon-a-disabled">
    <div class="title-table-remark">
      {{ title_table_description }}
    </div>
    <a-table
      id="campaign-list-table"
      row-key="id"
      :columns="columns"
      :data-source="data"
      :scroll="{ y: innerHeight - 330, x: 1300 }"
      :pagination="false"
      :locale="{
        filterConfirm: lbls.role_page.btn.ok,
        filterReset: lbls.role_page.btn.reset,
      }"
      :custom-row="customRowTable"
      @change="handleTableChange"
    >
      <div
        slot="filterDropdown"
        slot-scope="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }"
        style="padding: 8px"
      >
        <a-input
          v-ant-ref="(c) => (searchInput = c)"
          :placeholder="`${lbls.btn.search}${column.title}`"
          :value="selectedKeys[0]"
          style="
            width: 188px;
            margin-bottom: 8px;
            display: block;
            font-size: 12px;
          "
          @change="
            (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
          "
          @pressEnter="
            () => handleTableSearch(selectedKeys, confirm, column.dataIndex)
          "
        />
        <a-button
          type="primary"
          style="width: 90px; margin-right: 8px"
          @click="
            () => handleTableSearch(selectedKeys, confirm, column.dataIndex)
          "
        >
          {{ lbls.btn.search }}
        </a-button>
        <a-button
          style="width: 90px"
          @click="() => handleTableReset(clearFilters)"
        >
          {{ lbls.btn.reset }}
        </a-button>
      </div>
      <a-icon
        slot="filterIcon"
        slot-scope="filtered"
        type="search"
        :style="{ color: filtered ? '#108ee9' : undefined }"
      />
      <template slot="template-title-name" slot-scope="text, record">
        <div class="d-flex align-items-center">
          <div class="click-goto">
            <a-tooltip>
              <template slot="title">
                {{ lbls.role_page.btn.manage }} {{ text }}
              </template>
              <router-link
                :to="{
                  name: 'ManageAssignApplicationstoRole',
                  query: {
                    id: record.id,
                  },
                }"
              >
                {{ text }}
              </router-link>
            </a-tooltip>
          </div>
        </div>
      </template>
      <template slot="template-title-description" slot-scope="text">
        <div>{{ text }}</div>
      </template>
      <template slot="template-status" slot-scope="text">
        <a-tag v-if="text" :color="'green'" class="tag-status">
          <a-badge
            :status="'success'"
            :text="lbls.role_page.title_status_active"
          />
        </a-tag>
        <a-tag v-else :color="'red'" class="tag-status">
          <a-badge
            :status="'error'"
            :text="lbls.role_page.title_status_inactive"
          />
        </a-tag>
      </template>
      <template slot="template-products" slot-scope="text, record">
        <span v-if="text && text.length > 0" class="products-custom">
          <div v-for="(product, index) in text" :key="product.id">
            <div v-if="index <= checkShowMore(record)">
              <div class="d-flex align-items-center">
                <div class="w-100">
                  <span v-if="record.products.length > 1"
                    >{{ index + 1 }}.
                  </span>
                  <span>{{ product.name }}</span>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="text && text.length > 3"
            class="show-hide-custom mt-2"
            @click="loadMore(record)"
          >
            <span>
              {{
                record.isShowMore
                  ? lbls.role_page.btn.hide
                  : lbls.role_page.btn.more
              }}
            </span>
            <span v-if="!record.isShowMore"> ({{ text.length }})</span>
            <span class="ml-2">
              <a-icon v-if="record.isShowMore" type="caret-up" />
              <a-icon v-else type="caret-down" />
            </span>
          </div>
        </span>
        <span v-else> - </span>
      </template>
      <template slot="template-edit" slot-scope="text, record">
        <div class="action-list justify-content-center">
          <a-tooltip :disabled="!isPermissionAssign" placement="top">
            <template slot="title">
              {{ lbls.role_page.btn.assign_to_role }}
            </template>
            <a @click="goToPageWithRecord('ManageAssigntoRole', record)">
              <a-icon type="usergroup-add" />
            </a>
          </a-tooltip>
          <a-tooltip
            v-if="supportVersion3"
            :disabled="!isPermissionSetting"
            placement="top"
          >
            <template slot="title">
              {{ lbls.role_page.btn.assign_applications_to_role }}
            </template>
            <router-link
              :to="{
                name: 'ManageAssignApplicationstoRole',
                query: {
                  id: record.id,
                },
              }"
            >
              <a-icon type="plus-square" />
            </router-link>
          </a-tooltip>
          <a-tooltip
            v-if="supportVersion3"
            :disabled="!isPermissionSetting"
            placement="top"
          >
            <template slot="title">
              {{ lbls.role_page.btn.manage }}
            </template>
            <router-link
              :to="{
                name: 'ManageRoleSetPerMission',
                query: {
                  id: record.id,
                  name: record.name,
                },
              }"
            >
              <a-icon type="setting" />
            </router-link>
          </a-tooltip>
          <a-tooltip
            :disabled="!isPermissionEdit || record.name == configAdminName"
            placement="top"
          >
            <template slot="title">
              {{ mylbl("role_page.btn.edit") }}
            </template>
            <a @click="handleEdit(record)">
              <a-icon type="edit" />
            </a>
          </a-tooltip>
          <a-tooltip
            v-if="supportVersion3"
            :disabled="!isPermissionCopy"
            placement="topLeft"
          >
            <template slot="title">
              {{ mylbl("role_page.btn.copy") }}
            </template>
            <a @click="handleCopyNew(record)">
              <a-icon type="copy" />
            </a>
          </a-tooltip>
          <a-tooltip
            v-if="supportVersion2"
            :disabled="!isPermissionSetting"
            placement="top"
          >
            <template slot="title">
              {{ lbls.role_page.btn.manage }} (v2)
            </template>
            <a
              style="color: black"
              @click="goToPageWithRecord('ManageAssignRole', record)"
            >
              <a-icon type="setting" />
            </a>
          </a-tooltip>
          <a-tooltip
            v-if="supportVersion2"
            :disabled="!isPermissionCopy"
            placement="top"
          >
            <template slot="title">
              {{ mylbl("role_page.btn.copy") }} (v2)
            </template>
            <a style="color: black" @click="handleCopy(record)">
              <a-icon type="copy" />
            </a>
          </a-tooltip>
        </div>
      </template>
    </a-table>
  </div>
</template>
<script>
import _ from "lodash";
import { config } from "@/config/bzbsconfig.js";
import PermissionHelper from "@/helper/PermissionHelper";

export default {
  name: "ManageProduct",
  components: {},
  props: {
    datatable: {
      type: Array,
    },
    conditiontable: {
      type: Object,
    },
  },
  data() {
    return {
      permission_key: "role",
      innerHeight: 0,
      lastscrollTop: 0,
      totalList: 0,
      tableContentHeight: 0,
      tableContentWidth: 0,
      searchInput: null,
      data: [],
      dataStart: [],
      productFilters: [],
      isFilters: false,
      title_table_description: "",
      supportVersion2: config?.businessRule?.support?.version2 ?? false,
      supportVersion3: config?.businessRule?.support?.version3 ?? true,
      columns: [
        {
          id: "Status",
          title: "Status",
          width: "135px",
          keys: "tbl_colum_status",
          dataIndex: "is_active",
          scopedSlots: { customRender: "template-status" },
          filters: [
            {
              text: "title_status_active",
              value: "true",
            },
            {
              text: "title_status_inactive",
              value: "false",
            },
          ],
          onFilter: (value, record) => {
            value = value == "true";
            return record.is_active === value;
          },
        },
        {
          id: "RoleName",
          title: "Role Name",
          keys: "tbl_colum_name",
          dataIndex: "name",
          width: "350px",
          sorter: (a, b) => a.name.localeCompare(b.name),
          scopedSlots: { customRender: "template-title-name" },
        },
        {
          id: "RoleDescription",
          title: "Role Description",
          keys: "tbl_colum_description",
          dataIndex: "description",
          width: "350px",
          scopedSlots: { customRender: "template-title-description" },
        },
        {
          id: "Products",
          title: "Role",
          keys: "tbl_colum_products",
          dataIndex: "products",
          filters: [],
          scopedSlots: { customRender: "template-products" },
          onFilter: (value, record) => {
            if (value == "none") {
              return record.products.length == 0;
            } else {
              let findobj = record.products.find((o) => o.id === value);
              if (findobj) {
                record.idfind = findobj.id;
              }
              return record.idfind === value;
            }
          },
        },
        {
          title: "",
          align: "center",
          width: config.businessRule.support.version2 ? "190px" : "140px",
          fixed: "right",
          scopedSlots: { customRender: "template-edit" },
        },
      ],
      isPermissionAssign: true,
      isPermissionSetting: true,
      isPermissionEdit: true,
      isPermissionCopy: true,
      configAdminName: PermissionHelper.configAdminName()
    };
  },
  watch: {
    datatable() {
      this.setDataFilters();
    },
    lbls() {
      this.columns = _.cloneDeep(this.columnsStart);
      this.setColumnTable();
      this.title_table_description = this.lbls.role_page.title_table_description
        ? this.lbls.role_page.title_table_description
        : "*Double-click a table row to edit an item.";
    },
    "$store.state.menu"() {
      this.handlePermission();
    },
  },
  mounted() {
    this.innerHeight = window.innerHeight;
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    this.tableContent = document.querySelector(".ant-table-body");
    this.tableContentHeight =
      this.tableContent.offsetHeight + this.setHeightDefualt;
    // this.checkLayout();
    this.onScroll();
  },
  created() {
    this.title_table_description = this.lbls.role_page.title_table_description
      ? this.lbls.role_page.title_table_description
      : "*Double-click a table row to edit an item.";
    this.init();
  },
  methods: {
    init() {
      this.data = this.datatable;
      this.dataStart = this.datatable;
      this.columnsStart = _.cloneDeep(this.columns);
      this.setColumnTable();
      this.setDataFilters();
      this.handlePermission();
    },
    handlePermission() {
      if (this.$store.state.menu?.length) {
        let permission_page = this.handlePermissionPage(this.permission_key);
        if (permission_page?.length) {
          this.isPermissionAssign = this.checkArrowPermission(
            "role-assign",
            permission_page
          );
          this.isPermissionSetting = this.checkArrowPermission(
            "role-setting",
            permission_page
          );
          this.isPermissionEdit = this.checkArrowPermission(
            "role-edit",
            permission_page
          );
          this.isPermissionCopy = this.checkArrowPermission(
            "role-copy",
            permission_page
          );
        }
      }
    },
    setColumnTable() {
      _.forEach(this.columns, (column) => {
        if (column.keys) {
          column.title = this.lbls.role_page[column.keys];
        }
        if (column.filters) {
          _.forEach(column.filters, (filters) => {
            filters.text = this.lbls.role_page[filters.text];
          });
        }
      });
    },
    setDataFilters() {
      this.productFilters.push({
        text: "-",
        value: "none",
      });
      _.forEach(this.datatable, (element) => {
        if (element.products) {
          _.forEach(element.products, (product) => {
            this.productFilters.push({
              text: product.display_name,
              value: product.id,
            });
          });
        }
      });
      this.productFilters = [
        ...new Map(
          this.productFilters.map((item) => [item.text, item])
        ).values(),
      ];
      let findcolumns = this.columns.find((o) => o.id == "Products");
      if (findcolumns) {
        findcolumns.filters = this.productFilters;
      }
      this.$forceUpdate();
    },
    onScroll() {
      this.tableContent.addEventListener("scroll", (event) => {
        let maxScroll = event.target.scrollHeight - event.target.clientHeight;
        let currentScroll = event.target.scrollTop;
        let isScrollDown = this.lastscrollTop !== event.target.scrollTop;
        let scrollPersent = (currentScroll / maxScroll) * 100;
        if (
          scrollPersent >= 95 &&
          this.conditiontable.hasMoreData &&
          isScrollDown &&
          !this.isFilters
        ) {
          this.conditiontable.hasMoreData = false;
          this.handleLoading(true);
          this.callGetList();
        } else {
          return;
        }
        this.lastscrollTop = event.target.scrollTop;
      });
    },
    handleTableSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleTableReset(clearFilters) {
      clearFilters();
      this.searchText = "";
      this.valueStatus = "";
      this.data = this.dataStart;
    },
    handleMenuClick(e, item) {
      if (item) {
        if (e.key == "edit") {
          this.handleEdit(item);
        } else if (e.key == "copy") {
          this.handleCopy(item);
        }
      }
    },
    handleEdit(record) {
      this.$emit("handleedit", record);
    },
    handleCopy(record) {
      this.$emit("handlecopy", record);
    },
    handleCopyNew(record) {
      this.$emit("handlecopynew", record);
    },
    handleDelete(record) {
      this.$emit("handledelete", record);
    },
    handleDeleteRole(record, tag) {
      let data = {
        record: record,
        tag: tag,
      };
      this.$emit("handledeleterole", data);
    },
    callGetList() {
      this.$emit("callgetlist");
    },
    onResize() {
      this.innerHeight = window.innerHeight;
      // this.checkLayout();
      this.$forceUpdate();
    },
    checkLayout() {
      let tableWidth = document.querySelector(".ant-table-tbody");
      this.tableContentWidth = tableWidth.offsetWidth;
      this.columns[this.columns.length - 1] = this.unFixedColumn(this.columns);
      if (this.boxDetailWidth() <= this.tableContentWidth) {
        this.columns[this.columns.length - 1] = this.fixedColumn(this.columns);
      }
    },
    handleTableChange(pagination, filters, sorte, currentData) {
      console.log(pagination, filters, sorte);
      let isFilters = false;
      let isFiltersStatus = filters["is_active"] ? filters["is_active"] : [];
      let isFiltersProducts = filters["products"] ? filters["products"] : [];
      isFiltersStatus = isFiltersStatus.length > 0;
      isFiltersProducts = isFiltersProducts.length > 0;
      isFilters = isFiltersStatus || isFiltersProducts;
      this.isFilters = isFilters;
      this.totalList = currentData.currentDataSource.length;
      let onsearchData = {
        totalList: this.totalList,
        isFilters: this.isFilters,
      };
      this.$emit("onsearchtable", onsearchData);
    },
    loadMore(record) {
      record.isShowMore = record.isShowMore == true;
      record.isShowMore = !(record.isShowMore != false);
      this.$forceUpdate();
    },
    checkShowMore(record) {
      let lengthData = 2;
      if (record.isShowMore) {
        lengthData = record.products.length;
      }
      return lengthData;
    },
    customRowTable(record, index) {
      return {
        attrs: {
          id: index,
        },
        on: {
          dblclick: () => {
            if (record.name != this.configAdminName) {
              this.handleEdit(record);
            }
          },
        },
      };
    },
    goToPageWithRecord(page, record) {
      this.$router.push({
        name: page,
        query: {
          id: record.id,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/_mixin.scss";
.ant-table-fixed {
  width: 900px;
}
.ant-dropdown-menu-item {
  display: flex;
  align-items: center;
  .item-left {
    width: 25px;
  }
}
.products-custom {
  line-height: 1.2;
  .show-hide-custom {
    cursor: pointer;
    font-size: 12px;
    color: $color-primary !important;
    font-weight: 600;
  }
  .img-rotate {
    transform: rotate(180deg);
  }
}
</style>
<style lang="scss">
@import "@/style/_mixin.scss";
.table-role-info {
  colgroup {
    col {
      min-width: 130px !important;
    }
  }
}
</style>
