const apiConfig = {
  scopes: [
    "https://devaadb2cbuzzebees.onmicrosoft.com/368a589d-9781-41f3-9b55-e22ab2b43e8a/Access",
  ],
};
const b2cPolicies = {
  names: {
    signUpSignIn: "B2C_1A_SIGNUP_SIGNIN",
    editProfile: "B2C_1A_PROFILE_EDIT",
    changePassword: "B2C_1A_PROFILE_EDIT_PASSWORD_CHANGE",
  },
  authorities: {
    signUpSignIn: {
      authority:
        "https://devaadb2cbuzzebees.b2clogin.com/devaadb2cbuzzebees.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN",
    },
    editProfile: {
      authority:
        "https://devaadb2cbuzzebees.b2clogin.com/devaadb2cbuzzebees.onmicrosoft.com/B2C_1A_PROFILE_EDIT",
    },
    changePassword: {
      authority:
        "https://devaadb2cbuzzebees.b2clogin.com/devaadb2cbuzzebees.onmicrosoft.com/B2C_1A_PROFILE_EDIT_PASSWORD_CHANGE",
    },
  },
  authorityDomain: "devaadb2cbuzzebees.b2clogin.com",
};
export const config = {
  appName: "Permission Management",
  debug: true,
  apiVersion: "/v1.0",
  apiVersion2: "/v2.0",
  themeVersion: "4",
  apiBaseUrl: "https://dev-api-product-platform-external.azurewebsites.net/api",
  bzbBlobUrl: "https://devstoragebuzzebees.blob.core.windows.net",
  extBlobUrl: "https://devproductplatformext.blob.core.windows.net",
  themeUrl:
    "https://devproductization.blob.core.windows.net/themebackoffice/assets",
  adAPI:
    "https://dev-api-active-directory-b2c-wrapper.azurewebsites.net/ppperm/api",
  apiAnnouncement: "https://dev-api-product-announcement.azurewebsites.net",
  msalConfig: {
    auth: {
      clientId: "a9c3b4f5-fa75-4c1c-9ab4-4bcec03f3183",
      authority: b2cPolicies.authorities.signUpSignIn.authority,
      knownAuthorities: [b2cPolicies.authorityDomain],
      redirectUri: window.location.origin + "/redirectms",
      postLogoutRedirectUri: window.location.origin,
    },
    cache: {
      cacheLocation: "localStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      allowRedirectInIframe: true,
    },
  },
  b2cPolicies: b2cPolicies,
  loginRequest: {
    scopes: ["openid", ...apiConfig.scopes],
    // prompt: "select_account",
  },
  tokenRequest: {
    scopes: [...apiConfig.scopes],
    forceRefresh: false,
  },
  refeshToken: {
    scopes: [...apiConfig.scopes],
    forceRefresh: false,
  },
  businessRule: {
    webFor: {
      TH: true, //Thailand
      PH: false, //Philippines
    },
    support: {
      version3: true,
    },
  },
};
