<template>
  <div class="col-12 d-md-flex justify-content-between align-items-center mb-3">
    <div class="col-lg-6 col-md-6 col-sm-12 text mb-4 mb-md-0 pl-0 pr-0">
      <a-input-search
        v-model="searchData"
        :placeholder="lbls.crossapplication_page.input_search"
        class="input-search"
        size="large"
        @blur="onSearch"
        @search="onSearch"
      >
        <template slot="suffix">
          <a-icon
            v-if="isSearchData"
            style="margin-right: 5px; font-size: 10px"
            type="close-circle"
            theme="filled"
            class="icon-close-search-input"
            @click="closeSearchData()"
          />
        </template>
      </a-input-search>
    </div>
  </div>
</template>
<script>
export default {
  name: "PageHeader",
  props: {
    datapageheader: {
      type: Object,
    },
  },
  data() {
    return {
      data: {},
      searchData: "",
      isSearchData: false,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.data = this.datapageheader;
    },
    handleCreate() {
      this.$emit("handlecreate");
    },
    onSearch() {
      this.isSearchData = this.searchData != "";
      this.$emit("onsearch", this.searchData);
    },
    closeSearchData() {
      this.searchData = "";
      this.onSearch();
      this.$forceUpdate();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/_mixin.scss";
</style>
