import Vue from "vue";
import Cache from "@/helper/Cache.js";
import Auth from "@/core/Account/service/Auth";
import Locale from "@/helper/locale/locale.js";
import userAPI from "@/core/Page/ManageUserAPI";
import * as Msal from "@azure/msal-browser";
import { config } from "@/config/bzbsconfig.js";
import { store, state } from "@/store";
import router from "@/router";

//Main key.
const KEY_COOKIE_AUTH = "Auth";

/* eslint-disable */
export default {
  config: function () {
    return Vue.bzbsConfig;
  },
  initial() {
    return new Promise((resolve) => {
      Vue.initFinished = false;
      Locale.callBlobVersion().then(() => {
        Locale.getLbl();
        resolve();
      });
    });
  },
  getAuth() {
    return Cache.get(KEY_COOKIE_AUTH);
  },
  setAuth(auth) {
    return Cache.set(KEY_COOKIE_AUTH, auth);
  },
  async clearCache() {
    await Cache.removeAll();
  },
  register(params) {
    return new Promise((resolve, reject) => {
      Auth.apiRegister(params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  login(params) {
    return new Promise((resolve, reject) => {
      Auth.apiLogin(params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  logout() {
    store.commit("Loading", true);
    const myMSAL = new Msal.PublicClientApplication(config.msalConfig);
    const logoutRequest = {
      postLogoutRedirectUri: config.msalConfig.auth.postLogoutRedirectUri,
    };
    this.clearCache();
    localStorage.clear();
    store.replaceState(state);
    myMSAL.logoutRedirect(logoutRequest);
  },
  async loadFacebookSDK(d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    js = d.createElement(s);
    js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  },
  fbAsyncInit() {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: this.config().fbAppId, //You will need to change this 327544911989466
        cookie: true, // This is important, it's not enabled by default
        xfbml: true,
        version: "v13.0",
      });
    };
  },
  callGetMenu(appid) {
    const params = {
      buzzebees_application_id: appid || Cache.get("app"),
    };
    return new Promise((resolve, reject) => {
      userAPI
        .getMenu(params)
        .then((res) => {
          const product = _.find(res.data.result.products, (product) => {
            if (product.name == Cache.get("product")) return product;
          });
          const otherproducts = _.filter(
            res.data.result.products,
            (product) => {
              if (product.is_visible && product.name != "Help-Center")
                return _.map(res.data.result.products, (product) => {
                  return {
                    display_name: product.display_name,
                    icon: product.icon,
                    name: product.name,
                    url: product.url,
                  };
                });
            }
          );
          const helpCenter = _.find(res.data.result.products, (product) => {
            if (product.name == "Help-Center" && product.menus?.length > 0) {
              return product;
            }
          });
          if (product && product.menus) {
            store.commit("setMenu", product.menus);
          }
          store.commit("setMenuProduct", otherproducts);
          store.commit("setHelpCenter", helpCenter);
          resolve(res.data.result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  callGetUserPermission(accountid) {
    const params = {
      account_id: accountid,
    };
    return new Promise((resolve, reject) => {
      userAPI
        .getUsePermission(params)
        .then((res) => {
          store.commit("setMenuEx", res?.data?.result?.features);
          resolve(res.data.result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getApplication(parmas) {
    return new Promise((resolve, reject) => {
      userAPI
        .getApplications(parmas)
        .then((response) => {
          Cache.set("product", response.data.result.product.name);
          resolve(response.data.result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  setDefaultMenu() {
    var menu = store.state.menu;
    if (menu?.length > 0) {
      if (menu[0].page && typeof menu[0].page.route_name == "string") {
        router.push({ name: menu[0].page.route_name });
      } else {
        if (menu[0].menus) {
          if (
            menu[0].menus[0].page &&
            typeof menu[0].menus[0].page.route_name == "string"
          ) {
            router.push({ name: menu[0].menus[0].page.route_name });
          } else {
            if (
              menu[0].menus[0].menus &&
              menu[0].menus[0].menus[0].page &&
              typeof menu[0].menus[0].menus[0].page.route_name == "string"
            ) {
              router.push({ name: menu[0].menus[0].menus[0].page.route_name });
            }
          }
        }
      }
    } else {
      window.location = window.location.origin + "/unauthorized";
    }
  },
  setDefaultMenuEx() {
    let menuEx = store.state.menuEx;
    let menu = [];
    menuEx.forEach((element) => {
      if (element.name == "Application") {
        let item = {
          seq: 1,
          name: "application",
          display_name: "Application",
          icon_type: "ant",
          icon_name: "appstore",
          page: {
            display_name: "Application",
            name: "application",
            route_name: "ManageApplication",
            url_path: "/manageapplication",
          },
        };
        menu.push(item);
      }
      if (element.name == "Role-And-User") {
        let item = {
          seq: 2,
          name: "role",
          display_name: "Role Management",
          icon_type: "custom",
          icon_name: "rolemanagement",
          page: {
            display_name: "Role Management",
            name: "role",
            route_name: "ManageRoles",
            url_path: "/manageroles",
          },
        };
        menu.push(item);
      }
      if (element.name == "Account") {
        let item = {
          seq: 3,
          name: "user",
          display_name: "User Management",
          icon_type: "ant",
          icon_name: "user",
          page: {
            display_name: "User Management",
            name: "user",
            route_name: "ManageUsers",
            url_path: "ManageUsers",
          },
        };
        menu.push(item);
      }
    });
    menu.sort((a, b) => a.seq - b.seq);
    store.commit("setMenuEx", menu);
    let is_redirect = window.location.pathname == "/redirectms";
    if (is_redirect) {
      router.push({ name: menu[0].page.route_name });
    }
  },
};
