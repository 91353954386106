<template>
  <div :style="{ minHeight: '360px' }">
    <AppLayout>
      <div class="title-page-new">
        <div>
          <a-breadcrumb class="custom-breadcrumb" :routes="routes">
            <template slot="itemRender" slot-scope="{ route }">
              <span v-if="routes.indexOf(route) === routes.length - 1">
                {{ route.breadcrumbName }}
              </span>
              <router-link v-else :to="`${route.path}`">
                {{ route.breadcrumbName }}
              </router-link>
            </template>
          </a-breadcrumb>
        </div>
      </div>
      <div class="manage-create-application" :style="{ margin: '24px' }">
        <div class="page-wrapper">
          <a-page-header
            :title="lbl.editapplication.editapplication"
            sub-title=""
            style="margin-bottom: 15px"
          >
            <template slot="extra">
              <span class="float-right"
                ><a-switch
                  :checked="itemForEdit.is_active"
                  @change="onChangeSwitch"
              /></span>
            </template>
          </a-page-header>
          <div>
            <a-form :form="form" @submit="handleSubmit">
              <a-row :gutter="[16]">
                <a-col
                  :xs="24"
                  :sm="24"
                  :md="24"
                  style="align-items: center; display: flex"
                >
                  <a-form-item>
                    <a-upload
                      disabled
                      name="avatar"
                      list-type="picture-card"
                      class="avatar-uploader"
                      :show-upload-list="false"
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      :before-upload="beforeUpload"
                      @change="handleChange"
                    >
                      <img
                        v-if="imageUrl"
                        style="max-width: 128px"
                        src="@/assets/images/TestM150Logo.jpg"
                        alt="avatar"
                      />
                      <div v-else>
                        <a-icon :type="loading ? 'loading' : 'plus'" />
                        <div class="ant-upload-text">Upload</div>
                      </div>
                    </a-upload>
                  </a-form-item>
                  <span style="margin-top: -50px; margin-left: 15px"
                    >Application image display</span
                  >
                </a-col>
                <a-col :xs="24" :sm="24" :md="12">
                  <a-form-item :label="lbl.editapplication.title_name">
                    <a-input
                      v-decorator="[
                        'name',
                        {
                          initialValue: itemForEdit.name,
                          rules: [
                            {
                              required: true,
                              message: lbl.editapplication.input_name.required,
                            },
                            {
                              pattern: new RegExp(/^[A-Za-zก-๙0-9_-]{1,100}$/),
                              message: lbl.editapplication.input_name.pattern,
                            },
                            {
                              min: 4,
                              message: lbl.editapplication.input_name.min,
                            },
                          ],
                        },
                      ]"
                      disabled
                      :placeholder="lbl.editapplication.input_name.placeholder"
                      :max-length="maxLengthName"
                      @keyup="countChar"
                    />
                  </a-form-item>
                  <p class="total-char" href="">
                    {{ totalCharName.toLocaleString() }} /
                    {{ maxLengthName.toLocaleString() }}
                  </p>
                </a-col>
                <a-col
                  :xs="24"
                  :sm="24"
                  :md="12"
                  style="display: flex; align-items: center"
                >
                  <div style="width: 75%">
                    <a-form-item :label="lbl.editapplication.input_appid.name">
                      <a-input
                        v-decorator="[
                          'applicationprefix',
                          {
                            initialValue: itemForEdit.buzzebees_application_Id,
                            rules: [
                              {
                                required: true,
                                message:
                                  lbl.editapplication.input_appid.required,
                              },
                              {
                                pattern: new RegExp(/^[A-Za-z0-9_-]{1,100}$/),
                                message:
                                  lbl.editapplication.input_appid.pattern,
                              },
                              {
                                min: 4,
                                message: lbl.editapplication.input_appid.min,
                              },
                            ],
                          },
                        ]"
                        :placeholder="
                          lbl.editapplication.input_appid.placeholder
                        "
                        :max-length="100"
                        @keyup="countChar"
                        @change="handleCheckFormat"
                      />
                    </a-form-item>
                    <p class="total-char">
                      {{ totalCharAppID.toLocaleString() }} /
                      {{ maxLengthAppID.toLocaleString() }}
                    </p>
                  </div>
                  <div class="auto-gen" @click="autogen">
                    {{ lbl.btn.autogen }}
                  </div>
                </a-col>
              </a-row>
              <a-row :gutter="[16]">
                <a-col :xs="24" :sm="24" :md="24">
                  <a-form-item :label="lbl.editapplication.title_description">
                    <a-textarea
                      v-decorator="[
                        'description',
                        {
                          initialValue: itemForEdit.description,
                          rules: [
                            {
                              required: true,
                              message:
                                lbl.editapplication.input_description.required,
                            },
                            {
                              min: 4,
                              message:
                                lbl.editapplication.input_description.min,
                            },
                          ],
                        },
                      ]"
                      :auto-size="{ minRows: 6, maxRows: 6 }"
                      :placeholder="
                        lbl.editapplication.input_description.placeholder
                      "
                      :max-length="maxLengthDescription"
                      @keyup="countChar"
                    />
                  </a-form-item>
                  <p class="total-char" href="">
                    {{ totalCharDescription.toLocaleString() }} /
                    {{ maxLengthDescription.toLocaleString() }}
                  </p>
                </a-col>
              </a-row>
              <a-row :gutter="[16]">
                <a-col :xs="24" :sm="24" :md="24">
                  <div style="text-align: right">
                    <a-form-item>
                      <a-button
                        class="btn-custom"
                        size="large"
                        style="margin-right: 15px"
                        @click="goToPage('ManageApplications')"
                      >
                        {{ lbl.btn.cancel }}
                      </a-button>
                      <a-button
                        type="primary"
                        size="large"
                        style="width: 150px"
                        :disabled="disablebtnok"
                        @click="handleSave"
                      >
                        {{ lbl.btn.save }}
                      </a-button>
                    </a-form-item>
                  </div>
                </a-col>
              </a-row>
            </a-form>
          </div>
        </div>
      </div>
      <!-- Modal -->
      <a-modal
        v-model="isShowModal"
        class="process-modal"
        centered
        :width="650"
        :closable="false"
      >
        <template slot="footer">
          <a-button
            v-show="idModal != 'confirm'"
            type="primary"
            @click="handlemodalCancelError"
          >
            {{ lbl.btn.close }}
          </a-button>
          <a-button
            v-show="idModal == 'confirm'"
            class="btn-custom"
            @click="handlemodalCancel"
          >
            {{ lbl.btn.cancel }}
          </a-button>
          <a-button
            v-show="idModal == 'confirm'"
            :type="isDelete ? 'danger' : 'primary'"
            style="min-width: 105px"
            @click="handlemodalOk"
          >
            {{ modalbtnok }}
          </a-button>
        </template>
        <div>
          <div class="title-modal">
            <span style="color: #faad14; margin-right: 16px">
              <a-icon
                v-show="idModal == 'confirm'"
                style="color: #faad14"
                type="question-circle"
              />
              <a-icon
                v-show="idModal == 'error'"
                style="color: #faad14"
                type="exclamation-circle"
              />
              <a-icon
                v-show="idModal == 'success'"
                style="color: #52c41a"
                type="check-circle"
              />
            </span>
            <span>{{ titleModal }}</span>
          </div>
          <div class="content-modal">
            <p>{{ contentModal }}</p>
            <div v-if="errtransaction_id" style="font-size: 12px">
              Transaction ID : {{ errtransaction_id }}
            </div>
          </div>
        </div>
      </a-modal>
    </AppLayout>
  </div>
</template>
<script>
import _ from "lodash";
import AppLayout from "@/components/Layout/LayoutMaster.vue";
import manageApplication from "@/core/Page/ManageApplicationAPI";
import Cache from "@/helper/Cache";

export default {
  name: "ManageDatalevel",
  components: {
    AppLayout,
  },
  data() {
    return {
      routes: [
        {
          path: "/manageapplications",
          breadcrumbName: "Applications",
          keys: "application",
        },
        {
          path: "/editapplication",
          breadcrumbName: "Edit Application",
          keys: "editapplication",
        },
      ],
      data: [],
      form: this.$form.createForm(this),
      maxLengthName: 100,
      totalCharName: 0,
      maxLengthDescription: 250,
      totalCharDescription: 0,
      maxLengthAppID: 100,
      totalCharAppID: 0,
      isShowModal: false,
      idModal: "",
      modalbtnok: "",
      contentModal: "",
      titleModal: "",
      isDelete: false,
      errtransaction_id: "",
      disablebtnok: true,
      itemForEdit: [],
      loading: false,
      imageUrl: "",
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      let record = localStorage.getItem("record");
      record = JSON.parse(record);
      if (record === undefined || record === null || record === "") {
        window.location.href = "/manageapplications";
      }
      localStorage.removeItem("record");
      this.exAccount = Cache.get("exAccount");
      _.forEach(this.routes, (column) => {
        column.breadcrumbName = this.lbl.menu[column.keys];
      });
      this.callGetDetail(record);
    },
    countChar(e) {
      this.disablebtnok = true;
      if (e.target.id == "name") {
        this.totalCharName = e.target.value.length;
      }
      if (e.target.id == "description") {
        this.totalCharDescription = e.target.value.length;
        this.itemForEdit.description = e.target.value;
      }
      if (e.target.id == "applicationprefix") {
        this.totalCharAppID = e.target.value.length;
        this.itemForEdit.buzzebees_application_Id = e.target.value;
      }
      this.checkBTN();
    },
    checkBTN() {
      this.disablebtnok = true;
      if (
        (this.totalCharDescription >= 4 &&
          this.itemForEdit.description != this.checkDescription) ||
        (this.totalCharAppID >= 4 &&
          this.itemForEdit.buzzebees_application_Id != this.checkAppID) ||
        this.itemForEdit.is_active != this.checkActive
      ) {
        this.disablebtnok = false;
      }
    },
    handleSave(e) {
      this.objDataSave = [];
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.objDataSave.name = values.name;
          this.objDataSave.description = values.description;
          this.idModal = "confirm";
          this.isShowModal = true;
          this.modalbtnok = this.lbl.btn.yes;
          this.titleModal = this.lbl.editapplication.modal.title_confirm;
          this.contentModal =
            this.lbl.editapplication.modal.content_update + values.name + " ?";
        }
      });
    },
    postUpdateData() {
      this.handleLoading(true);
      var valueSave = {
        account_id: this.exAccount.account.id,
        application_id: this.itemForEdit.id,
        application_description: this.objDataSave.description,
        application_is_active: this.itemForEdit.is_active,
        buzzebees_application_Id: this.itemForEdit.buzzebees_application_Id,
      };
      this.objForSave = JSON.stringify(valueSave);
      manageApplication
        .postEditApplication(this.objForSave)
        .then((res) => {
          if (res.status == "200") {
            if (res.data.result) {
              this.idModal = "success";
              setTimeout(() => {
                this.titleModal = this.lbl.editapplication.modal.title_success;
                this.contentModal =
                  this.lbl.editapplication.modal.content_update_success;
                this.isShowModal = true;
                this.handleLoading(false);
              }, 500);
            }
          }
        })
        .catch((error) => {
          this.idModal = "error";
          let errorcontent = "Is Someting Error";
          this.err_id = "";
          if (error.response) {
            if (error.response.statusText) {
              errorcontent = error.response.statusText;
              this.errtransaction_id = error.response.data.transaction_id
                ? error.response.data.transaction_id
                : "";
              if (error.response.data.error) {
                errorcontent =
                  error.response.data && error.response.data.error.length > 0
                    ? error.response.data.error[0].message
                    : error.response.statusText;
                this.err_id = error.response.data.error[0].id;
              }
            }
          }
          setTimeout(() => {
            this.isShowModal = true;
            this.titleModal = this.lbl.editapplication.modal.title_error;
            this.contentModal = errorcontent;
            this.handleLoading(false);
          }, 500);
        });
    },
    handlemodalOk() {
      this.postUpdateData();
      this.isShowModal = false;
      this.errtransaction_id = "";
    },
    handlemodalCancel() {
      this.errtransaction_id = "";
      this.isShowModal = false;
      this.isDelete = false;
    },
    handlemodalCancelError() {
      this.isShowModal = false;
      this.errtransaction_id = "";
      if (this.idModal == "success") {
        this.handleLoading(true);
        setTimeout(() => {
          window.location.href = "/manageapplications";
        }, 500);
      }
      if (this.contentModal == "Unauthorized") {
        this.$router.push({ name: "Login" });
      }
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);
        }
      });
    },
    goToPage(page) {
      this.$router.push({
        name: page,
      });
    },
    autogen() {
      this.form.resetFields();
      let autogen = Math.random().toString(36).substr(2);
      this.itemForEdit.buzzebees_application_Id = autogen.toUpperCase();
      this.totalCharAppID = autogen.length;
      this.form.setFieldsValue({
        applicationprefix: autogen.toUpperCase(),
      });
      this.checkBTN();
    },
    onChangeSwitch(checked) {
      this.itemForEdit.is_active = checked;
      this.checkBTN();
    },
    callGetDetail(record) {
      let params = {
        account_id: this.exAccount.account.id,
        application_id: record.id,
      };
      manageApplication
        .getApplicationDetail(params)
        .then((res) => {
          if (res.data) {
            if (res.data.result) {
              let result = res.data.result;
              this.itemForEdit = result;
              this.checkActive = result.is_active;
              this.checkAppID = result.buzzebees_application_Id;
              this.totalCharName = result.name.length;
              this.totalCharAppID = result.name.length;
              this.totalCharDescription = result.description.length;
              this.checkDescription = result.description;
              this.newdescription = result.description;
            }
          }
        })
        .catch((error) => {
          this.idModal = "error";
          let errorcontent = "Is Something Error";
          this.err_id = "";
          if (error.response) {
            if (error.response.statusText) {
              errorcontent = error.response.statusText;
              this.errtransaction_id = error.response.data.transaction_id
                ? error.response.data.transaction_id
                : "";
              if (error.response.data.error) {
                errorcontent =
                  error.response.data && error.response.data.error.length > 0
                    ? error.response.data.error[0].message
                    : error.response.statusText;
                this.err_id = error.response.data.error[0].id;
              }
            }
          }
          setTimeout(() => {
            this.isShowModal = true;
            this.titleModal = this.lbl.role.modal.title_error;
            this.contentModal = errorcontent;
            this.handleLoading(false);
          }, 500);
        });
    },
    handleCheckFormat(e) {
      var text = e.currentTarget.value;
      if (e.currentTarget.id == "applicationprefix") {
        text = text.replace(/[^a-zA-Z0-9 ]/g, "");
        e.target.value = text.toUpperCase();
      }
      if (
        e.currentTarget.id == "cardnumber" ||
        e.currentTarget.id == "postalcode"
      ) {
        text = text.replace(/[^0-9]/g, "");
        e.target.value = text;
      }
      if (e.currentTarget.id == "cvv") {
        text = text.replace(/[^0-9]/g, "");
        e.target.value = text;
      }
    },
    handleChange(info) {
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
    },
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("You can only upload JPG file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("Image must smaller than 2MB!");
      }
      return isJpgOrPng && isLt2M;
    },
  },
};
</script>
<style lang="scss">
@import "@/style/_mixin.scss";
.editable-row-operations a {
  margin-right: 8px;
}
.manage-create-application {
  background: rgb(255, 255, 255);
  padding: 20px;
  height: calc(100vh - 190px);
  overflow: auto;
  .ant-form-item-control {
    line-height: 0 !important;
  }
  .ant-form-item-children {
    input {
      height: 40px;
      margin-bottom: 4px;
    }
  }
  .ant-select-selection--single {
    height: 40px !important;
  }
  .ant-select-search__field__placeholder,
  .ant-select-selection__placeholder {
    margin-top: -5px !important;
  }
  .auto-gen {
    padding: 15px;
    color: #1890ff;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 15px;
  }
  .avatar-uploader > .ant-upload {
    width: 128px;
    height: 128px;
  }
  .ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
  }
  .ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
  }
  .total-char {
    margin-top: -20px !important;
  }
}
.ant-page-header-back i svg {
  vertical-align: 0;
}
</style>
