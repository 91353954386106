<template>
  <div :style="{ minHeight: '360px' }">
    <AppLayout>
      <div class="title-page-new flex-center-between">
        <span>
          <PageBreadcrumb :datapagebreadcrumb="dataPageBreadcrumb" />
        </span>
        <div>
          <span class="set-test-beta">
            <router-link
              :to="{
                name: 'ManageAssignRole',
                query: {
                  id: $route.query.id,
                },
              }"
            >
              Assign Roles Current Version
            </router-link>
          </span>
        </div>
      </div>
      <div class="manage-role-setrole manage-page-detail">
        <div id="mypage">
          <div v-if="objSelect.name" class="page-wrapper">
            <a-page-header>
              <template slot="title">
                <div class="d-flex">
                  <!-- <div v-if="objSelect.is_active">
                    <img class="icon-width-18-custom" :src="IConActive" />
                  </div>
                  <div v-else>
                    <img class="icon-width-18-custom" :src="IConInctive" />
                  </div> -->
                  <div class="ml-2">{{ objSelect.name }}</div>
                  <div class="ml-2 opacity6">({{ data.length }})</div>
                </div>
              </template>
              <template slot="extra">
                <div class="flex-center">
                  <div>
                    <!-- <a-tooltip>
                      <template slot="title">
                        {{ lbls.role_page.btn.assign_to_role }}
                      </template>
                      <router-link
                        :to="{
                          name: 'ManageAssigntoRole',
                          query: {
                            id: $route.query.id,
                          },
                        }"
                      >
                        <a-button class="btn-custom-edit">
                          <img class="icon-width-18" :src="IConAddUser" />
                        </a-button>
                      </router-link>
                    </a-tooltip> -->
                    <a-tooltip placement="topLeft">
                      <template slot="title">
                        {{ lbls.role_page.btn.assign_to_role }}
                      </template>
                      <router-link
                        :to="{
                          name: 'ManageAssigntoRole',
                          query: {
                            id: $route.query.id,
                          },
                        }"
                        target="_blank"
                      >
                        <a-icon type="usergroup-add" />
                      </router-link>
                    </a-tooltip>
                  </div>
                  <a-divider style="top: 1px" type="vertical" />
                  <div>
                    <a-tooltip>
                      <template slot="title">
                        {{ lbls.role_page.btn.edit }}
                      </template>
                      <!-- <a-button class="btn-custom-edit" @click="handleEdit()">
                        <img class="icon-width-18" :src="IConEdit" />
                      </a-button> -->
                      <a @click="handleEdit()">
                        <a-icon type="edit" />
                      </a>
                    </a-tooltip>
                  </div>
                  <a-divider type="vertical" />
                  <div>
                    <!-- <a-tooltip>
                      <template slot="title">
                        {{ lbls.role_page.btn.delete }}
                      </template>
                      <a-button class="btn-custom-edit" @click="handleDelete()">
                        <img class="icon-width-18" :src="IConDelete" />
                      </a-button>
                    </a-tooltip> -->
                    <a-tooltip placement="topLeft">
                      <template slot="title">
                        {{ lbls.role_page.btn.delete }}
                      </template>
                      <a @click="handleDelete()">
                        <a-icon type="delete" />
                      </a>
                    </a-tooltip>
                  </div>
                </div>
              </template>
            </a-page-header>
          </div>
          <div class="description custom-description">
            {{ objSelect.description }}
          </div>
        </div>
        <a-skeleton v-show="isShowSkeleton" class="mt-3 body-detail" />
        <div v-show="!isShowSkeleton" id="body_detail" class="body-detail">
          <div v-for="item in data" :key="item.subscription.id">
            <div
              class="box-data"
              :class="{ 'conbox-data-active': !item.collapsed }"
            >
              <div
                class="subname"
                :class="{
                  'subname-active': !item.collapsed,
                  opacity6:
                    !item.applications &&
                    !item.cross_applications &&
                    !item.products,
                }"
                @click="toggle(item)"
              >
                <div>
                  <span
                    :hidden="
                      !item.applications &&
                      !item.cross_applications &&
                      !item.products
                    "
                    class="mr-2"
                  >
                    <!-- <img
                      class="arrow-show-data"
                      :class="{ 'arrow-hide-data': !item.collapsed }"
                      :src="iconArrowDown"
                    /> -->
                    <a-icon v-if="!item.collapsed" type="caret-up" />
                    <a-icon v-else type="caret-down" />
                  </span>
                  <span>{{ item.package.name }}</span>
                </div>
              </div>
              <div v-if="!item.collapsed" class="content">
                <div v-if="item.products" class="product-item">
                  <div class="box-product" @click="toggle(item.products)">
                    <div class="product-title">
                      <span class="mr-2">
                        <!-- <img
                          class="arrow-show-data custom-size-10"
                          :class="{
                            'arrow-hide-data': !item.products.collapsed,
                          }"
                          :src="iconArrowDown"
                        /> -->
                        <a-icon
                          v-if="!item.products.collapsed"
                          type="caret-up"
                        />
                        <a-icon v-else type="caret-down" />
                      </span>
                      <span>{{ lbls.role_page.title_product }}</span>
                      <span> ({{ item.products.length }})</span>
                    </div>
                  </div>
                  <div v-if="!item.products.collapsed" class="product-content">
                    <div
                      v-for="product in item.products"
                      :key="product.id"
                      class="product"
                    >
                      <div class="product-name">
                        <div
                          class="product-label width-origianal-label"
                          :class="{
                            'width-origianal-label-edit':
                              product.isEditShowCheckbox,
                          }"
                          @click="() => (product.isshow = !product.isshow)"
                        >
                          <span class="mr-2">
                            <!-- <img
                              class="arrow-show-data custom-size-10"
                              :class="{
                                'arrow-hide-data': product.isshow,
                              }"
                              :src="iconArrowDown"
                            /> -->
                            <a-icon v-if="product.isshow" type="caret-up" />
                            <a-icon v-else type="caret-down" />
                          </span>
                          <span>{{ product.name }}</span>
                        </div>
                        <div
                          class="product-btn width-origianal-btn mr-2"
                          :class="{
                            'width-origianal-btn-edit':
                              product.isEditShowCheckbox,
                          }"
                        >
                          <span v-if="product.isEditShowCheckbox" class="mr-2">
                            <a-button @click="handleBackrecord()">
                              {{ lbls.role_page.btn.cancel }}
                            </a-button>
                          </span>
                          <span v-if="product.isEditShowCheckbox">
                            <a-button
                              style="width: 70px"
                              type="primary"
                              @click="
                                handleSaverecord(
                                  item.subscription,
                                  product,
                                  'product'
                                )
                              "
                            >
                              {{ lbls.role_page.btn.save }}
                            </a-button>
                          </span>
                          <span v-if="!product.isEditShowCheckbox">
                            <span v-if="product.isshow">
                              <a-tooltip>
                                <template slot="title">
                                  {{ lbls.role_page.btn.manage }}
                                </template>
                                <!-- <a-button
                                  class="btn-custom-edit"
                                  @click="
                                    handleEditrecord(
                                      item.subscription,
                                      product,
                                      'product'
                                    )
                                  "
                                >
                                  <img
                                    class="icon-width-18"
                                    :src="IConManage"
                                  />
                                </a-button> -->
                                <a
                                  @click="
                                    handleEditrecord(
                                      item.subscription,
                                      product,
                                      'product'
                                    )
                                  "
                                >
                                  <a-icon type="setting" />
                                </a>
                              </a-tooltip>
                            </span>
                          </span>
                        </div>
                      </div>
                      <div v-if="product.isshow" class="product-desc">
                        <div
                          v-if="product.data_levels"
                          class="title-label mb-2"
                        >
                          {{ lbls.role_page.title_data_level }}
                        </div>
                        <div
                          v-show="
                            product.data_levels && !product.isShowListDatavl
                          "
                          class="mb-2"
                        >
                          <a-radio-group
                            v-model="product.isdefault"
                            disabled
                            :options="product.data_levels"
                            @change="onChangeRadio"
                          />
                        </div>
                        <div v-if="product.isShowListDatavl" class="mb-2">
                          <a-radio-group
                            v-model="valueDataLv"
                            :options="product.dataLv"
                            @change="onChangeRadio"
                          />
                        </div>
                        <div class="title-label mb-2">
                          <span>{{ lbls.role_page.title_features }}</span>
                          <span class="ml-2"
                            >({{ product.features.length }})</span
                          >
                        </div>
                        <div v-if="product.isEditShowCheckbox">
                          <div
                            v-if="product.features.length > 1"
                            class="checkall-role"
                          >
                            <a-checkbox
                              :indeterminate="
                                product.valuecheckbox.length > 0 &&
                                product.valuecheckbox.length <
                                  product.features.length
                              "
                              :checked="
                                product.features.length ==
                                product.valuecheckbox.length
                              "
                              @change="onCheckAllFeature(product)"
                            >
                              <span>Check all</span>
                              <span class="ml-2 opacity6">
                                <span v-if="product.valuecheckbox">
                                  ({{ product.valuecheckbox.length }}/</span
                                >
                                <span>{{ product.features.length }})</span>
                              </span>
                            </a-checkbox>
                          </div>
                          <div>
                            <a-checkbox-group
                              v-model="product.valuecheckbox"
                              class="list-feature"
                              name="checkboxgroup"
                              :options="product.listOfFeature"
                              @change="onChangesCheckbox"
                            />
                          </div>
                        </div>
                        <div v-else class="feature-list">
                          <div
                            v-for="feature in product.features"
                            :key="feature.id"
                            class="features-item"
                          >
                            <span>
                              <span>
                                <a-icon
                                  v-if="feature.is_selected"
                                  style="color: #1890ff"
                                  type="check-circle"
                                />
                              </span>
                              <span>
                                <a-icon
                                  v-if="!feature.is_selected"
                                  style="color: #ff5252"
                                  type="close-circle"
                                />
                              </span>
                            </span>
                            <span>{{ feature.name }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="item.applications" class="application-itme">
                  <div class="application-title">
                    <div>
                      <span>{{ lbls.role_page.title_application }}</span>
                      <span> ({{ item.applications.length }})</span>
                    </div>
                    <div>
                      <span v-if="item.isEditShowCheckboxApp" class="mr-2">
                        <a-button @click="handleBackrecord()">
                          {{ lbls.role_page.btn.cancel }}
                        </a-button>
                      </span>
                      <span v-if="item.isEditShowCheckboxApp">
                        <a-button
                          style="width: 70px"
                          type="primary"
                          @click="
                            handleSaverecord(
                              item.subscription,
                              item,
                              'applications'
                            )
                          "
                        >
                          {{ lbls.role_page.btn.save }}
                        </a-button>
                      </span>
                      <span v-if="!item.isEditShowCheckboxApp">
                        <a-tooltip>
                          <template slot="title">
                            {{ lbls.role_page.btn.manage }}
                          </template>
                          <!-- <a-button
                            class="btn-custom-edit"
                            @click="handleEditrecord('', item, 'applications')"
                          >
                            <img class="icon-width-18" :src="IConManage" />
                          </a-button> -->
                          <a
                            @click="handleEditrecord('', item, 'applications')"
                          >
                            <a-icon type="setting" />
                          </a>
                        </a-tooltip>
                      </span>
                    </div>
                  </div>
                  <div class="application-desc">
                    <div v-if="item.isEditShowCheckboxApp">
                      <div
                        v-if="item.applications.length > 1"
                        class="checkall-role"
                      >
                        <a-checkbox
                          :indeterminate="
                            item.valuecheckboxApp.length > 0 &&
                            item.valuecheckboxApp.length <
                              item.applications.length
                          "
                          :checked="
                            item.applications.length ==
                            item.valuecheckboxApp.length
                          "
                          @change="onCheckAllApplication(item)"
                        >
                          <span>Check all</span>
                          <span class="ml-2 opacity6">
                            <span v-if="item.valuecheckboxApp">
                              ({{ item.valuecheckboxApp.length }}/</span
                            >
                            <span>{{ item.applications.length }})</span>
                          </span>
                        </a-checkbox>
                      </div>
                      <div>
                        <a-checkbox-group
                          v-model="item.valuecheckboxApp"
                          class="list-feature"
                          name="checkboxgroup"
                          :options="item.listOfFeatureApp"
                          @change="onChangesCheckbox"
                        />
                      </div>
                    </div>
                    <div v-else class="feature-list">
                      <div
                        v-for="application in item.applications"
                        :key="application.id"
                        class="features-item"
                      >
                        <span
                          ><a-icon
                            v-if="application.is_selected"
                            style="color: #1890ff"
                            type="check-circle"
                        /></span>
                        <span
                          ><a-icon
                            v-if="!application.is_selected"
                            style="color: #ff5252"
                            type="close-circle"
                        /></span>
                        <span>{{ application.name }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="item.cross_applications" class="application-itme">
                  <div class="application-title">
                    <div>
                      <span>{{ lbls.role_page.title_cross }}</span>
                      <span> ({{ item.cross_applications.length }})</span>
                    </div>
                    <div>
                      <span v-if="item.isEditShowCheckboxCross" class="mr-2">
                        <a-button @click="handleBackrecord()">
                          {{ lbls.role_page.btn.cancel }}
                        </a-button>
                      </span>
                      <span v-if="item.isEditShowCheckboxCross">
                        <a-button
                          style="width: 70px"
                          type="primary"
                          @click="
                            handleSaverecord(item.subscription, item, 'cross')
                          "
                        >
                          {{ lbls.role_page.btn.save }}
                        </a-button>
                      </span>
                      <span v-if="!item.isEditShowCheckboxCross">
                        <a-tooltip>
                          <template slot="title">
                            {{ lbls.role_page.btn.manage }}
                          </template>
                          <!-- <a-button
                            class="btn-custom-edit"
                            @click="handleEditrecord('', item, 'cross')"
                          >
                            <img class="icon-width-18" :src="IConManage" />
                          </a-button> -->
                          <a @click="handleEditrecord('', item, 'cross')">
                            <a-icon type="setting" />
                          </a>
                        </a-tooltip>
                      </span>
                    </div>
                  </div>
                  <div class="application-desc">
                    <div v-if="item.isEditShowCheckboxCross">
                      <div
                        v-if="item.cross_applications.length > 1"
                        class="checkall-role"
                      >
                        <a-checkbox
                          :indeterminate="
                            item.valuecheckboxCross.length > 0 &&
                            item.valuecheckboxCross.length <
                              item.cross_applications.length
                          "
                          :checked="
                            item.cross_applications.length ==
                            item.valuecheckboxCross.length
                          "
                          @change="onCheckAllCross(item)"
                        >
                          <span>Check all</span>
                          <span class="ml-2 opacity6">
                            <span v-if="item.valuecheckboxCross">
                              ({{ item.valuecheckboxCross.length }}/</span
                            >
                            <span>{{ item.cross_applications.length }})</span>
                          </span>
                        </a-checkbox>
                      </div>
                      <div>
                        <a-checkbox-group
                          v-model="item.valuecheckboxCross"
                          name="checkboxgroup"
                          class="list-feature"
                          :options="item.listOfFeatureCross"
                          @change="onChangesCheckbox"
                        />
                      </div>
                    </div>
                    <div v-else class="feature-list">
                      <div
                        v-for="cross in item.cross_applications"
                        :key="cross.id"
                        class="features-item"
                      >
                        <span
                          ><a-icon
                            v-if="cross.is_selected"
                            style="color: #1890ff"
                            type="check-circle"
                        /></span>
                        <span
                          ><a-icon
                            v-if="!cross.is_selected"
                            style="color: #ff5252"
                            type="close-circle"
                        /></span>
                        <span>{{ cross.name }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal -->
      <div v-if="isModalProcess">
        <ModalProcess
          :modalprocess="modalprocess"
          :dataobjselect="dataObjSelect"
          @actionModal="actionModal($event)"
        >
        </ModalProcess>
      </div>
      <div v-if="isModalResponse">
        <ModalAlert
          :modalalert="modalAlert"
          :alertdata="alertData"
          @handlemodalok="handlemodalOk"
        >
        </ModalAlert>
      </div>
    </AppLayout>
  </div>
</template>
<script>
import AppLayout from "@/components/Layout/LayoutMaster.vue";
import manageRoleAPI from "@/core/Page/ManageRoleAPI";
import Cache from "@/helper/Cache.js";
import PageBreadcrumb from "@/module/Role/ManageSetRole/components/Layout/PageBreadcrumb.vue";
import ModalProcess from "@/module/Role/ManageSetRole/components/Modal/ModalProcess.vue";
import ModalAlert from "@/components/Modal/Alertmodal.vue";
export default {
  name: "ManageRole",
  components: {
    AppLayout,
    PageBreadcrumb,
    ModalProcess,
    ModalAlert,
  },
  data() {
    return {
      isShowSkeleton: true,
      data: [],
      btndeletedisabled: false,
      isEdit: false,
      objSelect: [],
      dataObjSelect: [],
      valueDataLv: [],
      isModalProcess: false,
      isModalResponse: false,
      titleModalProcess: "",
      showBtnDelete: false,
      totalList: 0,
      idModal: "",
      titleModal: "",
      isDelete: false,
      errtransaction_id: "",
      modalbtnok: "",
      contentModal: "",
      searchInput: null,
      valuecheckbox: [],
      listOfDatalv: [],
      listOfFeature: [],
      record: "",
      valueDataLvRadio: "",
      resetbgc: {
        backgroundColor: "",
      },
      disablebtnok: true,
      indeterminate: true,
      checkAll: false,
      dataPageBreadcrumb: {},
      myoffsetHeight: 0,
      alertData: {},
      modalAlert: {
        visible: false,
        id: "",
        title: "",
        message: "",
        okText: "",
        cancelText: "",
      },
      iconArrowDown: require("@/assets/images/Icon/Icon_arrow-down.svg"),
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.$route.query.id) {
        this.exAccount = Cache.get("exAccount");
        Promise.all([this.callGetDetail()]).then((resgetdetail) => {
          let isCallListSuccess = resgetdetail[0];
          if (isCallListSuccess) {
            this.nameSearch = "";
            this.callGetList();
          } else {
            this.handleLoading(false);
          }
        });
      } else {
        this.$router.push({ name: "ManageRoles" });
      }
    },
    showModalProcess() {
      this.isModalProcess = true;
      this.handleLoading(false);
      this.modalprocess = {
        visible: true,
        title: this.titleModalProcess,
        key: "user_page.",
        isCreate: this.isCreate,
        isEdit: this.isEdit,
      };
    },
    closeModalProcess() {
      this.isModalProcess = false;
    },
    resetCondition() {
      this.isEdit = false;
      this.isDelete = false;
    },
    handleEdit() {
      this.resetCondition();
      this.handleLoading(true);
      this.isEdit = true;
      this.titleModalProcess = this.lbls.role_page.drw_title_edit;
      this.callGetDetailEdit();
    },
    handleDelete() {
      this.resetCondition();
      this.idModal = "confirm";
      this.isDelete = true;
      this.modalbtnok = this.lbls.role_page.modal.btn.delete;
      this.titleModal = this.lbls.role_page.modal.title_confirm;
      this.contentModal =
        this.lbls.role_page.modal.content_delete + this.objSelect.name + " ?";
      this.modalAlert = {
        id: this.idModal,
        visible: true,
        title: this.titleModal,
        message: this.contentModal,
        okText: this.modalbtnok,
        cancelText: this.modalbtncancel,
        isDelete: this.isDelete,
      };
      // this.isModalResponse = true;
      this.$confirm({
        centered: true,
        autoFocusButton: null,
        title: this.titleModal,
        content: this.contentModal,
        okText: this.modalbtnok,
        okType: "danger",
        cancelText: this.modalbtncancel,
        icon: "delete",
        onOk: () => {
          this.handleLoading(true);
          this.postDeleteData();
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },
    goTo(page) {
      this.$router.push({
        name: page,
        query: { id: this.$route.query.id },
      });
    },
    toggle(value) {
      if (
        !value.length &&
        !value.applications &&
        !value.applications &&
        !value.products
      )
        return;
      let newvalue = value.collapsed;
      if (newvalue) {
        newvalue = false;
      } else {
        newvalue = true;
      }
      value.collapsed = newvalue;
      this.$forceUpdate();
    },
    callGetList() {
      let params = {
        account_id: this.exAccount.account.id,
        role_id: this.objSelect.id,
      };
      manageRoleAPI
        .getDetail(params)
        .then((res) => {
          if (res.data) {
            if (res.data.result) {
              if (res.data.result.items) {
                res.data.result.items.forEach((element) => {
                  element.id = element.package.id;
                  element.collapsed = true;
                  if (element.products && element.products.length > 0) {
                    element.products.collapsed = false;
                    element.products.forEach((products) => {
                      products.isshow = true;
                      products.idParent = element.package.id;
                      if (
                        products.data_levels &&
                        products.data_levels.length > 0
                      ) {
                        products.data_levels.forEach((data_levels) => {
                          data_levels.label = data_levels.name;
                          data_levels.value = data_levels.id;
                          if (data_levels.is_selected) {
                            products.isdefault = data_levels.id;
                          }
                        });
                      }
                      if (products.features && products.features.length > 0) {
                        products.features.forEach((features) => {
                          features.idParent = products.id;
                        });
                      }
                    });
                  }
                });
                this.data = res.data.result.items;
                this.totalList = this.data.length;
                this.dataPageBreadcrumb = {
                  totalList: this.totalList,
                };
                this.myoffsetHeight =
                  document.getElementById("mypage").offsetHeight;
                var element = document.getElementById("body_detail");
                this.myoffsetHeight = this.myoffsetHeight + 15;
                element.style.height =
                  "calc(100% - " + this.myoffsetHeight + "px)";
              }
            }
          }
          this.handleLoading(false);
          this.isShowSkeleton = false;
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callGetDetail() {
      return new Promise((resolve) => {
        let isCallDetailSuccess = false;
        let params = {
          account_id: this.exAccount.account.id,
          role_id: this.$route.query.id,
        };
        manageRoleAPI
          .getDetailRole(params)
          .then((res) => {
            if (res.data && res.data.result) {
              isCallDetailSuccess = true;
              this.objSelect = res.data.result;
              resolve(isCallDetailSuccess);
            } else {
              resolve(isCallDetailSuccess);
            }
          })
          .catch((error) => {
            this.idModal = "error";
            this.handleLoading(true);
            this.errorApi(error);
            resolve(isCallDetailSuccess);
          });
      });
    },
    callGetDetailEdit() {
      let params = {
        account_id: this.exAccount.account.id,
        role_id: this.$route.query.id,
      };
      manageRoleAPI
        .getDetailRole(params)
        .then((res) => {
          if (res.data && res.data.result) {
            this.dataObjSelect = res.data.result;
            const newData = {
              id: this.objSelect.id,
              name: this.objSelect.name,
              description: this.objSelect.description,
              is_active: this.objSelect.is_active,
            };
            this.dataEditForCheck = JSON.stringify(newData);
            this.dataObjSelect.dataEditForCheck = this.dataEditForCheck;
            this.showModalProcess();
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    postEditData() {
      var valueSave = {
        account_id: this.exAccount.account.id,
        role_id: this.objSelect.id,
        role_name: this.objDataSave.name,
        role_description: this.objDataSave.description,
        is_active: this.objDataSave.is_active,
      };
      this.objForSave = JSON.stringify(valueSave);
      manageRoleAPI
        .postEditRole(this.objForSave)
        .then((res) => {
          if (res.status == "200") {
            if (res.data.result) {
              this.idModal = "success";
              this.objSelect = res.data.result;
              const newData = {
                id: this.objSelect.id,
                description: this.objSelect.description,
                is_active: this.objSelect.is_active,
                name: this.objSelect.name,
              };
              this.dataEditForCheck = JSON.stringify(newData);
              this.closeModalProcess();
              setTimeout(() => {
                this.titleModal = this.lbls.role_page.modal.title_success;
                this.contentModal =
                  this.lbls.role_page.modal.content_update_success;
                this.openNotificationWithIcon("success");
              }, 500);
            }
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    postDeleteData() {
      this.handleLoading(true);
      var valueSave = {
        account_id: this.exAccount.account.id,
        role_id: this.objSelect.id,
      };
      manageRoleAPI
        .deleteRole(valueSave)
        .then((res) => {
          if (res.status == "200") {
            this.idModal = "success";
            this.closeModalProcess();
            setTimeout(() => {
              this.titleModal = this.lbls.role_page.modal.title_success;
              this.contentModal =
                this.lbls.role_page.modal.content_delete_success;
              this.openNotificationWithIcon("success");
            }, 500);
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    handlemodalOk() {
      if (this.idModal == "error") {
        this.isModalResponse = false;
        return;
      }
      this.handleLoading(true);
      if (this.isDelete) {
        this.postDeleteData();
      } else {
        this.postEditData();
      }
      this.isModalResponse = false;
    },
    handlemodalCancel() {
      this.errtransaction_id = "";
      this.isModalResponse = false;
      this.isDelete = false;
    },
    handlemodalCancelError() {
      if (this.isDelete) {
        this.$router.push({ name: "ManageRoles" });
      }
      if (this.ispostPermission) {
        this.ispostPermission = false;
        this.callGetList();
      }
      this.errtransaction_id = "";
      this.isModalResponse = false;
      if (this.idModal == "success") {
        this.isEdit = false;
        this.page = 0;
        this.page_size = 20;
        this.data = [];
        if (!this.isDelete) {
          this.callGetList();
        }
        this.isDelete = false;
        this.closeModalProcess();
      }
      if (this.err_status == 401) {
        this.$router.push({ name: "Home" });
      }
      if (this.err_id == 2001) {
        this.$router.push({ name: "CheckPermission" });
      }
      if (this.err_id == 13001 || this.err_id == 13002) {
        this.$router.push({ name: "ManageRoles" });
      }
    },
    onCheckAllFeature(item) {
      if (item.valuecheckbox && item.valuecheckbox.length > 0) {
        item.valuecheckbox = [];
      } else {
        item.features.forEach((features) => {
          item.valuecheckbox.push(features.id);
        });
      }
      this.$forceUpdate();
    },
    onCheckAllApplication(item) {
      if (item.valuecheckboxApp && item.valuecheckboxApp.length > 0) {
        item.valuecheckboxApp = [];
      } else {
        item.applications.forEach((application) => {
          item.valuecheckboxApp.push(application.id);
        });
      }
      this.$forceUpdate();
    },
    onCheckAllCross(item) {
      if (item.valuecheckboxCross && item.valuecheckboxCross.length > 0) {
        item.valuecheckboxCross = [];
      } else {
        item.cross_applications.forEach((cross_applications) => {
          item.valuecheckboxCross.push(cross_applications.id);
        });
      }
      this.$forceUpdate();
    },
    onChangesCheckbox() {
      this.$forceUpdate();
    },
    onChangeRadio(e) {
      this.valueDataLvRadio = [];
      this.valueDataLvRadio = e.target.value;
    },
    handleEditrecord(sub, objdata, from) {
      this.resetFieldOther();
      if (from == "product") {
        if (objdata.features) {
          if (objdata.features.length > 0) {
            objdata.features.forEach((element) => {
              this.listOfFeature.push({
                label: element.name,
                value: element.id,
              });
              if (element.is_selected) {
                this.valuecheckbox.push(element.id);
              }
            });
          }
        }
        if (objdata.data_levels) {
          if (objdata.data_levels.length > 0) {
            this.isShowListDatavl = true;
            objdata.data_levels.forEach((element) => {
              this.listOfDatalv.push({
                label: element.name,
                value: element.id,
                is_selected: element.is_selected,
                key: 1,
              });
              if (element.is_selected) {
                this.valueDataLv = element.id;
              }
            });
            const newData = {
              label: "None",
              value: "",
              key: 0,
            };
            this.listOfDatalv.push(newData);
            this.listOfDatalv.sort((a, b) => a.key - b.key);
          }
        }
        if (objdata.features || objdata.features) {
          this.data.forEach((element) => {
            if (element.subscription.id == sub.id) {
              if (element.products.length > 0) {
                element.products.forEach((products) => {
                  if (products.id == objdata.id) {
                    products.isEditShowCheckbox = true;
                    products.valuecheckbox = this.valuecheckbox;
                    products.listOfFeature = this.listOfFeature;
                    products.dataLv = this.listOfDatalv;
                    products.isShowListDatavl = this.isShowListDatavl;
                  }
                });
              }
            }
          });
        }
      }
      if (from == "applications") {
        if (objdata.applications) {
          if (objdata.applications.length > 0) {
            objdata.applications.forEach((element) => {
              this.listOfFeatureApp.push({
                label: element.name,
                value: element.id,
              });
              if (element.is_selected) {
                this.valuecheckboxApp.push(element.id);
              }
            });
          }
          this.data.forEach((element) => {
            if (element.subscription.id == objdata.subscription.id) {
              if (element.applications.length > 0) {
                element.isEditShowCheckboxApp = true;
                element.listOfFeatureApp = this.listOfFeatureApp;
                element.valuecheckboxApp = this.valuecheckboxApp;
              }
            }
          });
        }
      }
      if (from == "cross") {
        if (objdata.cross_applications) {
          if (objdata.cross_applications.length > 0) {
            objdata.cross_applications.forEach((element) => {
              this.listOfFeatureCross.push({
                label: element.name,
                value: element.id,
              });
              if (element.is_selected) {
                this.valuecheckboxCross.push(element.id);
              }
            });
          }
          this.data.forEach((element) => {
            if (element.subscription.id == objdata.subscription.id) {
              if (element.applications.length > 0) {
                element.isEditShowCheckboxCross = true;
                element.listOfFeatureCross = this.listOfFeatureCross;
                element.valuecheckboxCross = this.valuecheckboxCross;
              }
            }
          });
        }
      }
    },
    handleBackrecord() {
      this.resetFieldOther();
    },
    handleSaverecord(sub, objdata, from) {
      this.handleLoading(true);
      this.dataSelect = objdata;
      this.clickfrom = from;
      let record = {
        account_id: this.exAccount.account.id,
        role_id: this.objSelect.id,
        subscription_id: sub.id,
      };
      if (from == "product") {
        record.product_id = objdata.id;
        if (objdata.valuecheckbox && objdata.valuecheckbox.length > 0) {
          record.feature_ids = objdata.valuecheckbox;
        }
        if (this.valueDataLvRadio != "" || this.valueDataLv.length > 0) {
          record.data_level_id = this.valueDataLvRadio
            ? this.valueDataLvRadio
            : this.valueDataLv;
        }
        record = JSON.stringify(record);
        this.postPermissionProduct(record);
      }
      if (from == "applications") {
        if (objdata.valuecheckboxApp.length > 0) {
          record.application_ids = objdata.valuecheckboxApp;
        }
        record = JSON.stringify(record);
        this.dataSelect = objdata;
        this.postPermissionApplication(record);
      }
      if (from == "cross") {
        if (objdata.valuecheckboxCross.length > 0) {
          record.cross_application_ids = objdata.valuecheckboxCross;
        }
        record = JSON.stringify(record);
        this.postPermissionCross(record);
      }
    },
    postPermissionProduct(record) {
      manageRoleAPI
        .postPermissionProduct(record)
        .then((res) => {
          if (res.status == "200") {
            if (res.data.result) {
              this.idModal = "success";
              this.ispostPermission = true;
              setTimeout(() => {
                this.titleModal = this.lbls.role_page.modal.title_success;
                this.contentModal =
                  this.lbls.role_page.modal.content_update_success;
                this.openNotificationWithIcon("success");
              }, 500);
            }
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    postPermissionApplication(record) {
      manageRoleAPI
        .postPermissionApplication(record)
        .then((res) => {
          if (res.status == "200") {
            if (res.data.result) {
              this.idModal = "success";
              this.ispostPermission = true;
              setTimeout(() => {
                this.titleModal = this.lbls.role_page.modal.title_success;
                this.contentModal =
                  this.lbls.role_page.modal.content_update_success;
                this.openNotificationWithIcon("success");
              }, 500);
            }
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    postPermissionCross(record) {
      manageRoleAPI
        .postPermissionCross(record)
        .then((res) => {
          if (res.status == "200") {
            if (res.data.result) {
              this.idModal = "success";
              this.ispostPermission = true;
              setTimeout(() => {
                this.titleModal = this.lbls.role_page.modal.title_success;
                this.contentModal =
                  this.lbls.role_page.modal.content_update_success;
                this.openNotificationWithIcon("success");
              }, 500);
            }
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    resetFieldOther() {
      this.valueDataLv = "";
      this.valueDataLvRadio = "";
      this.isShowListDatavl = false;
      this.listOfFeature = [];
      this.valuecheckbox = [];
      this.listOfDatalv = [];
      this.valuecheckboxApp = [];
      this.listOfFeatureApp = [];
      this.valuecheckboxCross = [];
      this.listOfFeatureCross = [];
      this.data.forEach((element) => {
        element.isEditShowCheckboxApp = false;
        element.isEditShowCheckboxCross = false;
        if (element.products) {
          if (element.products.length > 0) {
            element.products.forEach((products) => {
              products.isEditShowCheckbox = false;
              products.isShowListDatavl = false;
            });
          }
        }
      });
      let temp = this.data;
      this.data = [];
      this.data = temp;
    },
    openNotificationWithIcon(type) {
      this.$notification[type]({
        message: this.titleModal,
        description: this.contentModal,
      });
      this.isShowSkeleton = true;
      if (this.isDelete) {
        setTimeout(() => {
          this.$router.push("manageroles");
        }, 500);
      } else if (this.clickfrom == "product") {
        this.setDataProduct();
      } else if (this.clickfrom == "applications") {
        this.setDataApp();
      } else if (this.clickfrom == "cross") {
        this.setDataCross();
      } else {
        this.handleLoading(false);
        this.isShowSkeleton = false;
      }
    },
    setDataProduct() {
      let idDataLevel = this.valueDataLvRadio
        ? this.valueDataLvRadio
        : this.valueDataLv;
      let findpackage = this.data.find(
        (o) => o.id === this.dataSelect.idParent
      );
      let findproducts = findpackage.products.find(
        (o) => o.id === this.dataSelect.id
      );
      if (findproducts) {
        findproducts.features.forEach((features) => {
          features.is_selected = false;
        });
        if (findproducts.valuecheckbox.length > 0) {
          findproducts.valuecheckbox.forEach((element) => {
            let findfeatures = findproducts.features.find(
              (o) => o.id === element
            );
            findfeatures.is_selected = true;
          });
        }
        if (idDataLevel) {
          findproducts.isdefault = idDataLevel;
          findproducts.data_levels.forEach((data_levels) => {
            data_levels.is_selected = false;
          });
          findproducts.dataLv.forEach((dataLv) => {
            dataLv.is_selected = false;
          });
          let finddata_levels = findproducts.data_levels.find(
            (o) => o.id === idDataLevel
          );
          let findDataLevel = findproducts.dataLv.find(
            (o) => o.value === idDataLevel
          );
          if (finddata_levels) finddata_levels.is_selected = true;
          if (findDataLevel) findDataLevel.is_selected = true;
        } else {
          findproducts.isdefault = "";
        }
      }
      this.resetShowdata();
    },
    setDataApp() {
      let findpackage = this.data.find((o) => o.id === this.dataSelect.id);
      findpackage.applications.forEach((applications) => {
        applications.is_selected = false;
      });
      if (findpackage.valuecheckboxApp.length > 0) {
        findpackage.valuecheckboxApp.forEach((element) => {
          let findcross = findpackage.applications.find(
            (o) => o.id === element
          );
          findcross.is_selected = true;
        });
      }
      this.resetShowdata();
    },
    actionModal(value) {
      if (value == "close") {
        this.isModalProcess = false;
        return;
      } else {
        this.handleLoading(true);
        if (this.isEdit) {
          this.objDataSave = value;
          this.postEditData();
        }
      }
    },
    setDataCross() {
      let findpackage = this.data.find((o) => o.id === this.dataSelect.id);
      findpackage.cross_applications.forEach((cross) => {
        cross.is_selected = false;
      });
      if (findpackage.valuecheckboxCross.length > 0) {
        findpackage.valuecheckboxCross.forEach((element) => {
          let findcross = findpackage.cross_applications.find(
            (o) => o.id === element
          );
          findcross.is_selected = true;
        });
      }
      this.resetShowdata();
    },
    resetShowdata() {
      this.resetFieldOther();
      this.handleLoading(false);
      this.isShowSkeleton = false;
    },
    handleErrorApi(error) {
      this.idModal = "error";
      let errorcontent = "Is Something Error";
      this.err_id = "";
      this.err_status = "";
      if (error.response) {
        this.err_status = error.response.status;
        if (this.err_status == 401) {
          errorcontent = this.lbls.layoutmaster.modal.msg_content_unauthorized;
          this.errorApi();
        } else {
          if (error.response.statusText) {
            errorcontent = error.response.statusText;
            this.errtransaction_id = error.response.data.transaction_id
              ? error.response.data.transaction_id
              : "";
            if (error.response.data.error) {
              errorcontent =
                error.response.data && error.response.data.error.length > 0
                  ? error.response.data.error[0].message
                  : error.response.statusText;
              this.err_id = error.response.data.error[0].id;
            }
          }
          setTimeout(() => {
            this.isModalResponse = true;
            this.titleModal = this.lbls.role_page.modal.title_error;
            this.contentModal = errorcontent;
            this.handleLoading(false);
          }, 500);
        }
      }
    },
  },
};
</script>
<style lang="scss">
@import "@/style/_mixin.scss";
#body_detail {
  border-top: 1px solid $grey-grey-7;
}
.manage-role-setrole {
  overflow: hidden !important;
  background: $color-white;
  height: calc(100vh - 132px);
  margin-top: 0px;
  .opacity6 {
    opacity: 0.6;
  }
  .ant-page-header-heading {
    .ant-page-header-heading-title {
      margin-top: 4px;
      font-size: 20px !important;
      width: calc(100% - 130px);
    }
    .ant-page-header-heading-extra {
      margin-top: 5px;
      text-align: right;
      width: 130px;
    }
    .ant-page-header-heading-tags {
      margin: 10px 0 !important;
    }
  }
  .checkall-role {
    border-bottom: 1px solid rgb(233, 233, 233);
    padding-bottom: 5px;
    margin-bottom: 10px;
  }
  .btn-custom-cancel,
  .btn-custom-cancel:focus,
  .btn-custom-cancel:hover {
    padding: 2px 8px;
    width: 55px;
    border: solid 1px $color-primary !important;
    height: inherit;
    color: $color-primary !important;
    font-size: 12px;
  }
  .page-wrapper {
    padding: 12px 12px 0;
  }
  .description {
    margin-bottom: 12px;
    color: $grey-grey-5;
    line-height: 1.5;
    padding: 0 12px;
    white-space: pre-line;
  }
  .arrow-show-data {
    width: 12px;
    transform: rotate(0);
    margin-top: -3px;
  }
  .custom-size-10 {
    width: 10px !important;
  }
  .custom-size-8 {
    width: 8px !important;
  }
  .arrow-hide-data {
    transform: rotate(180deg);
    opacity: 0.25;
  }
  .body-detail {
    padding: 12px;
    line-height: 22px;
    font-size: 14px;
    color: $grey-grey-2;
    white-space: pre-line;
    word-break: break-word;
    overflow: auto;
    .box-data {
      margin-bottom: 16px;
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */
    }
    .conbox-data-active {
      border: 1px solid $color-primary;
    }
    .subname {
      line-height: 1.2;
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      border: 1px solid $grey-grey-7;
      border-radius: $border-radius;
      cursor: pointer;
      .anticon {
        font-size: 14px;
        vertical-align: 0 !important;
      }
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
    }
    .subname-active {
      border: none !important;
      box-shadow: none !important;
      border-radius: $border-radius;
      border-bottom: 1px solid $color-primary !important;
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    .subname-active,
    .subname:hover {
      background: $color-white-f5e;
      border: 1px solid $color-primary;
    }
    .content {
      padding: 15px;
    }
    .application-itme {
      font-size: 16px;
      margin-top: 15px;
      .application-title {
        padding: 7px 17px 7px 12px;
        background: $grey-grey-7;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .application-desc {
        border: 2.5px solid $grey-grey-10;
        border-top: none;
        width: 100%;
        padding: 7px 12px;
        overflow: hidden;
        font-size: 14px;
        line-height: 1.57;
        .title-label {
          font-size: 12px;
          line-height: 1.5;
          color: $grey-grey-6;
        }
        .anticon {
          vertical-align: 1px !important;
          margin-right: 10px;
          color: #1890ff;
        }
      }
    }
    .product-item {
      .box-product {
        display: flex;
        justify-content: space-between;
        background: $grey-grey-7;
        padding: 7px 17px 7px 12px;
        cursor: pointer;
      }
      .product-title {
        font-size: 16px;
      }
      .product-action {
        font-size: 14px;
      }
      .product-content {
        border: 2.5px solid $grey-grey-7;
        padding-bottom: 15px;
        padding-top: 5px;
      }
      .product {
        padding: 0 15px;
        margin-top: 10px;
        font-size: 14px;
        .product-name {
          background: $grey-grey-10;
          border: 1px solid $grey-grey-10;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 8px 0;
          cursor: pointer;
          .product-label {
            margin-right: 10px;
            padding: 7px 17px 7px 12px;
          }
          .product-btn {
            padding: 0px;
            text-align: right;
          }
          .width-origianal-label {
            width: calc(100% - 40px);
          }
          .width-origianal-label-edit {
            width: calc(100% - 230px);
          }
          .width-origianal-btn {
            width: 40px;
          }
          .width-origianal-btn-edit {
            width: 230px;
          }
        }
        .product-desc {
          border: 3.5px solid $grey-grey-10;
          border-top: none;
          width: 100%;
          padding: 7px 12px;
          overflow: hidden;
          font-size: 14px;
          line-height: 1.57;
          .title-label {
            font-size: 12px;
            line-height: 1.5;
            color: $grey-grey-6;
          }
          .anticon {
            vertical-align: 1px !important;
            margin-right: 10px;
            color: #1890ff;
          }
        }
      }
    }
    .product {
      .product-title {
        background: $grey-grey-9;
        padding: 7px 17px 7px 12px;
        margin: 10px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        line-height: 1.2;
        .product-word {
          margin-right: 10px;
        }
        .product_full {
          line-height: 20px;
          font-size: 12px;
          background: #f0f5ff;
          border: 1px solid #adc6ff;
          border-radius: 20px;
          padding: 3px 10px;
          color: #2f54eb;
          .anticon {
            vertical-align: 1px !important;
          }
        }
        .product_hash {
          line-height: 20px;
          font-size: 12px;
          background: #fff0f6;
          border: 1px solid #ffadd2;
          border-radius: 20px;
          padding: 3px 10px;
          color: #eb2f96;
        }
      }
      .product-desc {
        width: 100%;
        overflow: hidden;
        font-size: 14px;
        line-height: 1.57;
        .title-label {
          font-size: 12px;
          line-height: 1.5;
          color: $grey-grey-6;
        }
        .anticon {
          vertical-align: 1px !important;
          margin-right: 10px;
          color: #1890ff;
        }
      }
    }
    .list-feature {
      width: 100%;
      .ant-checkbox-group-item {
        margin-right: 0;
        display: inline-table;
        @include flexcol(12%);
        @include breakpoint-max($desktop-xxxl) {
          @include flexcol(20%);
        }
        @include breakpoint-max($desktop-xxl) {
          @include flexcol(25%);
        }
        @include breakpoint-max($desktop-xs) {
          @include flexcol(33.3%);
        }
        @include breakpoint-max($tablet-pro) {
          @include flexcol(50%);
        }
        @include breakpoint-max($tablet-xs) {
          @include flexcol(100%);
        }
      }
    }
    .feature-list {
      width: 100%;
      .features-item {
        display: inline-table;
        @include flexcol(12%);
        @include breakpoint-max($desktop-xxxl) {
          @include flexcol(20%);
        }
        @include breakpoint-max($desktop-xxl) {
          @include flexcol(25%);
        }
        @include breakpoint-max($desktop-xs) {
          @include flexcol(33.3%);
        }
        @include breakpoint-max($tablet-pro) {
          @include flexcol(50%);
        }
        @include breakpoint-max($tablet-xs) {
          @include flexcol(100%);
        }
      }
    }
  }
}
</style>
