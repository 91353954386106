<template>
  <AppLayout>
    <div
      :class="`${$route.name}`"
      class="campaigns-detail-main campaigns-detail-page page"
    >
      <TitleBreadcrumbs
        :databreadcrumbs="breadcrumbs"
        :title="dataPageHeader.name"
      />
      <div class="set-test-beta">
        <router-link
          :to="{
            name: 'ManageRoleSetRoles',
            query: {
              id: $route.query.id,
            },
          }"
        >
          Assign Roles Version 1
        </router-link>
      </div>
      <a-card v-if="isShowSkeleton">
        <a-skeleton />
      </a-card>
      <div v-show="showDataApplications">
        <ApplicationsList
          :datapageheader="dataPageHeader"
          :dataapplications="dataApplications"
          :hasmoredata="hasMoreDataApp"
          @callgetlist="callGetList"
          @handleadd="handleAdd"
          @handleedit="handleEdit"
          @handledeleterole="handleDeleteRole"
          @onsearch="onSearch($event)"
          @handlecopies="handleCopies($event)"
          @handledeleteapp="handleDeleteApp($event)"
          @handledeleteall="handleDeleteAllApp()"
        />
      </div>
      <!-- Modal -->
      <div v-if="isModalProcess">
        <ModalProcess
          :modalprocess="modalprocess"
          :dataobjselect="dataObjSelect"
          @actionModal="actionModal($event)"
        >
        </ModalProcess>
      </div>
      <div v-if="isShowModalSelectApp">
        <ModalSelectApp
          :modalcondition="modalCondition"
          :dataapplications="dataApplicationsModal"
          :dataselectforcopy="dataSelecttoCopy"
          :hasmoredata="hasMoreData"
          :showloading="showLoading"
          @callgetlist="checkCallGetData"
          @onsearch="onSearchModal($event)"
          @actionModal="actionModal($event)"
        >
        </ModalSelectApp>
      </div>
      <div v-if="isModalResponse">
        <ModalAlert
          :modalalert="modalAlert"
          :alertdata="alertData"
          @handlemodalok="handlemodalOk"
        >
        </ModalAlert>
      </div>
    </div>
  </AppLayout>
</template>
<script>
import _ from "lodash";
import AppLayout from "@/components/Layout/LayoutMaster.vue";
import manageRoleAPI from "@/core/Page/ManageRoleAPI";
import Cache from "@/helper/Cache.js";
import ApplicationsList from "@/module/Role/ManageAssignRole/components/Layout/ApplicationsList.vue";
import ModalProcess from "@/module/Role/ManageAssignRole/components/Modal/ModalProcess.vue";
import ModalSelectApp from "@/module/Role/ManageAssignRole/components/Modal/ModalSelectApp.vue";
import ModalAlert from "@/components/Modal/Alertmodal.vue";

export default {
  name: "ManageAssignRole",
  components: {
    AppLayout,
    ApplicationsList,
    ModalProcess,
    ModalSelectApp,
    ModalAlert,
  },
  data() {
    return {
      permission_key: "role",
      action_key: "view",
      breadcrumbs: [],
      isShowSkeleton: true,
      data: [],
      dataApplications: [],
      dataApplicationsModal: [],
      objSelect: [],
      dataObjSelect: [],
      isEdit: false,
      isDelete: false,
      isModalProcess: false,
      isModalResponse: false,
      titleModalProcess: "",
      idModal: "",
      titleModal: "",
      errtransaction_id: "",
      modalbtnok: "",
      contentModal: "",
      dataSelecttoCopy: {},
      alertData: {},
      modalAlert: {
        visible: false,
        id: "",
        title: "",
        message: "",
        okText: "",
        cancelText: "",
      },
      nameSearch: "",
      nameSearchModal: "",
      dataPageHeader: {},
      page: 0,
      page_size: 20,
      hasMoreData: false,
      hasMoreDataApp: false,
      showDataApplications: false,
      isShowModalSelectApp: false,
      showLoading: false,
      group_size: 200,
      logo_default: require("@/assets/images/bzbs-logo1.png"),
    };
  },
  watch: {
    lbls() {
      this.setBreadcrumbs();
    },
    "$store.state.menu"() {
      this.handlePermissionPage(this.permission_key, this.action_key);
    },
  },
  created() {
    this.setBreadcrumbs();
    this.init();
  },
  methods: {
    init() {
      if (this.$route.query.id) {
        this.exAccount = Cache.get("exAccount");
        Promise.all([this.callGetDetail()]).then((resgetdetail) => {
          let isCallListSuccess = resgetdetail[0];
          if (isCallListSuccess) {
            this.pageGetlist = 0;
            this.dataApplications = [];
            this.callGetList();
          } else {
            window.location = window.location.origin + "/manageroles";
            this.handleLoading(false);
          }
        });
        if (this.$store.state.menu?.length) {
          this.handlePermissionPage(this.permission_key, this.action_key);
        }
      } else {
        this.$router.push({ name: "ManageRoles" });
      }
    },
    setBreadcrumbs() {
      this.breadcrumbs = [];
      this.breadcrumbs.push({
        breadcrumbName: this.lbls.menu.rolemanagement,
        name: "ManageRoles",
      });
      this.breadcrumbs.push({
        breadcrumbName: this.lbls.menu.assignrolemanagement,
        name: "InviteUsersDetail",
      });
    },
    onSearch(searchData) {
      let text = searchData;
      if (text != this.nameSearch && !this.isShowSkeleton) {
        // this.isShowSkeleton = true;
        this.nameSearch = text != undefined ? text : "";
        this.pageGetlist = 0;
        this.dataApplications = [];
        this.callGetList();
      } else {
        return;
      }
    },
    onSearchModal(searchData) {
      let text = searchData;
      if (text != this.nameSearchModal) {
        this.nameSearchModal = text != undefined ? text : "";
        if (this.isCopiesPerMission) {
          this.pageCopy = 0;
          this.dataApplicationsModal = [];
          this.callGetListForCoppy();
        }
        if (this.isAddApp) {
          this.page = 0;
          this.dataApplicationsModal = [];
          this.callGetAvailableApp();
        }
        if (this.isDeleteAllApp) {
          this.page = 0;
          this.pageGetlistDelete = 0;
          this.dataApplicationsModal = [];
          this.callGetListForDelete();
        }
      } else {
        return;
      }
    },
    showModalProcess() {
      this.isModalProcess = true;
      this.handleLoading(false);
      this.modalprocess = {
        visible: true,
        title: this.titleModalProcess,
        key: "user_page.",
        isCreate: this.isCreate,
        isEdit: this.isEdit,
      };
    },
    closeModalProcess() {
      this.isModalProcess = false;
      this.isShowSkeleton = true;
      this.showDataApplications = false;
    },
    showModalAddApps() {
      this.handleLoading(false);
      this.isShowModalSelectApp = true;
      let typeModal = this.checkTypeModal();
      this.modalCondition = {
        visible: true,
        title: this.titleModalProcess,
        key: "user_page.",
        isAddApp: this.isAddApp,
        isDeleteApp: this.isDeleteApp,
        isCopiesPerMission: this.isCopiesPerMission,
        hasmoredata: this.hasMoreData,
        type: typeModal,
      };
      this.showLoading = false;
      if (this.isAddApp) {
        this.modalCondition.okText = this.lbls.role_page.btn.add_user;
      }
      if (this.isCopiesPerMission) {
        this.modalCondition.okText = this.lbls.role_page.btn.copy_permission;
      }
      if (this.isDeleteAllApp) {
        this.modalCondition.okText = this.lbls.role_page.btn.delete_user;
      }
    },
    resetCondition() {
      this.isAddApp = false;
      this.isCopiesPerMission = false;
      this.isEdit = false;
      this.isDelete = false;
      this.isDeleteApp = false;
      this.isDeleteAllApp = false;
      this.hasMoreData = false;
      this.isShowModalSelectApp = false;
      this.page = 0;
      this.pageCopy = 0;
      this.dataApplicationsModal = [];
      this.objDataSave = [];
      this.nameSearchModal = "";
    },
    handleAdd() {
      this.resetCondition();
      this.handleLoading(true);
      this.isAddApp = true;
      this.titleModalProcess = this.lbls.role_page.modal_add_app.title_add;
      this.callGetAvailableApp();
    },
    handleEdit() {
      this.resetCondition();
      this.handleLoading(true);
      this.isEdit = true;
      this.titleModalProcess = this.lbls.role_page.drw_title_edit;
      this.callGetDetailEdit();
    },
    handleDeleteRole() {
      this.resetCondition();
      this.idModal = "confirm";
      this.isDelete = true;
      this.$confirm({
        centered: true,
        autoFocusButton: null,
        title: this.lbls.role_page.modal.content_delete,
        content: this.objSelect.name,
        okText: this.lbls.role_page.modal.btn.delete,
        okType: "danger",
        cancelText: this.lbls.role_page.btn.cancel,
        icon: "delete",
        onOk: () => {
          this.handleLoading(true);
          this.callPostDeleteData();
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },
    handleCopies(data) {
      this.resetCondition();
      this.handleLoading(true);
      this.isCopiesPerMission = true;
      this.titleModalProcess = this.lbls.role_page.modal_add_app.title_copy;
      this.dataSelecttoCopy = data;
      this.dataPackageCopy = data.package;
      this.callGetListForCoppy();
    },
    handleDeleteAllApp() {
      this.resetCondition();
      this.handleLoading(true);
      this.isDeleteAllApp = true;
      this.callGetListForDelete();
    },
    handleDeleteApp(item) {
      this.resetCondition();
      this.idModal = "confirm";
      this.isDeleteApp = true;
      this.objDataSave.push(item);
      this.$confirm({
        class: "custom-width-confirm",
        centered: true,
        autoFocusButton: null,
        title: this.lbls.role_page.modal.content_remove_apps,
        content: item.name,
        okText: this.lbls.role_page.modal.btn.remove,
        okType: "danger",
        cancelText: this.lbls.role_page.btn.cancel,
        icon: "delete",
        onOk: () => {
          this.handleLoading(true);
          this.callPostDeleteDataApp();
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },
    callGetList() {
      this.pageGetlist = this.pageGetlist + 1;
      let params = {
        account_id: this.exAccount.account.id,
        role_id: this.$route.query.id,
        page: this.pageGetlist,
        page_size: 100,
      };
      if (this.nameSearch) {
        params.application_name = this.nameSearch;
      }
      manageRoleAPI
        .getApplications(params)
        .then((res) => {
          if (res.data && res.data.result && res.data.result.items) {
            let result = res.data.result.items;
            let paging = res.data.result.paging;
            _.forEach(result, (item) => {
              item.application.logo_url = item.application.logo_url
                ? item.application.logo_url
                : this.logo_default;
            });
            this.dataApplications.push(...result);
            this.hasMoreDataApp = paging.has_more_data;
            this.isShowSkeleton = false;
            this.showDataApplications = true;
            this.handleLoading(false);
          } else {
            this.isShowSkeleton = false;
            this.showDataApplications = true;
            this.handleLoading(false);
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callGetListForCoppy() {
      this.pageCopy = this.pageCopy + 1;
      let params = {
        account_id: this.exAccount.account.id,
        role_id: this.$route.query.id,
        package_id: this.dataPackageCopy.id,
        page: this.pageCopy,
        page_size: 100,
      };
      if (this.nameSearchModal) {
        params.application_name = this.nameSearchModal;
      }
      manageRoleAPI
        .getApplications(params)
        .then((res) => {
          if (res.data && res.data.result && res.data.result.items) {
            let result = res.data.result.items;
            let paging = res.data.result.paging;
            this.hasMoreData = paging.has_more_data;
            let data = [];
            if (result.length > 0) {
              _.forEach(result, (item) => {
                data.push({
                  id: item.application.id,
                  bzbs_id: item.application.buzzebees_application_id,
                  name: item.application.name,
                  package_dsname: item.package.display_name,
                  logo_url: item.application.logo_url,
                });
              });
            } else {
              data = _.cloneDeep(this.dataApplicationsModal);
              this.dataApplicationsModal = [];
            }
            this.dataApplicationsModal.push(...data);
            this.dataApplicationsModal = this.dataApplicationsModal.filter(
              (x) => x.id != this.dataSelecttoCopy.application.id
            );
            this.showModalAddApps();
          } else {
            this.isShowSkeleton = false;
            this.showDataApplications = true;
            this.handleLoading(false);
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callGetListForDelete() {
      this.page = this.page + 1;
      let params = {
        account_id: this.exAccount.account.id,
        role_id: this.$route.query.id,
        page: this.page,
        page_size: 100,
      };
      if (this.nameSearchModal) {
        params.application_name = this.nameSearchModal;
      }
      manageRoleAPI
        .getApplications(params)
        .then((res) => {
          if (res.data && res.data.result && res.data.result.items) {
            let result = res.data.result.items;
            let paging = res.data.result.paging;
            this.hasMoreData = paging.has_more_data;
            let data = [];
            if (result.length > 0) {
              _.forEach(result, (item) => {
                data.push({
                  id: item.application.id,
                  bzbs_id: item.application.buzzebees_application_id,
                  name: item.application.name,
                  package_dsname: item.package.display_name,
                  logo_url: item.application.logo_url,
                });
              });
            } else {
              data = _.cloneDeep(this.dataApplicationsModal);
              this.dataApplicationsModal = [];
            }
            this.dataApplicationsModal.push(...data);
            this.titleModalProcess =
              this.lbls.role_page.modal_add_app.title_remove;
            this.showModalAddApps();
          } else {
            this.handleLoading(false);
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callGetDetail() {
      return new Promise((resolve) => {
        let isCallDetailSuccess = false;
        let params = {
          account_id: this.exAccount.account.id,
          role_id: this.$route.query.id,
        };
        manageRoleAPI
          .getDetailRole(params)
          .then((res) => {
            if (res.data && res.data.result) {
              isCallDetailSuccess = true;
              this.dataPageHeader = res.data.result;
              this.objSelect = res.data.result;
              resolve(isCallDetailSuccess);
            } else {
              resolve(isCallDetailSuccess);
            }
          })
          .catch((error) => {
            this.idModal = "error";
            this.handleLoading(true);
            this.errorApi(error);
            resolve(isCallDetailSuccess);
          });
      });
    },
    callGetAvailableApp() {
      this.hasMoreData = false;
      this.showLoading = true;
      this.page = this.page + 1;
      let params = {
        account_id: this.exAccount.account.id,
        role_id: this.$route.query.id,
        page: this.page,
        page_size: 100,
      };
      if (this.nameSearchModal) {
        params.application_name = this.nameSearchModal;
      }
      manageRoleAPI
        .getAvailableApp(params)
        .then((res) => {
          if (res.data && res.data.result && res.data.result.items) {
            let items = res.data.result.items;
            let paging = res.data.result.paging;
            _.forEach(items, (item) => {
              this.dataApplicationsModal.push({
                id: item.application.id,
                bzbs_id: item.application.buzzebees_application_id,
                name: item.application.name,
                package_dsname: item.package.display_name,
                logo_url: item.application.logo_url,
              });
            });
            this.hasMoreData = paging.has_more_data;
            this.showModalAddApps();
          } else {
            this.showLoading = false;
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callGetDetailEdit() {
      let params = {
        account_id: this.exAccount.account.id,
        role_id: this.$route.query.id,
      };
      manageRoleAPI
        .getDetailRole(params)
        .then((res) => {
          if (res.data && res.data.result) {
            this.dataObjSelect = res.data.result;
            const newData = {
              id: this.objSelect.id,
              name: this.objSelect.name,
              description: this.objSelect.description,
              is_active: this.objSelect.is_active,
            };
            this.dataEditForCheck = JSON.stringify(newData);
            this.dataObjSelect.dataEditForCheck = this.dataEditForCheck;
            this.showModalProcess();
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callPostAddData() {
      var valueSave = {
        account_id: this.exAccount.account.id,
        role_id: this.$route.query.id,
        application_ids: this.setDataAppsForSave(),
      };
      var valueSaveJSON = JSON.stringify(valueSave);
      manageRoleAPI
        .postAddApplications(valueSaveJSON)
        .then((res) => {
          if (res.status == "200") {
            if (res.data.result) {
              this.idModal = "success";
              this.closeModalProcess();
              setTimeout(() => {
                this.titleModal = this.lbls.role_page.modal.title_success;
                this.contentModal =
                  this.lbls.role_page.modal.content_add_apps_success;
                // this.openNotificationWithIcon("success");
                this.showModalSuccess();
                this.dataPageHeader = res.data.result;
              }, 500);
            }
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callPostCopiesData() {
      var valueSave = {
        account_id: this.exAccount.account.id,
        role_id: this.$route.query.id,
        source_application_id: this.dataSelecttoCopy.application.id,
        target_application_ids: this.setDataAppsForSave(),
      };
      this.objForSave = JSON.stringify(valueSave);
      manageRoleAPI
        .postCopiesPermission(this.objForSave)
        .then((res) => {
          if (res.status == "200") {
            if (res.data.result) {
              this.idModal = "success";
              this.closeModalProcess();
              setTimeout(() => {
                this.titleModal = this.lbls.role_page.modal.title_success;
                this.contentModal =
                  this.lbls.role_page.modal.content_update_success;
                // this.openNotificationWithIcon("success");
                this.showModalSuccess();
                this.dataPageHeader = res.data.result;
              }, 500);
            }
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callPostEditData() {
      var valueSave = {
        account_id: this.exAccount.account.id,
        role_id: this.objDataSave.id,
        role_name: this.objDataSave.name,
        role_description: this.objDataSave.description,
        is_active: this.objDataSave.is_active,
      };
      this.objForSave = JSON.stringify(valueSave);
      manageRoleAPI
        .postEditRole(this.objForSave)
        .then((res) => {
          if (res.status == "200") {
            if (res.data.result) {
              this.idModal = "success";
              this.objSelect = res.data.result;
              const newData = {
                id: this.objSelect.id,
                description: this.objSelect.description,
                is_active: this.objSelect.is_active,
                name: this.objSelect.name,
              };
              this.dataEditForCheck = JSON.stringify(newData);
              this.isModalProcess = false;
              setTimeout(() => {
                this.titleModal = this.lbls.role_page.modal.title_success;
                this.contentModal =
                  this.lbls.role_page.modal.content_update_success;
                // this.openNotificationWithIcon("success");
                this.showModalSuccess();
                this.dataPageHeader = res.data.result;
                this.handleLoading(false);
              }, 500);
            }
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callPostDeleteData() {
      this.handleLoading(true);
      var valueSave = {
        account_id: this.exAccount.account.id,
        role_id: this.objSelect.id,
        application_ids: this.objDataSave,
      };
      manageRoleAPI
        .deleteRole(valueSave)
        .then((res) => {
          if (res.status == "200") {
            this.idModal = "success";
            this.closeModalProcess();
            setTimeout(() => {
              this.titleModal = this.lbls.role_page.modal.title_success;
              this.contentModal =
                this.lbls.role_page.modal.content_delete_success;
              // this.openNotificationWithIcon("success");
              this.showModalSuccess();
            }, 500);
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callPostDeleteDataApp() {
      this.handleLoading(true);
      var valueSave = {
        account_id: this.exAccount.account.id,
        role_id: this.$route.query.id,
        application_ids: this.setDataAppsForSave(),
      };
      manageRoleAPI
        .postDeleteApplications(valueSave)
        .then((res) => {
          if (res.status == "200") {
            this.idModal = "success";
            this.isModalProcess = false;
            setTimeout(() => {
              this.titleModal = this.lbls.role_page.modal.title_success;
              this.contentModal =
                this.lbls.role_page.modal.content_remove_apps_success;
              this.showModalSuccess();
            }, 500);
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    async copyOverRecord() {
      let limitAll = this.objDataSave.length;
      let limit = 0;
      let count = Math.ceil(limitAll / this.group_size);
      for (let i = 0; i < count; i++) {
        let page_number = i;
        limit = limit + this.group_size;
        var valueSave = {
          account_id: this.exAccount.account.id,
          role_id: this.$route.query.id,
          source_application_id: this.dataSelecttoCopy.application.id,
          target_application_ids: this.setDataAppsForSaveOver(
            page_number,
            limit
          ),
        };
        let isLastgroup = i + 1 == count;
        await this.exportOver(valueSave, isLastgroup);
      }
    },
    exportOver(params, isLastgroup) {
      let newparams = JSON.stringify(params);
      return new Promise((resolve) => {
        manageRoleAPI
          .postCopiesPermission(newparams)
          .then((res) => {
            if (res.status == "200") {
              resolve();
              if (isLastgroup) {
                this.idModal = "success";
                this.closeModalProcess();
                setTimeout(() => {
                  this.titleModal = this.lbls.role_page.modal.title_success;
                  this.contentModal =
                    this.lbls.role_page.modal.content_update_success;
                  this.showModalSuccess();
                  this.dataPageHeader = res.data.result;
                }, 500);
              }
            }
          })
          .catch((error) => {
            this.idModal = "error";
            this.handleLoading(true);
            this.errorApi(error);
          });
      });
    },
    openNotificationWithIcon(type) {
      this.$notification[type]({
        message: this.titleModal,
        description: this.contentModal,
      });
      if (this.isDelete) {
        setTimeout(() => {
          this.$router.push("manageroles");
        }, 500);
      } else if (this.isAddApp || this.isCopiesPerMission) {
        this.resetCondition();
        this.init();
      } else if (this.isDeleteApp) {
        _.forEach(this.objDataSave, (item) => {
          this.dataApplications = this.dataApplications.filter(
            (x) => x.application.id != item
          );
        });
        this.isShowSkeleton = false;
        this.showDataApplications = true;
        this.handleLoading(false);
      }
    },
    showModalSuccess() {
      this.handleLoading(false);
      let secondsToGo = this.$store.state.secondstogo;
      const modal = this.$success({
        centered: true,
        autoFocusButton: null,
        title: this.contentModal,
        okType: "default",
        okText: `Ok (${secondsToGo})`,
      });
      const interval = setInterval(() => {
        secondsToGo -= 1;
        modal.update({
          okText: `Ok (${secondsToGo})`,
        });
      }, 1000);
      setTimeout(() => {
        clearInterval(interval);
        modal.destroy();
      }, secondsToGo * 1000);
      if (this.isDelete) {
        setTimeout(() => {
          window.location = window.location.origin + "/manageroles";
        }, 500);
      } else if (this.isAddApp || this.isCopiesPerMission) {
        this.resetCondition();
        this.init();
      } else if (this.isDeleteApp || this.isDeleteAllApp) {
        if (this.isDeleteApp) {
          _.forEach(this.objDataSave, (item) => {
            this.dataApplications = this.dataApplications.filter(
              (x) => x.application.id != item.id
            );
          });
        }
        if (this.isDeleteAllApp) {
          this.resetCondition();
          this.pageGetlist = 0;
          this.dataApplications = [];
          this.callGetList();
        }
      }
    },
    handlemodalOk() {
      if (this.idModal == "error") {
        this.isModalResponse = false;
        return;
      }
      this.handleLoading(true);
      if (this.isDelete) {
        this.callPostDeleteData();
      } else if (this.isDeleteApp) {
        if (this.objDataSave.length == 0) {
          _.forEach(this.dataApplications, (item) => {
            this.objDataSave.push(item.id);
          });
        }
        this.callPostDeleteDataApp();
      } else {
        this.callPostEditData();
      }
      this.isModalResponse = false;
    },
    actionModal(value) {
      if (value == "close") {
        this.isModalProcess = false;
        this.resetCondition();
        return;
      } else {
        this.handleLoading(true);
        if (this.isEdit) {
          this.objDataSave = value;
          this.callPostEditData();
        }
        if (this.isAddApp) {
          this.objDataSave = value;
          this.callPostAddData();
        }
        if (this.isCopiesPerMission) {
          this.objDataSave = value;
          let limitAll = this.objDataSave.length;
          let count = Math.ceil(limitAll / this.group_size);
          if (count > 1) {
            this.copyOverRecord();
          } else {
            this.callPostCopiesData();
          }
        }
        if (this.isDeleteAllApp) {
          this.objDataSave = value;
          this.callPostDeleteDataApp();
        }
      }
    },
    setDataAppsForSave() {
      let data = [];
      _.forEach(this.objDataSave, (item) => {
        data.push(item.id);
      });
      return data;
    },
    setDataAppsForSaveOver(no, limit) {
      let newgroup = this.group_size * +no;
      let newlimit = limit - 1;
      let data = [];
      _.forEach(this.objDataSave, (item, i) => {
        if (i >= newgroup && i <= newlimit) {
          data.push(item.id);
        }
      });
      return data;
    },
    checkCallGetData() {
      if (this.isAddApp) {
        this.callGetAvailableApp();
      }
      if (this.isDeleteAllApp) {
        this.callGetListForDelete();
      }
      if (this.isCopiesPerMission) {
        this.callGetListForCoppy();
      }
    },
    checkTypeModal() {
      let type = null;
      if (this.isAddApp) {
        type = 0;
      }
      if (this.isCopiesPerMission) {
        type = 1;
      }
      if (this.isDeleteAllApp) {
        type = 2;
      }
      return type;
    },
  },
};
</script>
<style lang="scss">
@import "@/style/_mixin.scss";
.custom-footer-setpermission {
  position: absolute;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: right;
  height: 63px;
  background: #fff;
  bottom: 0;
  width: 100%;
  .btn-custom-delete {
    img {
      margin-top: -4px;
    }
  }
}
</style>
