<template>
  <div class="list-user">
    <div class="list-user-header">
      <div class="d-flex">
        <div>
          <a-checkbox
            :id="'checkboxAllUser' + databtn"
            :checked="setChecked()"
            :indeterminate="setIndeterminate()"
            @change="onChangesCheckboxAll($event, databtn)"
          />
        </div>
        <div
          class="title-table ml-2"
          @click="onChangesCheckboxAll($event, databtn)"
        >
          <span v-if="databtn == 'delete'">{{
            lbls.role_page.assigntorole.tbl_role_User
          }}</span>
          <span v-else>{{ lbls.role_page.assigntorole.tbl_all_User }}</span>
        </div>
        <div class="ml-2">
          <a-badge :count="datatable.length" :class="'count-list'" show-zero />
        </div>
      </div>
      <div class="custom-input-search">
        <a-input-search
          v-model="searchData"
          :placeholder="lbls.role_page.assigntorole.search"
          class="input-search"
          size="large"
          @blur="onSearch"
          @search="onSearch"
        >
          <template slot="suffix">
            <a-icon
              v-if="isSearchData"
              style="margin-right: 5px; font-size: 10px"
              type="close-circle"
              theme="filled"
              class="icon-close-search-input"
              @click="closeSearchData()"
            />
          </template>
        </a-input-search>
      </div>
    </div>
    <div class="list-user-body">
      <div
        v-for="(item, index) in datatable"
        :key="item.id"
        class="item-user"
        :class="{ 'item-user-active': checkEvenorOdd(index) }"
      >
        <div class="item-user-checkbox">
          <a-checkbox
            :checked="item.is_selected"
            @change="onChangesCheckbox($event, item)"
          />
        </div>
        <div class="item-user-detail" @click="onClikSetCheckbox(item)">
          <div class="user-email">{{ item.email }}</div>
          <div class="user-detail">
            <div v-if="item.display_name">
              <span>
                {{ lbls.role_page.title_display_name }}
              </span>
              <span class="label-detail">{{ item.display_name }}</span>
            </div>
            <div v-if="item.identity_provider">
              <span>
                {{ lbls.role_page.title_identity }}
              </span>
              <span class="label-detail">
                {{ item.identity_provider }}
              </span>
            </div>
          </div>
        </div>
        <div class="item-user-btn">
          <a-button
            type="primary"
            class="vertical-initial"
            :class="{ 'btn-custom-delete': databtn == 'delete' }"
            @click="callSaveUser(item.id)"
          >
            <span v-if="databtn == 'delete'">{{
              lbls.role_page.btn.delete_user
            }}</span>
            <span v-else>{{ lbls.role_page.btn.add_user }}</span>
          </a-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PageBreadcrumb",
  props: {
    databtn: {
      type: String,
    },
    datatable: {
      type: Array,
    },
    dataselect: {
      type: Array,
    },
  },
  data() {
    return {
      data: [],
      searchData: "",
      isSearchData: false,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.data = this.datatable;
    },
    checkEvenorOdd(index) {
      return index % 2 == 0;
    },
    onChangesCheckboxAll(e, text) {
      let id_element = "#checkboxAllUser" + text;
      let element = !document.querySelector(id_element).checked;
      let checked = e.target.id ? e.target.checked : element;
      this.$emit("checkboxall", checked);
    },
    onSearch() {
      this.isSearchData = this.searchData != "";
      this.$emit("onsearch", this.searchData);
    },
    closeSearchData() {
      this.searchData = "";
      this.onSearch();
      this.$forceUpdate();
    },
    onChangesCheckbox(e, item) {
      item.is_selected = e.target.checked;
      this.$emit("setdataforsave", item);
      this.$forceUpdate();
    },
    onClikSetCheckbox(item) {
      item.is_selected = !item.is_selected;
      this.$emit("setdataforsave", item);
      this.$forceUpdate();
    },
    callSaveUser(item) {
      this.$emit("callsaveuser", item);
    },
    setChecked() {
      let itemselect = this.datatable.filter((item) => {
        return item.is_selected;
      }).length;
      return itemselect > 0 && itemselect == this.datatable.length;
    },
    setIndeterminate() {
      let itemselect = this.datatable.filter((item) => {
        return item.is_selected;
      }).length;
      return itemselect > 0 && itemselect != this.datatable.length;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/_mixin.scss";
.list-user {
  background: #fff;
  border: 1px solid #d9d9d9;
}
.list-user-header {
  font-size: 16px;
  border-bottom: 1px solid $grey-grey-7;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff5e5;
  .title-table {
    cursor: pointer;
  }
  .ant-badge {
    top: -1px;
  }
  .custom-input-search {
    width: calc(100% - 200px);
    text-align: right;
  }
}
.list-user-body {
  height: calc(100vh - 280px);
  overflow: auto;
  .user-item {
    display: flex;
  }
  .item-user {
    display: flex;
    border-bottom: 1px solid $grey-grey-7;
    padding: 16px;
    min-height: 80px;
  }
  .item-user-checkbox {
    width: 16px;
  }
  .item-user-btn {
    width: 54px;
    text-align: center;
    .ant-btn {
      padding: 3px;
      width: 100%;
      border-radius: 4px;
      height: inherit;
      font-size: 12px;
    }
  }
  .item-user-detail {
    width: calc(100% - 70px);
    line-height: 1.2;
    padding: 0 8px;
    cursor: pointer;
    .user-detail {
      font-size: 12px;
      opacity: 0.7;
      color: #7e8299;
      font-weight: normal;
    }
    .label-detail {
      font-weight: 500;
    }
  }
  .item-user-active {
    background: $grey-grey-9;
  }
}
</style>
