<template>
  <div>
    <a-modal
      :title="lbls.create_account.title_page"
      centered
      :visible="visible"
      class="modal-with-content create-owner-account"
      :closable="false"
      :mask-closable="false"
    >
      <a-form :form="form" layout="vertical">
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item>
              <template slot="label">
                <span>{{
                  lbls.create_account.company_name.name || "Company Name"
                }}</span>
              </template>
              <a-input
                v-decorator="[
                  'company_name',
                  {
                    initialValue: objDataSave.company_name,
                    rules: [
                      {
                        required: true,
                        message: lbls.create_account.company_name.required,
                      },
                      {
                        min: 1,
                        message: lbls.create_account.company_name.min,
                      },
                    ],
                  },
                ]"
                allow-clear
                :placeholder="lbls.create_account.company_name.placeholder"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item>
              <template slot="label">
                <span>{{
                  lbls.create_account.owner_email.name || "Owner Email"
                }}</span>
              </template>
              <a-input
                v-decorator="[
                  'owner_email',
                  {
                    initialValue: objDataSave.owner_email,
                    rules: [
                      {
                        required: true,
                        message: lbls.create_account.owner_email.required,
                      },
                      {
                        min: 1,
                        message: lbls.create_account.owner_email.min,
                      },
                    ],
                  },
                ]"
                allow-clear
                :placeholder="lbls.create_account.owner_email.placeholder"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item>
              <template slot="label">
                <span>{{
                  lbls.create_account.providers_type.name || "Providers Type"
                }}</span>
              </template>
              <a-select
                v-decorator="[
                  'providers_type',
                  {
                    initialValue: objDataSave.provider_type,
                    rules: [
                      {
                        required: true,
                        message: lbls.create_account.providers_type.required,
                      },
                      {
                        min: 1,
                        message: lbls.create_account.providers_type.min,
                      },
                    ],
                  },
                ]"
                show-search
                :placeholder="lbls.create_account.providers_type.placeholder"
              >
                <a-select-option value="office_365" style="width: 100%">
                  <img
                    :src="urlImage.office_365"
                    style="max-width: 16px"
                    class="mr-1"
                  />Office 365
                </a-select-option>
                <a-select-option value="hotmail_or_outlook">
                  <img
                    :src="urlImage.microsoft"
                    style="max-width: 16px"
                    class="mr-1"
                  />Hotmail/Outlook
                </a-select-option>
                <a-select-option value="google">
                  <img
                    :src="urlImage.google"
                    style="max-width: 16px"
                    class="mr-1"
                  />Google
                </a-select-option>
                <a-select-option value="local_account">
                  <img
                    :src="urlImage.local"
                    style="max-width: 16px"
                    class="mr-1"
                  />
                  Local account
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item>
              <template slot="label">
                <span>{{
                  lbls.create_account.application_id.name || "Application Id"
                }}</span>
              </template>
              <a-input
                v-decorator="[
                  'application_id',
                  {
                    initialValue: objDataSave.application_id,
                    rules: [
                      {
                        required: true,
                        message: lbls.create_account.application_id.required,
                      },
                      {
                        min: 1,
                        message: lbls.create_account.application_id.min,
                      },
                    ],
                  },
                ]"
                allow-clear
                :placeholder="lbls.create_account.application_id.placeholder"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item>
              <template slot="label">
                <span>{{
                  lbls.create_account.package_list.name || "Packcage"
                }}</span>
              </template>
              <a-input
                v-decorator="[
                  'packcage',
                  {
                    initialValue: objDataSave.packcage,
                    rules: [
                      {
                        required: true,
                        message: lbls.create_account.package_list.required,
                      },
                      {
                        min: 1,
                        message: lbls.create_account.package_list.min,
                      },
                    ],
                  },
                ]"
                allow-clear
                :placeholder="lbls.create_account.package_list.placeholder"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item>
              <template slot="label">
                <span>{{
                  lbls.create_account.description.name || "Description"
                }}</span>
              </template>
              <a-textarea
                v-decorator="[
                  'description',
                  {
                    initialValue: objDataSave.description,
                    rules: [
                      {
                        required: false,
                        message: lbls.create_account.description.required,
                      },
                      {
                        min: 1,
                        message: lbls.create_account.description.min,
                      },
                    ],
                  },
                ]"
                allow-clear
                :placeholder="lbls.create_account.description.placeholder"
                :rows="4"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <template slot="footer">
        <a-button @click="onClose"> Cancel </a-button>
        <a-button type="primary" @click="onClose"> Create </a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import Cache from "@/helper/Cache.js";
import Vue from "vue";
export default {
  name: "DrawerCreateAccount",
  props: {
    isshowmodal: {
      type: Boolean,
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
      objDataSave: {},
      visible: false,
      showModalSummary: false,
      urlImage: {
        office_365:
          Vue.bzbsConfig.extBlobUrl +
          "/custom-policies/templates-v1/src/idp_logos/colored/office-365.svg?v=" +
          new Date().getTime(),
        microsoft:
          Vue.bzbsConfig.extBlobUrl +
          "/custom-policies/templates-v1/src/idp_logos/colored/microsoft.svg?v=" +
          new Date().getTime(),
        google:
          Vue.bzbsConfig.extBlobUrl +
          "/custom-policies/templates-v1/src/idp_logos/colored/google.svg?v=" +
          new Date().getTime(),
        local:
          Vue.bzbsConfig.extBlobUrl +
          "/custom-policies/templates-v1/src/idp_logos/colored/local.svg?v=" +
          new Date().getTime(),
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.objDataSave = {};
      this.data = this.datapageheader;
      this.exAccount = Cache.get("exAccount");
      this.showDrawer();
    },
    showDrawer() {
      this.visible = true;
    },
    onClose() {
      this.visible = false;
      this.$emit("handlemodalcreate");
    },
    validate() {
      const { getFieldError } = this.form;
      let statuserr = "";
      if (getFieldError("name")) {
        statuserr = "error";
      }
      if (
        this.objDataSave.name &&
        this.objDataSave.name == this.dataobjselect.name
      ) {
        statuserr = "warning";
      }
      return statuserr;
    },
  },
};
</script>
<style lang="scss">
@import "@/style/_mixin.scss";
.create-owner-account {
  .ant-select {
    .ant-select-selection-selected-value {
      width: 100%;
    }
  }
}
</style>
