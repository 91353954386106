<template>
  <AppLayout>
    <div>
      <TitleBreadcrumbs
        v-if="lbls && lbls.package_page"
        :databreadcrumbs="breadcrumbs"
        :title="lbls.package_page.title_page"
      />
    </div>
    <div class="row">
      <PageHeader @onsearch="onSearch($event)" />
    </div>
    <a-card v-if="isShowSkeleton">
      <a-skeleton />
    </a-card>
    <div v-else>
      <TableList
        :datatable="data"
        :conditiontable="conditionTable"
        @callgetlist="callGetList"
        @callback="handleActionButton"
        @handleadd="handleAdd($event)"
        @onsearchtable="onSearchTable($event)"
      />
      <div>
        <ShowTotal
          :datashowtitle="data"
          :conditiontable="conditionTable"
          :pagesize="page_size"
          :totallist="totalList"
          @callgetlist="callGetList"
          @setpagesize="setPageSize($event)"
        />
      </div>
    </div>

    <!-- Modal -->
    <div v-if="isShowModalDetails">
      <ModalDetails
        :conditionmodal="conditionModal"
        :datalist="dataList"
        :condition="conditionTable"
        @callback="handleActionView"
        @actionModal="actionModalDetail($event)"
        @onsearchview="onSearchView($event)"
      >
      </ModalDetails>
    </div>
    <div v-if="isModalProcess">
      <ModalProcess
        :modalprocess="modalprocess"
        :dataobjselect="dataObjSelect"
        @actionModal="actionModalProcess($event)"
      >
      </ModalProcess>
    </div>
  </AppLayout>
</template>

<script>
import _ from "lodash";
import Cache from "@/helper/Cache.js";
import AppLayout from "@/components/Layout/LayoutMaster.vue";
import PageHeader from "@/module/Package/components/Layout/PageHeader.vue";
import TableList from "@/module/Package/components/Table/TableList.vue";
import ManagePackageAPI from "@/core/Page/ManagePackageAPI";
import ManageApplicationAPI from "@/core/Page/ManageApplicationAPI";
import ModalDetails from "@/module/Package/components/modal/ModalDetails.vue";
import ShowTotal from "@/components/Layout/ShowTotal";
import ModalProcess from "@/module/Package/components/modal/ModalProcess.vue";

export default {
  name: "ManagePackage",
  components: {
    AppLayout,
    PageHeader,
    TableList,
    ModalDetails,
    ShowTotal,
    ModalProcess,
  },
  data() {
    return {
      breadcrumbs: [],
      isShowSkeleton: true,
      data: [],
      conditionTable: {},
      page: 0,
      page_size: 25,
      pageView: 0,
      page_sizeView: 25,
      totalList: 0,
      exAccount: null,
      nameSearch: "",
      dataList: [],
      conditionModal: {},
      isShowModalDetails: false,
      recordView: {},
      searchView: "",
      isModalProcess: false,
      modalprocess: {},
      dataObjSelect: {},
      objDataSave: {},
      objSelectPackage: {},
    };
  },
  watch: {
    lbls() {
      this.setBreadcrumbs();
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.exAccount = Cache.get("exAccount");
      this.handleLoading(false);
      this.setBreadcrumbs();
      this.setCallGetList();
    },
    setBreadcrumbs() {
      this.breadcrumbs = [];
      this.breadcrumbs.push({
        breadcrumbName: this.lbls.menu.package,
        name: "ManagePackage",
      });
    },
    setCallGetList() {
      this.isShowSkeleton = true;
      this.page = 0;
      this.page_size = this.page_size;
      this.data = [];
      this.callGetList();
    },
    setPageSize(value) {
      this.isShowSkeleton = true;
      this.page = 0;
      this.page_size = value;
      this.data = [];
      this.callGetList();
    },
    onSearch(searchData) {
      let text = searchData;
      if (text != this.nameSearch && !this.isShowSkeleton) {
        this.nameSearch = text != undefined ? text : "";
        this.setCallGetList();
      } else {
        return;
      }
    },
    onSearchTable(data) {
      this.totalList = data.totalList >= 0 ? data.totalList : this.totalList;
      this.conditionTable.isFilters = data.isFilters;
    },
    callGetList() {
      this.page = this.page + 1;
      let params = {
        account_id: this.exAccount.account.id,
        page: this.page,
        page_size: this.page_size,
      };
      if (this.nameSearch) {
        params.package_name = this.nameSearch;
      }
      this.isShowSkeleton = false;
      console.log("callGetList params : ", params);

      ManagePackageAPI.getSearchPackageList(params)
        .then((res) => {
          console.log("callGetList params : ", params);
          if (res.data) {
            if (res.data.result) {
              let items = res.data.result.items;
              let paging = res.data.result.paging;
              if (items.length > 0) {
                _.forEach(items, (item) => {
                  item.is_show_all_products = false;
                });
                this.data.push(...res.data.result.items);
              }
              this.totalList = this.data.length;
              this.conditionTable = {
                hasMoreData: paging.has_more_data,
                isFilters: false,
                lblPage: "package_page",
              };
            }
            this.handleLoading(false);
            this.isShowSkeleton = false;
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    handleActionButton(data) {
      let button = "handle" + data.btnaction;
      this[button](data.record);
    },
    handleList(record) {
      this.resetCondition();
      this.handleLoading(true);
      this.dataList = record;
      this.conditionModal = {
        visible: true,
        title: `${this.lbls.package_page.tbl_colum_product_list} (${record.length})`,
        key: "package_page.",
        isList: true,
        package: true,
      };
      this.isShowModalDetails = true;
      this.handleLoading(false);
    },
    handleView(record) {
      this.resetCondition();
      this.recordView = record;
      console.log("handleView : ", record);
      this.callGetListApplication();
    },
    onSearchView($event) {
      console.log("$event : ", $event);
      this.pageView = 0;
      this.page_sizeView = 25;
      this.dataList = [];
      this.searchView = $event;
      console.log("searchView : ", this.searchView);
      this.callGetListApplication();
    },
    callGetListApplication() {
      this.pageView = this.pageView + 1;
      let params = {
        account_id: this.exAccount.account.id,
        package_id: this.recordView.id,
        page: this.pageView,
        page_size: this.page_sizeView,
      };

      if (this.searchView) {
        params.application_name = this.searchView;
      }

      ManageApplicationAPI.getApplicationsByPackage(params)
        .then((res) => {
          if (res.data) {
            if (res.data.result) {
              this.dataList.push(...res.data.result.items);
              this.conditionTable = {
                hasMoreData: res.data.result.paging.has_more_data,
                isFilters: false,
                lblPage: "package_page",
              };
              this.conditionModal = {
                visible: true,
                title: `${this.recordView.package_display_name}`,
                key: "package_page.",
                isList: false,
                isView: true,
                package: true,
              };
            }
            this.isShowModalDetails = true;
            this.handleLoading(false);
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(false);
          this.errorApi(error);
        });
    },
    handleActionView(data) {
      if (data == "View") {
        this.callGetListApplication();
      }
    },
    actionModalDetail() {
      this.isShowModalContent = false;
      this.isShowModalDetails = false;
      this.resetCondition();
    },
    resetCondition() {
      this.pageView = 0;
      this.page_sizeView = 25;
      this.dataList = [];
      this.conditionModal = {};
      this.recordView = {};
      this.searchView = "";
    },
    handleAdd($event) {
      console.log("handleAdd : ", $event);
      this.handleLoading(true);
      this.titleModalProcess = this.lbls.application_page.drw_title_create;
      this.dataObjSelect = $event;
      this.objSelectPackage = $event;
      this.modalprocess = {
        visible: true,
        title: this.titleModalProcess,
      };
      this.isModalProcess = true;
      this.handleLoading(false);
    },
    actionModalProcess(value) {
      if (value == "close") {
        setTimeout(() => {
          this.isModalProcess = false;
          return;
        }, 500);
      } else {
        this.handleLoading(true);
        this.objDataSave = value;
        this.postAddApplication();
      }
    },
    postAddApplication() {
      console.log("postAddApplication : ", this.objDataSave);
      var valueSave = {
        account_id: this.exAccount.account.id,
        package_id: this.objSelectPackage.id,
        application_name: this.objDataSave.name,
        application_description: this.objDataSave.description,
        buzzebees_application_Id: this.objDataSave.buzzebees_application_Id,
      };
      if (this.objDataSave.application_logo) {
        valueSave.application_logo = this.objDataSave.application_logo;
      }
      console.log("valueSave : ", valueSave);
      ManageApplicationAPI.postCreateApplicationLogo(valueSave)
        .then((res) => {
          if (res.status == "200") {
            if (res.data.result) {
              this.idModal = "success";
              this.isShowSkeleton = true;
              this.closeModalProcess();
              setTimeout(() => {
                this.titleModal =
                  this.lbls.application_page.modal.title_success;
                this.contentModal =
                  this.lbls.application_page.modal.content_create_success;
                // this.openNotificationWithIcon("success");
                this.showModalSuccess();
              }, 500);
            }
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    closeModalProcess() {
      this.isModalProcess = false;
    },
    showModalSuccess() {
      this.handleLoading(false);
      let secondsToGo = this.$store.state.secondstogo;
      const modal = this.$success({
        centered: true,
        autoFocusButton: null,
        title: this.contentModal,
        okType: "default",
        okText: `Ok (${secondsToGo})`,
      });
      const interval = setInterval(() => {
        secondsToGo -= 1;
        modal.update({
          okText: `Ok (${secondsToGo})`,
        });
      }, 1000);
      setTimeout(() => {
        clearInterval(interval);
        modal.destroy();
      }, secondsToGo * 1000);
      setTimeout(() => {
        this.goToPage("ManageApplication");
      }, 1000);
    },
  },
};
</script>
<style lang="scss">
@import "@/style/_mixin.scss";
</style>
