<template>
  <div>
    <AppLayout>
      <div
        :class="`${$route.name}`"
        class="campaigns-detail-main campaigns-detail-page page"
      >
        <div>
          <TitleBreadcrumbs
            :databreadcrumbs="breadcrumbs"
            :title="lbls.user_page.btn.invite_user"
          />
        </div>
        <div>
          <a-steps :current="current" @change="onChange">
            <a-step
              title="Step 1"
              :status="checkStatusStep1()"
              description="Enter email"
            />
            <a-step
              title="Step 2"
              :status="checkStatusStep2()"
              :disabled="dataEmails.length == 0"
              description="Select emails"
            />
            <a-step
              title="Step 3"
              :status="checkStatusStep3()"
              :disabled="
                dataEmails.length == 0 ||
                (objDataSaveMails.length == 0 && objDataSaveId.length == 0)
              "
              description="Select roles"
            />
            <a-step
              title="Step 4"
              :status="checkStatusStep4()"
              :disabled="roleID.length == 0"
              description="Choose a product."
            />
          </a-steps>
        </div>
        <div class="manage-page-detail mt-3">
          <div>
            <div
              id="enter-email"
              class="custom-process-box"
              :class="{ 'custom-process-box-active': current == 0 }"
            >
              <div class="flex-center-between">
                <label>
                  {{ lbls.user_page.input_email.name }}
                </label>
                <a :href="fileSerialTemplate" download>
                  {{ lbls.user_page.mail_upload.btn.download }}
                </a>
              </div>
              <div class="custom-set-input-action">
                <div class="set-input-left-action">
                  <a-input
                    v-model="email_name"
                    :placeholder="lbls.user_page.input_email.placeholder"
                    :disabled="isShowSkeleton"
                    @pressEnter="onCheckEmail"
                  />
                  <!-- <a-select
                    v-model="emails"
                    mode="tags"
                    style="width: 100%"
                    :disabled="isShowSkeleton"
                    @change="handleChangeSelect"
                  >
                    <a-select-option v-for="i in emails" :key="i">
                      {{ i }}
                    </a-select-option>
                  </a-select> -->
                </div>
                <div class="set-input-righ-action">
                  <div>
                    <a-button type="primary" @click="onCheckEmail">
                      {{ lbls.user_page.modal_invite_mail.btn.search_email }}
                    </a-button>
                  </div>
                  <div>
                    <input
                      ref="serialFile"
                      type="file"
                      hidden
                      multiple
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      @change="handleUploadFile"
                    />
                    <a-button
                      :disabled="isShowSkeleton"
                      type="teriary"
                      @click="clickUploadFile"
                    >
                      <a-icon type="file-excel" class="fs-20" />
                      {{ lbls.user_page.mail_upload.btn.import }}
                    </a-button>
                  </div>
                </div>
              </div>
              <div class="custom-set-input-title">
                <div class="set-input-left-title">
                  {{ lbls.user_page.input_email.title_input }}
                </div>
                <div class="set-input-righ-title">
                  {{ lbls.user_page.mail_upload.title_max }}
                </div>
              </div>
              <div v-if="msgEmail" style="color: rgb(245, 34, 45)">
                {{ msgEmail }}
              </div>
            </div>
            <!-- <div v-if="isShowSkeleton" class="background-white p-5 mt-3">
              <a-skeleton />
            </div> -->
            <a-card v-if="isShowSkeleton" class="mt-3">
              <a-skeleton />
            </a-card>
            <div
              v-if="!isShowSkeleton"
              id="select-emails"
              class="mt-3"
              :class="{ 'custom-collapse-box-active': current == 1 }"
            >
              <a-collapse default-active-key="1">
                <a-collapse-panel key="1">
                  <template slot="header">
                    <div class="custom-text-collapse-header">
                      {{ lbls.user_page.modal_invite_mail.title_list_mail }}
                    </div>
                  </template>
                  <div v-if="dataEmails.length == 0" class="w-100 text-center">
                    <a-empty :image="simpleImage" />
                  </div>
                  <div v-else>
                    <div
                      v-if="dataEmails && dataEmailMap?.size > 1"
                      class="email-list-top"
                    >
                      <div>
                        <a-checkbox
                          :checked="checkedAllItem()"
                          :indeterminate="checkedIndeterminate()"
                          class="flex-center"
                          @change="onChangCheckBoxAllItem($event)"
                        >
                          <span>
                            {{
                              lbls.user_page.modal_invite_mail.btn
                                .select_all_email
                            }}
                          </span>
                        </a-checkbox>
                      </div>
                      <div></div>
                    </div>
                    <div
                      v-for="(item, index) in dataEmails"
                      :key="item.id"
                      class="email-group"
                    >
                      <div class="email-top">
                        <div v-if="index == 'Other'">
                          <div class="border-bt">{{ index }}</div>
                          <div class="border-bt mt-3 pb-3 row">
                            <div
                              class="col-lg-2 col-xl-2 col-md-12 col-sm-12 col-12"
                            >
                              Server email type
                            </div>
                            <div
                              class="col-lg-10 col-xl-10 col-md-12 col-sm-12 col-12"
                            >
                              <a-radio-group
                                v-model="emailServer"
                                name="radioGroupEmail"
                                button-style="solid"
                                class="col-lg-3 col-xl-3 col-md-6 col-sm-6 col-12"
                                @change="onChangeEmailServer"
                              >
                                <a-radio :value="'office_365'">
                                  <img
                                    :src="urlImage.office_365"
                                    style="max-width: 20px"
                                    class="mr-1"
                                  />
                                  Office 365
                                </a-radio>
                              </a-radio-group>
                              <a-radio-group
                                v-model="emailServer"
                                name="radioGroupEmail"
                                button-style="solid"
                                class="col-lg-3 col-xl-3 col-md-6 col-sm-6 col-12"
                                @change="onChangeEmailServer"
                              >
                                <a-radio :value="'hotmail_or_outlook'">
                                  <img
                                    :src="urlImage.microsoft"
                                    style="max-width: 20px"
                                    class="mr-1"
                                  />
                                  Hotmail/Outlook
                                </a-radio>
                              </a-radio-group>
                              <a-radio-group
                                v-model="emailServer"
                                name="radioGroupEmail"
                                button-style="solid"
                                class="col-lg-3 col-xl-3 col-md-6 col-sm-6 col-12"
                                @change="onChangeEmailServer"
                              >
                                <a-radio :value="'google'">
                                  <img
                                    :src="urlImage.google"
                                    style="max-width: 20px"
                                    class="mr-1"
                                  />
                                  Google
                                </a-radio>
                              </a-radio-group>
                              <a-radio-group
                                v-model="emailServer"
                                name="radioGroupEmail"
                                button-style="solid"
                                class="col-lg-3 col-xl-3 col-md-6 col-sm-6 col-12"
                                @change="onChangeEmailServer"
                              >
                                <a-radio :value="'local_account'">
                                  <img
                                    :src="urlImage.local"
                                    style="max-width: 20px"
                                    class="mr-1"
                                  />
                                  Local account
                                </a-radio>
                              </a-radio-group>
                            </div>
                          </div>
                          <div>List email</div>
                          <div v-if="item.length > 1" class="email-select-all">
                            <a-checkbox
                              :checked="setCheckAll(item)"
                              :indeterminate="setIndeterminate(item)"
                              class="flex-center"
                              @change="onChangAllCheckBox($event, item)"
                            >
                              <span>
                                {{
                                  lbls.user_page.modal_invite_mail.btn
                                    .select_all_email_from
                                }}
                                ({{ checkLength(item) }}/{{
                                  item.length
                                }})</span
                              >
                            </a-checkbox>
                          </div>
                        </div>
                        <div v-else>
                          <div>{{ index }}</div>
                          <div v-if="item.length > 1" class="email-select-all">
                            <a-checkbox
                              :checked="setCheckAll(item)"
                              :indeterminate="setIndeterminate(item)"
                              class="flex-center"
                              @change="onChangAllCheckBox($event, item)"
                            >
                              <span>
                                {{
                                  lbls.user_page.modal_invite_mail.btn
                                    .select_all_email_from
                                }}
                                ({{ checkLength(item) }}/{{
                                  item.length
                                }})</span
                              >
                            </a-checkbox>
                          </div>
                        </div>
                      </div>
                      <div class="emails-list group-mail-test">
                        <div
                          v-for="email in item"
                          :key="email.id"
                          class="d-flex items-mail-test"
                        >
                          <div class="email-checkbox">
                            <a-checkbox
                              :id="email.email"
                              :checked="email.is_selected"
                              :disabled="email.is_disabled"
                              @change="onChangesCheckbox($event, email)"
                            >
                            </a-checkbox>
                          </div>
                          <div
                            class="email-desc"
                            :class="{ 'not-event': email.is_disabled }"
                            @click="onChangesCheckbox($event, email)"
                          >
                            <div class="title-email">{{ email.email }}</div>
                            <div class="title-channel">
                              <div>{{ email.channel }}</div>
                              <div v-if="email.identity_provider">
                                Identity Provider: {{ email.identity_provider }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a-collapse-panel>
              </a-collapse>
            </div>
            <div
              v-if="
                !isShowSkeleton &&
                (objDataSaveMails.length > 0 || objDataSaveId.length > 0)
              "
              id="select-roles"
              class="mt-3 list-group"
              :class="{ 'custom-collapse-box-active': current == 2 }"
            >
              <a-collapse default-active-key="1">
                <a-collapse-panel
                  key="1"
                  :header="lbls.user_page.modal_title_select_role"
                >
                  <a-card v-if="isShowSkeletonRoleList">
                    <a-skeleton />
                  </a-card>
                  <div v-else>
                    <div v-if="dataRoleList.length > 1" class="email-list-top">
                      <a-checkbox
                        :checked="setCheckAllRole()"
                        :indeterminate="setIndeterminateRole()"
                        @change="onChangAllCheckBoxRole($event)"
                      >
                        {{ lbls.user_page.modal_invite_mail.btn.select_all }}
                      </a-checkbox>
                    </div>
                    <div>
                      <div
                        v-if="dataRoleList.length > 0"
                        class="group-role-test"
                      >
                        <a-checkbox
                          v-for="role in dataRoleList"
                          :key="role.id"
                          :checked="role.is_selected"
                          class="items-role-test"
                          @change="onChangesCheckboxRole($event, role)"
                        >
                          {{ role.name }}
                        </a-checkbox>
                      </div>
                      <div v-else class="w-100">
                        <a-empty :image="simpleImage" />
                      </div>
                    </div>
                  </div>
                </a-collapse-panel>
              </a-collapse>
            </div>
            <div
              v-if="
                !isShowSkeleton &&
                (objDataSaveMails.length > 0 ||
                  (objDataSaveId.length > 0 && dataProductInRole.length > 0))
              "
              id="select-product"
              class="custom-process-box mt-3 list-group"
              :class="{ 'custom-process-box-active': current == 3 }"
            >
              <div class="flex-center-between">
                <label class="label-subject">
                  {{ lbls.user_page.modal_title_select_product }}
                </label>
              </div>
              <div class="mt-3">
                <a-select
                  v-model="product_id"
                  :disabled="dataProductInRole.length == 0"
                  :placeholder="lbls.user_page.modal_select_product_msg"
                  style="width: 100%; color: #999"
                  @change="handleChangeProduct"
                >
                  <a-select-option
                    v-for="item in dataProductInRole"
                    :key="item.id"
                    :value="item.id"
                  >
                    <span>
                      {{ item.display_name }}
                    </span>
                  </a-select-option>
                </a-select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
    <div class="custom-footer-setpermission">
      <a-button
        :disabled="product_id.length == 0 || isSaveOther"
        type="primary"
        @click="handleSave()"
      >
        {{ lbls.role_page.btn.save }}
      </a-button>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import Vue from "vue";
import * as XLSX from "xlsx";
import { Empty } from "ant-design-vue";
import AppLayout from "@/components/Layout/LayoutMaster.vue";
import manageUsersAPI from "@/core/Page/ManageUserAPI";
import manageRoleAPI from "@/core/Page/ManageRoleAPI";
import Cache from "@/helper/Cache.js";

export default {
  name: "ManageUser",
  components: {
    AppLayout,
  },
  data() {
    return {
      permission_key: "user",
      action_key: "invite",
      breadcrumbs: [],
      isShowSkeleton: true,
      isShowSkeletonRoleList: true,
      data: [],
      contentModal: "",
      current: 0,
      email_name: "",
      emails: [],
      emailServer: null,
      roleID: [],
      product_id: [],
      dataEmails: [],
      dataEmailStart: [],
      dataEmailMap: [],
      dataRoleList: [],
      objDataSaveMails: [],
      objDataSaveId: [],
      dataRoleSelect: [],
      dataProductInRole: [],
      isShowProcessing: false,
      isDisablebtnSave: true,
      msgEmail: "",
      totalSerialFileSize: 0,
      fileSerialTemplate:
        Vue.bzbsConfig.extBlobUrl +
        "/product-platform-external/file_templates/Import_Email_Template.xlsx",
      urlImage: {
        office_365:
          Vue.bzbsConfig.extBlobUrl +
          "/custom-policies/templates-v1/src/idp_logos/colored/office-365.svg?v=" +
          new Date().getTime(),
        microsoft:
          Vue.bzbsConfig.extBlobUrl +
          "/custom-policies/templates-v1/src/idp_logos/colored/microsoft.svg?v=" +
          new Date().getTime(),
        google:
          Vue.bzbsConfig.extBlobUrl +
          "/custom-policies/templates-v1/src/idp_logos/colored/google.svg?v=" +
          new Date().getTime(),
        local:
          Vue.bzbsConfig.extBlobUrl +
          "/custom-policies/templates-v1/src/idp_logos/colored/local.svg?v=" +
          new Date().getTime(),
      },
      isSaveOther: false,
    };
  },
  watch: {
    lbls() {
      this.setBreadcrumbs();
    },
    "$store.state.menu"() {
      this.handlePermissionPage(this.permission_key, this.action_key);
    },
  },
  beforeCreate() {
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
  },
  created() {
    this.setBreadcrumbs();
    this.init();
  },
  methods: {
    init() {
      this.exAccount = Cache.get("exAccount");
      this.handleLoading(false);
      this.isShowSkeleton = false;
      this.callGetList();
      if (this.$store.state.menu?.length) {
        this.handlePermissionPage(this.permission_key, this.action_key);
      }
    },
    setBreadcrumbs() {
      this.breadcrumbs = [];
      this.breadcrumbs.push({
        breadcrumbName: this.lbls.menu.usermanagement,
        name: "ManageUsers",
      });
      this.breadcrumbs.push({
        breadcrumbName: this.lbls.user_page.btn.invite_user,
        name: "InviteUsersDetail",
      });
    },
    showModalSuccess() {
      this.handleLoading(false);
      let secondsToGo = this.$store.state.secondstogo;
      const modal = this.$success({
        centered: true,
        autoFocusButton: null,
        title: this.contentModal,
        okType: "default",
        okText: `Ok (${secondsToGo})`,
      });
      const interval = setInterval(() => {
        secondsToGo -= 1;
        modal.update({
          okText: `Ok (${secondsToGo})`,
        });
      }, 1000);
      setTimeout(() => {
        clearInterval(interval);
        modal.destroy();
      }, secondsToGo * 1000);
      let routeData = this.$router.resolve({
        name: "InviteUsersDetail",
      });
      setTimeout(() => {
        window.open(routeData.href, "_blank");
      }, 1000);
      this.emailServer = null;
      this.clickClearMail();
      this.dataRoleList = [];
      this.init();
    },
    callGetList() {
      this.page = this.page + 1;
      let params = {
        account_id: this.exAccount.account.id,
        page: this.pageRoleList,
        page_size: 200,
      };
      manageRoleAPI
        .getSearch(params)
        .then((res) => {
          if (res.data && res.data.result) {
            if (res.data.result.items) {
              if (res.data.result.items.length > 0) {
                res.data.result.items.forEach((element) => {
                  element.is_selected = false;
                  if (element.is_active) {
                    this.dataRoleList.push(element);
                  }
                });
              }
            }
            this.isShowSkeletonRoleList = false;
          }
        })
        .catch((error) => {
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    onCheckEmail() {
      if (this.email_name?.length > 0) {
        this.isShowSkeleton = true;
        this.mailError = [];
        this.validateStatus = "";
        this.msgEmail = "";
        this.textmail = "";
        let value = this.email_name;
        let baseValue = [];
        baseValue = value.replaceAll(" ", ",").split(",");
        var emailFormat = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
        let emails = [];
        baseValue.forEach((element) => {
          if (element) {
            if (element.match(emailFormat)) {
              emails.push(element);
            } else {
              this.mailError.push(element);
              this.textmail = this.textmail
                ? this.textmail + ", " + element
                : element;
            }
          }
        });
        if (this.mailError.length > 0) {
          this.validateStatus = "error";
          this.msgEmail =
            this.lbls.user_page.input_email.invalid + " : " + this.textmail;
        }
        if (emails.length > 0) {
          this.handleCheckEmail(emails);
        } else {
          this.isShowSkeleton = false;
        }
      }
    },
    handleChangeSelect(value) {
      if (value.length == 0) {
        this.dataEmails = null;
        return;
      }
      this.isShowSkeleton = true;
      this.lastdata = value[value.length - 1];
      if (!this.lastdata) return;
      let baseValue = [];
      baseValue = this.lastdata.replaceAll(" ", ",").split(",");
      this.words = value;
      if (baseValue.length > 1) {
        baseValue.forEach((element) => {
          if (element) {
            this.words.push(element);
          }
        });
      }
      if (baseValue.length > 1 && value.length > 1) {
        this.emails = this.emails.filter((item) => {
          return item !== this.lastdata;
        });
        this.words = this.words.filter((item) => {
          return item !== this.lastdata;
        });
      }
      this.words = [...new Set(this.words)];
      this.emails = [...new Set(this.emails)];
      this.mailError = [];
      this.handleTagConfirm();
    },
    handleTagConfirm() {
      let emails = this.emails;
      var emailFormat = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
      this.validateStatus = "";
      this.msgEmail = "";
      this.textmail = "";
      _.forEach(this.words, (words) => {
        if (words) {
          if (words.match(emailFormat)) {
            if (words && emails.indexOf(words) === -1) {
              emails = [...emails, words];
            }
            this.resetTagValue(emails);
          } else {
            if (words === "") {
              this.words(emails);
            } else {
              this.emails = this.emails.filter((item) => {
                return item !== words;
              });
              this.mailError.push(words);
              this.validateStatus = "error";
              this.textmail = this.textmail
                ? this.textmail + ", " + words
                : words;
              this.msgEmail =
                this.lbls.user_page.input_email.invalid + " : " + this.textmail;
              this.isShowSkeleton = false;
            }
          }
        }
      });
      this.objDataSave = _.cloneDeep(this.emails);
      if (this.emails.length > 0) {
        this.clickSearchEmail();
      }
    },
    resetTagValue(emails) {
      Object.assign(this, {
        emails,
      });
      if (this.mailError.length > 0) {
        this.mailError.forEach((element) => {
          this.emails = this.emails.filter((item) => {
            return item !== element;
          });
        });
      }
    },
    clickSearchEmail() {
      if (this.emails.length > 0) {
        this.isShowProcessing = true;
        let emails = this.emails;
        this.emails = [];
        this.handleCheckEmail(emails);
      } else {
        return;
      }
    },
    handleCheckEmail(emails) {
      var valueSave = {
        account_id: this.exAccount.account.id,
        emails: emails,
      };
      this.objForSave = JSON.stringify(valueSave);
      manageUsersAPI
        .postSearchEMails(this.objForSave)
        .then((res) => {
          if (res.data && res.data.result && res.data.result.items) {
            _.forEach(res.data.result.items, (item) => {
              item.is_selected = true;
              item.is_disabled = false;
              item.emails = item.email;
              item.setid = 1;
              item.ids = item.id;
              if (item.id == null) {
                item.emails = "Other";
                item.ids = item.email;
                item.setid = 0;
                this.isSaveOther = true;
              }
              let findobj = this.dataEmailStart.find((o) => {
                return o.ids === item.ids;
              });
              if (!findobj) {
                this.dataEmailStart.push(item);
              }
            });
            this.dataEmailStart.sort((a, b) => a.setid - b.setid);
            this.dataEmails = _.mapValues(
              _.groupBy(this.dataEmailStart, "emails")
            );
            this.dataEmailMap = Map.groupBy(this.dataEmailStart, (item) => {
              return item.email;
            });
            console.log(this.dataEmailMap, "this.dataEmailMap");
            this.setDataForsave();
            if (this.dataEmailStart.length > 0) {
              this.current = 1;
            }
            this.email_name = "";
            this.isShowSkeleton = false;
          }
        })
        .catch((error) => {
          this.isShowSkeleton = false;
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    clickUploadFile() {
      this.$refs.serialFile.value = null;
      let fileInputElement = this.$refs.serialFile;
      fileInputElement.click();
    },
    handleUploadFile(event) {
      var fileUpload = event.target.files;
      this.vilidateFile(fileUpload, "serial");
    },
    vilidateFile(fileList, type) {
      this.handleLoading(true);
      return new Promise((resolve) => {
        Object.values(fileList).forEach((file) => {
          //check file type
          let fileState = false;
          if (
            file.type ==
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
            fileState
          ) {
            //check validate field Additional Code
            this.checkFieldValidate(file).then((isValidField) => {
              if (isValidField) {
                this.totalSerialFileSize = this.totalSerialFileSize + file.size;
                //check file size
                if (this.checkTotalFileSize()) {
                  this.handleLoading(false);
                  resolve(true);
                } else {
                  console.log("Total", type, "file size is more 2 MB");
                  this.handleLoading(false);
                  this.isInvalid = true;
                  this.$error({
                    title: "Error",
                    content: type + " File more than 2 MB",
                    centered: true,
                    okText: "OK",
                  });
                  resolve(false);
                }
              } else {
                this.handleLoading(false);
                this.isInvalid = true;
                this.$error({
                  title: "Error",
                  content: "Field Additional Code is wrong",
                  centered: true,
                  okText: "OK",
                });
                resolve(false);
              }
            });
          } else {
            this.handleLoading(false);
            this.isInvalid = true;
            this.$error({
              title: "Error",
              content: "Wrong type file",
              centered: true,
              okText: "OK",
            });
            resolve(false);
          }
        });
      });
    },
    checkFieldValidate(file) {
      return new Promise((resolve) => {
        let fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onload = () => {
          var arrayBuffer = fileReader.result;
          var data = new Uint8Array(arrayBuffer);
          var arr = new Array();
          for (var i = 0; i != data.length; ++i)
            arr[i] = String.fromCharCode(data[i]);
          var bstr = arr.join("");
          var workbook = XLSX.read(bstr, { type: "binary" });
          var first_sheet_name = workbook.SheetNames[0];
          var worksheet = workbook.Sheets[first_sheet_name];
          var emailDetail = XLSX.utils.sheet_to_json(worksheet, {
            raw: true,
          });
          var emailFile = emailDetail.map((i) => i.Email);
          var dataEmailFile = emailFile.filter((item) => {
            return item != undefined;
          });
          if (dataEmailFile && dataEmailFile.length > 0) {
            this.handleChangeSelect(emailFile);
            resolve(true);
          } else {
            resolve(false);
          }
        };
      });
    },
    checkTotalFileSize() {
      // 2048KB * 1024
      if (this.totalSerialFileSize <= 2097152) {
        return true;
      } else {
        this.handleLoading(false);
        this.isInvalid = true;
        return false;
      }
    },
    onChangesCheckbox(e, item) {
      let itemcheck = document.getElementById(item.email).checked;
      let checked =
        e.target.checked != undefined ? e.target.checked : !itemcheck;
      item.is_selected = checked;
      this.checkEmailType();
      this.setDataForsave();
      this.$forceUpdate();
    },
    clickClearMail() {
      this.disabledok = true;
      this.emails = [];
      this.product_id = [];
      this.objDataSaveMails = [];
      this.objDataSaveId = [];
      this.dataEmails = [];
      this.dataEmailStart = [];
      this.msgEmail = "";
      this.roleID = [];
      this.dataProductInRole = [];
      this.current = 0;
      _.forEach(this.dataRoleList, (role) => {
        role.is_selected = false;
      });
    },
    setCheckAll(data) {
      let dataforcheck = data.filter((item) => {
        return item.is_selected;
      });
      return dataforcheck.length == data.length;
    },
    setIndeterminate(data) {
      let dataforcheck = data.filter((item) => {
        return item.is_selected;
      });
      return dataforcheck.length > 0 && dataforcheck.length != data.length;
    },
    onChangAllCheckBox(e, items) {
      let checked = e.target.checked;
      _.forEach(items, (item) => {
        item.is_selected = checked;
      });
      this.checkEmailType();
      this.setDataForsave();
    },
    checkLength(data) {
      let dataselect = data.filter((item) => {
        return item.is_selected;
      });
      return dataselect.length;
    },
    onChangesCheckboxRole(e, item) {
      let checked = e.target.checked;
      item.is_selected = checked;
      this.setDataForCheckRole();
      this.$forceUpdate();
    },
    setDataForCheckRole() {
      this.roleID = [];
      this.product_id = [];
      this.dataProductInRole = [];
      this.dataRoleSelect = this.dataRoleList.filter((role) => {
        return role.is_selected;
      });
      _.forEach(this.dataRoleSelect, (item) => {
        this.roleID.push(item.id);
        _.forEach(item.products, (product) => {
          this.dataProductInRole.push(product);
        });
      });
      const keys = ["id"];
      this.dataProductInRole = this.dataProductInRole.filter(
        (
          (s) => (o) =>
            ((k) => !s.has(k) && s.add(k))(keys.map((k) => o[k]).join("|"))
        )(new Set())
      );
      if (this.dataProductInRole.length == 1) {
        this.product_id = this.dataProductInRole[0].id;
      }
      this.$forceUpdate();
    },
    handleChangeProduct(value) {
      this.product_id = value;
    },
    handleSave() {
      this.handleLoading(true);
      var valueSave = {
        account_id: this.exAccount.account.id,
        emails: this.objDataSaveMails,
        user_ids: this.objDataSaveId,
        role_ids: this.roleID,
        product_id: this.product_id,
      };
      if (this.emailServer != null) {
        valueSave.email_server = this.emailServer;
      }
      let objForSave = JSON.stringify(valueSave);
      manageUsersAPI
        .postAddInviteUser(objForSave)
        .then((res) => {
          if (res.status == "200") {
            if (res.data.result) {
              setTimeout(() => {
                this.titleModal = this.lbls.user_page.modal.title_success;
                this.contentModal =
                  this.lbls.user_page.modal.content_create_success;
                // this.openNotificationWithIcon("success");
                this.showModalSuccess();
              }, 500);
            }
          }
        })
        .catch((error) => {
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    checkedAllItem() {
      let checked = this.dataEmailStart.filter((item) => {
        return item.is_selected;
      }).length;
      this.checkEmailType();
      return checked > 0 && checked == this.dataEmailStart.length;
    },
    checkedIndeterminate() {
      let checked = this.dataEmailStart.filter((item) => {
        return item.is_selected;
      }).length;
      return checked > 0 && checked != this.dataEmailStart.length;
    },
    onChangCheckBoxAllItem(e) {
      let checked = e.target.checked;
      _.forEach(this.dataEmailStart, (item) => {
        item.is_selected = checked;
      });
      this.checkEmailType();
      this.setDataForsave();
    },
    setCheckAllRole() {
      let dataforcheck = this.dataRoleList.filter((item) => {
        return item.is_selected;
      });
      return dataforcheck.length == this.dataRoleList.length;
    },
    setIndeterminateRole() {
      let dataforcheck = this.dataRoleList.filter((item) => {
        return item.is_selected;
      });
      return (
        dataforcheck.length > 0 &&
        dataforcheck.length != this.dataRoleList.length
      );
    },
    onChangAllCheckBoxRole(e) {
      let checked = e.target.checked;
      _.forEach(this.dataRoleList, (role) => {
        role.is_selected = checked;
      });
      this.setDataForCheckRole();
    },
    onChange(current) {
      this.current = current;
      let goto = null;
      if (current == 0) {
        goto = "enter-email";
      } else if (current == 1) {
        goto = "select-emails";
      } else if (current == 2) {
        goto = "select-roles";
      } else if (current == 3) {
        goto = "select-product";
      }
      let text = "#" + goto;
      location.href = text;
    },
    checkCurrent() {
      if (this.dataEmails.length == 0) {
        this.current = 0;
      } else if (this.dataEmails.length > 0) {
        this.current = 1;
      } else if (
        this.dataEmails.length > 0 &&
        (this.objDataSaveMails.length > 0 || this.objDataSaveId.length > 0)
      ) {
        this.current = 2;
      } else if (
        this.dataEmails.length > 0 &&
        (this.objDataSaveMails.length > 0 || this.objDataSaveId.length > 0)
      ) {
        this.current = 3;
      }
    },
    checkStatusStep1() {
      let status = "process";
      if (this.dataEmailStart.length > 0) {
        status = "finish";
      }
      return status;
    },
    checkStatusStep2() {
      let status = "wait";
      if (this.dataEmailStart.length > 0) {
        status = "process";
      }
      if (
        this.dataEmailStart.length > 0 &&
        (this.objDataSaveMails.length > 0 || this.objDataSaveId.length > 0)
      ) {
        status = "finish";
      }
      return status;
    },
    checkStatusStep3() {
      let status = "wait";
      if (
        this.dataEmailStart.length > 0 &&
        (this.objDataSaveMails.length > 0 || this.objDataSaveId.length > 0) &&
        this.roleID.length > 0
      ) {
        status = "finish";
      }
      return status;
    },
    checkStatusStep4() {
      let status = "wait";
      if (
        this.dataEmailStart.length > 0 &&
        (this.objDataSaveMails.length > 0 || this.objDataSaveId.length > 0) &&
        this.roleID.length > 0 &&
        this.product_id.length > 0
      ) {
        status = "finish";
      }
      return status;
    },
    setDataForsave() {
      console.log("dataEmailStart : ", this.dataEmailStart);
      let saveid = [];
      let saveemail = [];
      let other_email = 0;
      let other_email_all = 0;
      _.forEach(this.dataEmailStart, (email) => {
        if (email.setid == 0) {
          other_email_all++;
        }
        if (email.is_selected) {
          if (email.setid == 0) {
            saveemail.push(email.email);
          } else if (email.setid == 1) {
            saveid.push(email.id);
          }
        } else {
          if (email.setid == 0) {
            other_email++;
          }
        }
      });
      if (other_email == other_email_all) {
        this.isSaveOther = false;
      } else if (saveemail.length == 0 && other_email_all > 0) {
        this.isSaveOther = true;
      } else if (
        saveemail.length >= 0 &&
        this.emailServer == null &&
        other_email_all > 0
      ) {
        this.isSaveOther = true;
      }
      this.objDataSaveMails = saveemail;
      this.objDataSaveId = saveid;
    },
    onChangeEmailServer(e) {
      e.preventDefault();
      this.emailServer = e.target.value;
      this.isSaveOther = false;
      this.checkEmailType();
      this.setDataForsave();
    },
    checkEmailType() {
      if (this.emailServer) {
        _.forEach(this.dataEmailStart, (item) => {
          if (item.setid == 0) {
            if (this.emailServer == "google") {
              if (item.email.includes("@gmail.com")) {
                item.is_disabled = false;
              } else if (
                item.email.includes("@hotmail.com") ||
                item.email.includes("@outlook.com") ||
                item.email.includes("@buzzebees.com")
              ) {
                item.is_selected = false;
                item.is_disabled = true;
              } else {
                item.is_disabled = false;
              }
            } else if (this.emailServer == "hotmail_or_outlook") {
              if (
                item.email.includes("@hotmail.com") ||
                item.email.includes("@outlook.com")
              ) {
                item.is_disabled = false;
              } else if (
                item.email.includes("@gmail.com") ||
                item.email.includes("@buzzebees.com")
              ) {
                item.is_selected = false;
                item.is_disabled = true;
              } else {
                item.is_disabled = false;
              }
            } else if (this.emailServer == "office_365") {
              if (item.email.includes("@buzzebees.com")) {
                item.is_disabled = false;
              } else if (
                item.email.includes("@hotmail.com") ||
                item.email.includes("@outlook.com") ||
                item.email.includes("@gmail.com")
              ) {
                item.is_selected = false;
                item.is_disabled = true;
              } else {
                item.is_disabled = false;
              }
            } else {
              item.is_disabled = false;
            }
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/_mixin.scss";
.manage-page-detail {
  overflow: auto;
  padding-right: 8px;
  padding-bottom: 16px;
  height: calc(100vh - 332px);
}
.ant-collapse {
  background-color: #fff;
}
.custom-text-collapse-header {
  font-size: 1em;
  color: var(--text-light-text-body);
  font-weight: 500;
}
.custom-col {
  padding-left: 0 !important;
}
.custom-process-box {
  border: 1px solid #e3e3e3;
  border-radius: 6px;
  background: #ffffff;
  padding: 16px;
}
.custom-process-box-active {
  border: 1px solid var(--primary-gold-bzb-primary-bzb);
}
.custom-collapse-box-active {
  .ant-collapse {
    border: 1px solid var(--primary-gold-bzb-primary-bzb);
    border-bottom: 0;
  }
  .ant-collapse > .ant-collapse-item {
    border-bottom: 1px solid var(--primary-gold-bzb-primary-bzb);
  }
}
.custom-set-input-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .set-input-left-action {
    width: calc(100% - 255px);
  }
  .set-input-righ-action {
    text-align: right;
    width: 245px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.custom-set-input-title {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  .set-input-left-title {
    color: #f79443;
    font-weight: normal;
  }
  .set-input-righ-title {
    width: 125px;
    text-align: right;
    font-weight: 600;
    opacity: 0.6;
  }
}
.email-list-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 12px;
  margin-bottom: 16px;
}
.email-group {
  border: 1px solid #d9d9d9;
  padding: 16px;
  border-radius: 12px;
  background: #fff;
  color: $color-body;
  font-weight: normal;
  word-break: break-word;
  margin-bottom: 12px;
  .email-top {
    font-size: 14px;
    font-weight: 700;
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 8px;
    margin-bottom: 8px;

    .border-bt {
      border-bottom: 1px solid #d9d9d9;
      padding-bottom: 8px;
      margin-bottom: 8px;
    }

    .ant-radio-button-wrapper {
      width: 165px;
      white-space: nowrap;
      font-size: 14px;
    }
  }
  .title-email {
    font-weight: 500;
  }
  .title-channel {
    font-size: 12px;
    opacity: 0.7;
    line-height: 1.2;
  }
  .email-desc {
    padding-left: 8px;
    cursor: pointer;
    &.not-event {
      cursor: no-drop;
    }
  }
  .email-select-all {
    margin-top: 8px;
  }
  .group-mail-test {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 8px;
  }
  .items-mail-test {
    width: calc((100% - 32px) / 4);
    @include breakpoint-max($desktop-xxxl) {
      width: calc((100% - 24px) / 3);
    }
    @include breakpoint-max($desktop-xxl) {
      width: calc((100% - 16px) / 2);
    }
    @include breakpoint-max($desktop-xs) {
      width: calc((100% - 16px) / 2);
    }
    @include breakpoint-max($tablet-pro) {
      width: 100%;
    }
    @include breakpoint-max($tablet-xs) {
      width: 100%;
    }
  }
}
.email-group:last-child {
  margin-bottom: 0;
}
.list-group {
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }
  .group-role-test {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 8px;
  }
  .items-role-test {
    width: calc((100% - 40px) / 5);
    @include breakpoint-max($desktop-xxxl) {
      width: calc((100% - 32px) / 4);
    }
    @include breakpoint-max($desktop-xxl) {
      width: calc((100% - 24px) / 3);
    }
    @include breakpoint-max($desktop-xs) {
      width: calc((100% - 16px) / 2);
    }
    @include breakpoint-max($tablet-pro) {
      width: 100%;
    }
    @include breakpoint-max($tablet-xs) {
      width: 100%;
    }
  }
}
</style>
