<template>
  <div>
    <AppLayout>
      <div
        :class="`${$route.name}`"
        class="campaigns-detail-main campaigns-detail-page page"
      >
        <TitleBreadcrumbs
          :databreadcrumbs="breadcrumbs"
          :title="lbls.menu.assignrolemanagement"
        />
        <div v-if="isShowSkeleton" class="background-white p-5">
          <a-skeleton />
        </div>
        <div
          v-if="!isShowSkeleton"
          class="manage-page-detail set-permission-app"
        >
          <div class="set-permission-top">
            <div class="flex-center">
              <div class="mr-3">
                <router-link
                  :to="{
                    name: 'ManageRoles',
                  }"
                >
                  <span class="icon-custom-add icon-arrow-nex">
                    <a-icon type="arrow-left" />
                  </span>
                  <span class="font-weight-bold text-uppercase">
                    {{ lbls.role_page.btn.back }}
                  </span>
                </router-link>
              </div>
              <a-divider type="vertical" />
              <div class="ml-3">
                <div class="label-role">
                  <div class="label-name-app">
                    {{ dataPageHeader ? dataPageHeader.name : null }}
                  </div>
                  <div v-if="dataPageHeader.description" class="label-package">
                    <span class="label-name-package"
                      >{{ lbls.role_page.description }}:
                      {{ dataPageHeader.description }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="action-list">
              <a-tooltip placement="topLeft">
                <template slot="title">
                  {{ lbls.role_page.btn.assign_applications_to_role }}
                </template>
                <router-link
                  :to="{
                    name: 'ManageAssignApplicationstoRole',
                    query: {
                      id: $route.query.id,
                    },
                  }"
                  target="_blank"
                >
                  <a-icon type="plus-square" />
                </router-link>
              </a-tooltip>
              <a-tooltip placement="topLeft">
                <template slot="title">
                  {{ lbls.role_page.btn.delete }}
                </template>
                <a @click="handleDeleteRole()">
                  <a-icon type="delete" />
                </a>
              </a-tooltip>
            </div>
          </div>
          <div
            v-if="dataProductsNew && dataProductsNew.length > 0"
            class="set-permission-body p-0"
          >
            <div class="box-left pl-3 pt-3">
              <div
                v-for="(item, i) in dataProductsNew"
                :key="item.id"
                class="item-button-href flex-center"
                :class="{ 'href-active': item.name == productActive }"
              >
                <a
                  class="w-100"
                  :href="'#' + item.name"
                  @click="clickMeTo(item.name, i + 1)"
                >
                  {{ item.display_name }}
                  <span v-if="item.isSetPerMission"
                    ><a-icon
                      type="star"
                      theme="twoTone"
                      two-tone-color="#f79443"
                  /></span>
                </a>
              </div>
            </div>
            <div class="box-right pt-3 pr-3">
              <div
                v-if="dataProductsNew.length > 1"
                class="mb-3 checkbox-all text-right box-right-top flex-center-between"
              >
                <div class="text-left" style="font-size: 12px">
                  <div>
                    <a>
                      <a-icon
                        theme="twoTone"
                        two-tone-color="#eb2f96"
                        type="profile"
                      />
                    </a>
                    Menu layer
                  </div>
                  <div>
                    <a>
                      <a-icon type="ordered-list" />
                    </a>
                    Feature layer
                  </div>
                </div>
                <div>
                  <a-tooltip v-if="checkCollapse()" placement="top">
                    <template slot="title">
                      {{ lbls.btn.collapse }}
                    </template>
                    <a-button
                      id="collapse"
                      size="small"
                      class="custom-btn-collapse"
                      @click="setCollapse($event, null)"
                    >
                      <span><a-icon type="shrink" /></span>
                    </a-button>
                  </a-tooltip>
                  <a-tooltip v-else placement="top">
                    <template slot="title">
                      {{ lbls.btn.expand }}
                    </template>
                    <a-button
                      id="expand"
                      size="small"
                      class="custom-btn-collapse"
                      @click="setCollapse($event, null)"
                    >
                      <span><a-icon type="arrows-alt" /></span>
                    </a-button>
                  </a-tooltip>
                </div>
              </div>
              <div class="item-product" @scroll="onScroll($event)">
                <div
                  v-for="item in dataProductsNew"
                  :id="item.name"
                  :key="item.id"
                  class="pt-2"
                >
                  <div
                    class="test001"
                    :class="{ 'test001-active': item.name == productActive }"
                  >
                    <div class="item-title-product mb-3">
                      <div>{{ item.display_name }}</div>
                      <div>
                        <a-tooltip
                          v-if="item.activeKey.length > 0"
                          placement="top"
                        >
                          <template slot="title">
                            {{ lbls.btn.collapse }}
                          </template>
                          <a-button
                            id="collapse"
                            size="small"
                            class="custom-btn-collapse"
                            @click="setCollapse($event, item)"
                          >
                            <span><a-icon type="shrink" /></span>
                          </a-button>
                        </a-tooltip>
                        <a-tooltip v-else placement="top">
                          <template slot="title">
                            {{ lbls.btn.expand }}
                          </template>
                          <a-button
                            id="expand"
                            size="small"
                            class="custom-btn-collapse"
                            @click="setCollapse($event, item)"
                          >
                            <span><a-icon type="arrows-alt" /></span>
                          </a-button>
                        </a-tooltip>
                      </div>
                    </div>
                    <div class="mt-3 set-border-bottom"></div>
                    <div class="mt-3">
                      <a-collapse
                        v-model="item.activeKey"
                        @change="changeActivekey($event, item)"
                      >
                        <a-collapse-panel
                          v-if="item.data_levels"
                          key="1"
                          :header="lbls.role_page.title_data_level"
                        >
                          <span
                            ><div>
                              <a-radio-group
                                v-model="item.idDataLv"
                                @change="onChangeDataLv($event, item)"
                              >
                                <a-radio value="none"> None </a-radio>
                                <a-radio
                                  v-for="lv in item.data_levels"
                                  :key="lv.id"
                                  :value="lv.id"
                                >
                                  {{ lv.display_name }}
                                </a-radio>
                              </a-radio-group>
                            </div></span
                          >
                        </a-collapse-panel>
                        <a-collapse-panel
                          v-if="item.newmenulist"
                          key="2"
                          header="Menu"
                        >
                          <span>
                            <div class="label-item">
                              <div
                                v-if="item.featuresAll.length > 1"
                                class="checkbox-all flex-center-between"
                              >
                                <div>
                                  <a-checkbox
                                    :checked="setCheckAllMenu(item)"
                                    :indeterminate="setIndeterminatemenu(item)"
                                    @change="onCheckAllMenu($event, item)"
                                  >
                                    <span>
                                      <span>{{
                                        lbls.role_page.set_permission
                                          .select_all_menu
                                      }}</span>
                                      <span class="lable-length">
                                        {{ item.featuresSelect.length }}/{{
                                          item.featuresAll.length
                                        }}</span
                                      >
                                    </span>
                                  </a-checkbox>
                                </div>
                                <div>
                                  <a-tooltip
                                    v-if="expandAllMenu"
                                    placement="top"
                                  >
                                    <template slot="title">
                                      {{ lbls.btn.collapse }}
                                    </template>
                                    <a-button
                                      id="collapse"
                                      size="small"
                                      class="custom-btn-collapse"
                                      @click="setCollapseMenu(item)"
                                    >
                                      <span><a-icon type="shrink" /></span>
                                    </a-button>
                                  </a-tooltip>
                                  <a-tooltip v-else placement="top">
                                    <template slot="title">
                                      {{ lbls.btn.expand }}
                                    </template>
                                    <a-button
                                      id="expand"
                                      size="small"
                                      class="custom-btn-collapse"
                                      @click="setCollapseMenu(item)"
                                    >
                                      <span><a-icon type="arrows-alt" /></span>
                                    </a-button>
                                  </a-tooltip>
                                </div>
                              </div>
                              <div v-if="item.isShowMenu">
                                <a-tree
                                  v-if="item.newmenulist"
                                  v-model="item.menuChecked"
                                  checkable
                                  :tree-data="item.newmenulist"
                                  :default-expand-all="expandAllMenu"
                                  @check="onCheckMenuNew($event, item)"
                                >
                                  <template slot="title" slot-scope="text">
                                    <a v-if="!text.isFeature">
                                      <a-icon
                                        theme="twoTone"
                                        two-tone-color="#eb2f96"
                                        type="profile"
                                      />
                                    </a>
                                    <a v-if="text.isFeature">
                                      <a-icon type="ordered-list" />
                                    </a>
                                    {{ text.title }}
                                    <span
                                      v-if="!text.isFeature"
                                      class="lable-length"
                                      >({{ text.children.length }})</span
                                    >
                                  </template>
                                </a-tree>
                              </div>
                            </div>
                          </span>
                        </a-collapse-panel>
                        <a-collapse-panel
                          v-if="item.features"
                          key="4"
                          :header="lbls.role_page.title_features"
                        >
                          <span>
                            <div class="label-item">
                              <div
                                v-if="item.features.length > 1"
                                class="checkbox-all"
                              >
                                <a-checkbox
                                  :checked="setCheckAll(item.features)"
                                  :indeterminate="
                                    setIndeterminate(item.features)
                                  "
                                  @change="
                                    onChangAllCheckBox($event, item.features)
                                  "
                                >
                                  <span>
                                    <span
                                      >{{
                                        lbls.role_page.set_permission
                                          .select_all_features
                                      }}
                                    </span>
                                    <span class="lable-length">{{
                                      setShowLengthItem(item.features)
                                    }}</span>
                                  </span>
                                </a-checkbox>
                              </div>
                              <div class="feature-list">
                                <a-checkbox
                                  v-for="feature in item.features"
                                  :key="feature.id"
                                  :checked="feature.is_selected"
                                  class="feature-item"
                                  @change="onChangesCheckbox($event, feature)"
                                >
                                  <span>{{ feature.display_name }}</span>
                                </a-checkbox>
                              </div>
                            </div>
                          </span>
                        </a-collapse-panel>
                      </a-collapse>
                    </div>
                    <div class="mt-3 set-border-bottom" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <a-empty
              :image="imgEmpty"
              :image-style="{
                height: '150px',
              }"
            >
              <router-link
                :to="{
                  name: 'ManageAssignApplicationstoRole',
                  query: {
                    id: $route.query.id,
                  },
                }"
              >
                <a-button type="primary" ghost>
                  <a-icon type="plus" />
                  {{ lbls.role_page.btn.assign_applications_to_role }}
                </a-button>
              </router-link>
            </a-empty>
          </div>
        </div>
      </div>
    </AppLayout>
    <div v-if="!isShowSkeleton" class="custom-footer-setpermission">
      <a-button :disabled="disablebtnok" type="primary" @click="handleSave()">
        {{ lbls.role_page.btn.save }}
      </a-button>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import Cache from "@/helper/Cache.js";
import { config } from "@/config/bzbsconfig.js";
import manageRoleAPI from "@/core/Page/ManageRoleAPI";
import AppLayout from "@/components/Layout/LayoutMaster.vue";
import managePermissionAPI from "@/core/Page/ManagePermissionAPI";

export default {
  name: "ManageRoleSetPerMission",
  components: {
    AppLayout,
  },
  data() {
    return {
      breadcrumbs: [],
      imgEmpty: config.themeUrl + "/img/empty.svg",
      isShowSkeleton: true,
      dataPageHeader: {},
      objSelect: [],
      dataProductsNew: [],
      nameSearch: null,
      isSetDataForCheckSave: false,
      dataProductForSave: [],
      dataForCheckSave: null,
      disablebtnok: true,
      idModal: "",
      productActive: "",
      expandAllMenu: true,
      titleModal: "",
      contentModal: "",
    };
  },
  watch: {
    lbls() {
      this.setBreadcrumbs();
    },
  },
  mounted() {},
  created() {
    window.addEventListener("scroll", this.onScroll);
    this.setBreadcrumbs();
    this.init();
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    init() {
      if (this.$route.query.id) {
        this.exAccount = Cache.get("exAccount");
        Promise.all([this.callGetDetail()]).then((resgetdetail) => {
          let isCallListSuccess = resgetdetail[0];
          if (isCallListSuccess) {
            this.nameSearch = null;
            this.callPermissionProducts();
          } else {
            this.handleLoading(false);
          }
        });
      } else {
        this.$router.push({ name: "ManageRoles" });
      }
    },
    setBreadcrumbs() {
      this.breadcrumbs = [];
      this.breadcrumbs.push({
        breadcrumbName: this.lbls.menu.rolemanagement,
        name: "ManageRoles",
      });
      this.breadcrumbs.push({
        breadcrumbName: this.lbls.menu.assignrolemanagement,
        name: "ManageRoleSetPerMission",
      });
    },
    callGetDetail() {
      return new Promise((resolve) => {
        let isCallDetailSuccess = false;
        let params = {
          account_id: this.exAccount.account.id,
          role_id: this.$route.query.id,
        };
        manageRoleAPI
          .getDetailRole(params)
          .then((res) => {
            if (res.data && res.data.result) {
              isCallDetailSuccess = true;
              this.dataPageHeader = res.data.result;
              this.objSelect = res.data.result;
              resolve(isCallDetailSuccess);
            } else {
              resolve(isCallDetailSuccess);
            }
          })
          .catch((error) => {
            this.idModal = "error";
            this.handleLoading(true);
            this.errorApi(error);
            resolve(isCallDetailSuccess);
          });
      });
    },
    callPermissionProducts() {
      let params = {};
      let is_role_config = false;
      if (this.$route.query.name) {
        let role_name = this.$route.query?.name?.toLowerCase();
        role_name = role_name.replace(/ /g, "");
        is_role_config = role_name.includes("configpermission");
        if (is_role_config) {
          params = {
            account_id: this.exAccount.account.id,
            role_id: this.$route.query.id,
            is_internal_external: true
          };
        } else {
          params = {
            account_id: this.exAccount.account.id,
            role_id: this.$route.query.id,
          };
        }
      } else {
        params = {
          account_id: this.exAccount.account.id,
          role_id: this.$route.query.id,
        };
      }
      managePermissionAPI
        .getPermissionProducts(params)
        .then((res) => {
          if (res.data && res.data.result) {
            if (res.data.result.products != undefined) {
              let result = res.data.result;
              let dataset = {
                records: result.products,
              };
              this.dataProductsNew = dataset.records;
              this.isSetDataForCheckSave = true;
              if (this.dataProductsNew && this.dataProductsNew.length > 0) {
                this.setDataProductsNew();
              }
              this.handleLoading(false);
              this.isShowSkeleton = false;
            } else {
              console.log("NO Data");
              this.handleLoading(false);
              this.isShowSkeleton = false;
            }
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    setDataProductsNew() {
      _.forEach(this.dataProductsNew, (element, index) => {
        element.activeKey = [];
        if (element.data_levels) {
          element.activeKey.push("1");
          let findobj = element.data_levels.find((o) => o.is_selected === true);
          if (findobj) {
            element.idDataLv = findobj.id;
          } else {
            element.idDataLv = "none";
          }
        } //set data menu
        if (element.menus && element.menus.length > 0) {
          element.menus.sort((a, b) =>
            a.display_name.localeCompare(b.display_name)
          );
          this.menuChecked = [];
          this.featuresAll = [];
          this.featuresSelect = [];
          element.newmenulist = this.onSetNewMenu(element.menus);
          element.menuChecked = this.menuChecked;
          element.featuresAll = this.featuresAll;
          element.featuresSelect = this.featuresSelect;
          element.isShowMenu = this.expandAllMenu;
          if (element.newmenulist) {
            element.activeKey.push("2");
          }
        } //set data features
        if (element.features) {
          element.activeKey.push("4");
        }
        if (index == 0) {
          this.productActive = element.name;
        }
        element.activeKeyStart = element.activeKey;
      });
      this.isSetDataForCheckSave = true;
      this.setDataForSaveNew();
    },
    setDataForSaveNew() {
      this.dataProductForSave = [];
      _.forEach(this.dataProductsNew, (element) => {
        let dataItemForSave = {};
        let featuresSelectAll = [];
        element.isSetPerMission = false;
        if (element.idDataLv && element.idDataLv != "none") {
          dataItemForSave.data_level_id = element.idDataLv;
        }
        if (element.featuresSelect && element.featuresSelect.length > 0) {
          _.forEach(element.featuresSelect, (feature) => {
            featuresSelectAll.push(feature);
          });
          dataItemForSave.feature_ids = featuresSelectAll;
        }
        if (element.features) {
          _.forEach(element.features, (feature) => {
            if (feature.is_selected) {
              featuresSelectAll.push(feature.id);
            }
          });
          if (featuresSelectAll.length > 0) {
            dataItemForSave.feature_ids = featuresSelectAll;
          }
        }
        if (isEmpty(dataItemForSave)) {
          element.isSetPerMission = isEmpty(dataItemForSave);
          dataItemForSave.id = element.id;
          this.dataProductForSave.push(dataItemForSave);
        }
        function isEmpty(obj) {
          return Object.keys(obj).length > 0;
        }
      });
      if (this.isSetDataForCheckSave) {
        this.isSetDataForCheckSave = false;
        this.dataForCheckSave = JSON.stringify(this.dataProductForSave);
      }
      let dataProductForSave = JSON.stringify(this.dataProductForSave);
      this.disablebtnok = this.dataForCheckSave == dataProductForSave;
    },
    clickMeTo(refName, i) {
      console.log("clickMeTo : ", refName, i);
    },
    onSetNewMenu(items) {
      let newmenulist = [];
      _.forEach(items, (item) => {
        let children = [];
        let data = item.menus ? item.menus : item.features;
        let isFeature = false;
        let isFeatureChildren = item.features && item.features.length > 0;
        if (data && data.length > 0) {
          data.sort((a, b) => a.display_name.localeCompare(b.display_name));
          children = this.onSetNewMenu(data);
        }
        // this.checkedKeysMenuAll.push(item.id);
        if (item.is_selected != null) {
          isFeature = true;
          this.featuresAll.push(item.id);
          if (item.is_selected) {
            this.menuChecked.push(item.id);
            this.featuresSelect.push(item.id);
          }
        }
        newmenulist.push({
          title: item.display_name,
          key: item.id,
          id: item.id,
          sequence: item.sequence,
          children: children,
          isFeature: isFeature,
          isFeatureChildren: isFeatureChildren,
        });
      });
      return newmenulist;
    },
    checkCollapse() {
      let activeKey = [];
      _.forEach(this.dataProductsNew, (item) => {
        if (item.activeKey && item.activeKey.length > 0) {
          activeKey.push(item);
        }
      });
      return activeKey.length;
    },
    setCollapse(e, item) {
      if (item) {
        if (e.target.id == "collapse") {
          item.activeKey = [];
        }
        if (e.target.id == "expand") {
          item.activeKey = item.activeKeyStart;
        }
      } else {
        _.forEach(this.dataProductsNew, (item) => {
          if (e.target.id == "collapse") {
            item.activeKey = [];
          }
          if (e.target.id == "expand") {
            item.activeKey = item.activeKeyStart;
          }
        });
      }
      this.$forceUpdate();
    },
    onScroll(event) {
      var scrollDiv = null;
      this.scrollData = [];
      _.forEach(this.dataProductsNew, (item) => {
        scrollDiv = document.getElementById(item.name);
        if (scrollDiv) {
          scrollDiv = scrollDiv.offsetTop;
          this.scrollData.push({
            offsetTop: scrollDiv,
            id: item.id,
            name: item.name,
          });
        }
      });
      let header = document.querySelector(".ant-layout-header").offsetHeight;
      let breadcrumbs =
        document.querySelector(".title-breadcrumbs").offsetHeight;
      let topPage = document.querySelector(".set-permission-top").offsetHeight;
      let topBox = document.querySelector(".box-right-top").offsetHeight;
      this.startBoxData = header + breadcrumbs + topPage + topBox + 56;
      var scrollTop = event.target.scrollTop + this.startBoxData;
      this.dataObjSelectscroll = [];
      this.dataObjSelectscroll = this.scrollData.filter((data) => {
        return data.offsetTop < scrollTop;
      });
      if (this.isLastItem) {
        this.dataObjSelectscroll = this.scrollData;
        this.isLastItem = false;
      }
      if (this.dataObjSelectscroll.length > 0) {
        this.productActive = this.dataObjSelectscroll.slice(-1)[0].name;
      }
      this.isSetCollapseAll = false;
    },
    changeActivekey(key, item) {
      item.activeKey = key;
      this.$forceUpdate();
    },
    onChangeDataLv(e, item) {
      let datalv = e.target.value;
      _.forEach(item.data_levels, (element) => {
        element.is_selected = false;
        if (datalv == element.id) {
          element.is_selected = true;
        }
      });
      item.idDataLv = e.target.value;
      this.setDataForSaveNew();
      this.$forceUpdate();
    },
    setCheckAllMenu(item) {
      return item.featuresSelect.length == item.featuresAll.length;
    },
    setIndeterminatemenu(item) {
      return (
        item.featuresAll.length != item.featuresSelect.length &&
        item.featuresSelect.length > 0
      );
    },
    onCheckAllMenu(e, item) {
      let checked = e.target.checked;
      if (checked) {
        item.menuChecked = item.featuresAll;
        item.featuresSelect = item.featuresAll;
      } else {
        item.menuChecked = [];
        item.featuresSelect = [];
      }
      this.setDataForSaveNew();
      this.$forceUpdate();
    },
    setCollapseMenu(item) {
      item.isShowMenu = !item.isShowMenu;
      this.expandAllMenu = !this.expandAllMenu;
      this.$forceUpdate();
      setTimeout(() => {
        item.isShowMenu = !item.isShowMenu;
        this.$forceUpdate();
      });
    },
    onCheckMenuNew(checkedKeys, item) {
      item.featuresSelect = [];
      _.forEach(checkedKeys, (element) => {
        let findobj = item.featuresAll.find((id) => id == element);
        if (findobj) {
          item.featuresSelect.push(findobj);
        }
      });
      this.setDataForSaveNew();
      this.$forceUpdate();
    },
    setCheckAll(features) {
      let featuresSelect = features.filter((item) => {
        return item.is_selected;
      });
      return featuresSelect.length == features.length;
    },
    setIndeterminate(features) {
      let featuresSelect = features.filter((item) => {
        return item.is_selected;
      });
      return (
        featuresSelect.length > 0 && featuresSelect.length != features.length
      );
    },
    onChangAllCheckBox(e, features) {
      _.forEach(features, (item) => {
        this.onChangesCheckbox(e, item);
      });
    },
    onChangesCheckbox(e, feature) {
      let checked = e.target.checked;
      feature.is_selected = checked;
      this.setDataForSaveNew();
      this.$forceUpdate();
    },
    setShowLengthItem(features) {
      let featuresSelect = features.filter((item) => {
        return item.is_selected;
      });
      let lengthItem =
        "(" + featuresSelect.length + "/" + features.length + ")";
      return lengthItem;
    },
    handleSave() {
      this.handleLoading(true);
      let record = {
        account_id: this.exAccount.account.id,
        role_id: this.dataPageHeader.id,
        products: this.dataProductForSave,
      };
      record = JSON.stringify(record);
      managePermissionAPI
        .postPermissionProducts(record)
        .then((res) => {
          if (res.status == "200" && res.data.result) {
            this.idModal = "success";
            this.ispostPermission = true;
            this.titleModal = this.lbls.role_page.modal.title_success;
            this.contentModal =
              this.lbls.role_page.modal.content_update_success;
            this.isSetDataForCheckSave = true;
            this.settestcheckallproduct();
            this.showModalSuccess();
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    settestcheckallproduct() {
      let feature_selected_all = [];
      let product_list = [];
      let itemproduct = {};
      this.dataProductsNew.feature_selected_all = [];
      _.forEach(this.dataProductsNew, (element) => {
        if (element.features) {
          let feature_list = [];
          _.forEach(element.features, (feature) => {
            if (feature.is_selected) {
              feature_selected_all.push(feature);
              feature_list.push(feature.id);
            }
          });
          if (feature_list.length > 0) {
            itemproduct = {
              id: element.id,
              feature_ids: feature_list,
            };
            product_list.push(itemproduct);
          }
          this.dataProductsNew.feature_selected_all = feature_selected_all;
        }
      });
      this.dataProductForSave = product_list;
      this.setDataForSave();
    },
    setDataForSave() {
      _.forEach(this.dataProductsNew, (element) => {
        if (element.menuSelected && element.menuSelected.length >= 0) {
          let findobj = this.dataProductForSave.find(
            (o) => o.id === element.id
          );
          if (findobj) {
            findobj.menu_ids = element.menuSelected;
          } else {
            this.dataProductForSave.push({
              id: element.id,
              menu_ids: element.menuSelected,
            });
          }
        }
        if (element.pageSelected && element.pageSelected.length >= 0) {
          let findobj = this.dataProductForSave.find(
            (o) => o.id === element.id
          );
          if (findobj) {
            findobj.page_ids = element.pageSelected;
          } else {
            this.dataProductForSave.push({
              id: element.id,
              page_ids: element.pageSelected,
            });
          }
        }
        if (element.featuresSelected && element.featuresSelected.length >= 0) {
          let findobj = this.dataProductForSave.find(
            (o) => o.id === element.id
          );
          if (findobj) {
            findobj.featuresSelected = element.featuresSelected;
          } else {
            this.dataProductForSave.push({
              id: element.id,
              featuresSelected: element.featuresSelected,
            });
          }
          let feature_ids = findobj.feature_ids ? findobj.feature_ids : [];
          if (findobj && findobj.featuresSelected) {
            _.forEach(element.featuresSelected, (feature) => {
              let findfeature = feature_ids.find((o) => o === feature);
              if (!findfeature) {
                feature_ids.push(feature);
                findobj.feature_ids = feature_ids;
              }
            });
          }
        }
        if (element.idDataLv && element.idDataLv != "none") {
          let findobj = this.dataProductForSave.find(
            (o) => o.id === element.id
          );
          if (findobj) {
            findobj.data_level_id = element.idDataLv;
          } else {
            this.dataProductForSave.push({
              id: element.id,
              data_level_id: element.idDataLv,
            });
          }
        }
      });
      if (this.isSetDataForCheckSave) {
        this.isSetDataForCheckSave = false;
        this.dataForCheckSave = JSON.stringify(this.dataProductForSave);
      }
      let dataProductForSave = JSON.stringify(this.dataProductForSave);
      this.disablebtnok = this.dataForCheckSave == dataProductForSave;
    },
    showModalSuccess() {
      this.handleLoading(false);
      let secondsToGo = this.$store.state.secondstogo;
      const modal = this.$success({
        centered: true,
        autoFocusButton: null,
        title: this.contentModal,
        okType: "default",
        okText: `Ok (${secondsToGo})`,
      });
      const interval = setInterval(() => {
        secondsToGo -= 1;
        modal.update({
          okText: `Ok (${secondsToGo})`,
        });
      }, 1000);
      setTimeout(() => {
        clearInterval(interval);
        modal.destroy();
      }, secondsToGo * 1000);
      if (this.isDelete) {
        setTimeout(() => {
          this.$router.push("manageroles");
        }, 500);
      }
      this.callPermissionProducts();
    },
    resetCondition() {
      this.isAddApp = false;
      this.isCopiesPerMission = false;
      this.isEdit = false;
      this.isDelete = false;
      this.isDeleteApp = false;
      this.isDeleteAllApp = false;
      this.hasMoreData = false;
      this.isShowModalSelectApp = false;
      this.page = 0;
      this.pageCopy = 0;
      this.dataApplicationsModal = [];
      this.objDataSave = [];
      this.nameSearchModal = "";
    },
    handleDeleteRole() {
      this.resetCondition();
      this.idModal = "confirm";
      this.isDelete = true;
      this.$confirm({
        centered: true,
        autoFocusButton: null,
        title: this.lbls.role_page.modal.content_delete,
        content: this.objSelect.name,
        okText: this.lbls.role_page.modal.btn.delete,
        okType: "danger",
        cancelText: this.lbls.role_page.btn.cancel,
        icon: "delete",
        onOk: () => {
          this.handleLoading(true);
          this.callPostDeleteData();
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },
    callPostDeleteData() {
      this.handleLoading(true);
      var valueSave = {
        account_id: this.exAccount.account.id,
        role_id: this.objSelect.id,
      };
      manageRoleAPI
        .deleteRole(valueSave)
        .then((res) => {
          if (res.status == "200") {
            this.idModal = "success";
            this.isShowSkeleton = true;
            setTimeout(() => {
              this.titleModal = this.lbls.role_page.modal.title_success;
              this.contentModal =
                this.lbls.role_page.modal.content_delete_success;
              this.showModalSuccess();
            }, 500);
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/_mixin.scss";
.manage-page-detail {
  background: #fff;
  overflow: hidden;
  margin-top: 0;
  height: calc(100vh - 250px);
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }
}
.set-permission-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  margin: 0 16px;
  border-bottom: 1px solid $grey-grey-7;
  .ant-divider,
  .ant-divider-vertical {
    top: 0 !important;
    height: 30px;
  }
  .icon-arrow-nex {
    width: 15px;
    cursor: pointer;
    img {
      rotate: 180deg;
      width: 12px;
      margin-top: -2px;
    }
  }
  .logo-custom {
    border: 1px solid var(--grey-grey-8);
    border-radius: 4px;
    img {
      border-radius: 4px;
      width: 40px;
    }
  }
  .label-name-app {
    font-weight: 600;
    font-size: 14px;
    line-height: 1;
  }
  .label-package {
    color: $grey-grey-5;
    font-size: 12px;
    font-weight: normal;
  }
  .label-name-product {
    border: 1px solid;
    border-radius: 25px;
    padding: 0px 8px;
  }
  a {
    color: $color-body;
  }
  .action-list {
    a {
      color: $color-primary-2;
      padding: 0 5px;
    }
  }
}
.set-permission-body {
  padding: 16px;
  display: flex;
  a {
    color: $color-body;
  }
  .box-left {
    width: 250px;
    margin-right: 16px;
    border-right: 1px solid $grey-grey-7;
    overflow-y: auto;
    height: calc(100vh - 330px);
    .item-button-href {
      opacity: 0.7;
      padding: 4px;
      &.href-active {
        border-right: 2px solid $color-primary;
        background: var(--primary-gold-bzb-gold-9);
        font-weight: 500;
        opacity: 1;
        a {
          color: $color-primary;
        }
      }
    }
  }
  .box-right {
    width: calc(100% - 250px);
    .item-product {
      height: calc(100vh - 405px);
      padding-right: 8px;
      padding-top: 8px;
      overflow: auto;
      display: block;
    }
    .test001 {
      background: #f9f9f9;
      padding: 16px;
      margin-bottom: 16px;
      &.test001-active {
        border: 1px solid $color-primary;
      }
    }
    .lable-check-all {
      color: #000;
    }
    .feature-list {
      padding-left: 16px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
    .in-view {
      border: 1px solid $color-primary;
    }
    .set-border-bottom {
      border-bottom: 1px solid $grey-grey-7;
    }
    .item-title-product {
      color: #000;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .checkbox-all {
      border-bottom: 1px solid $grey-grey-10;
      padding-bottom: 12px;
      margin-bottom: 12px;
    }
    .item-title-feature {
      .title-item {
        font-size: 12px;
        opacity: 0.7;
      }
      .label-item {
        background: #fff;
        padding: 12px;
      }
    }
    .title-item-list {
      cursor: pointer;
    }
    a {
      color: var(--primary-gold-bzb-primary-bzb);
      text-decoration: none;
      .anticon {
        margin-top: -2px;
      }
    }
    a:hover {
      color: var(--primary-gold-bzb-gold-6);
      text-decoration: none;
    }
  }
}
.lable-length {
  opacity: 0.7;
  font-size: 12px;
}
.custom-footer-setpermission {
  position: absolute;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: right;
  height: 63px;
  background: #fff;
  bottom: 0;
  width: 100%;
}
</style>
<style lang="scss">
@import "@/style/_mixin.scss";
.set-permission-app {
  .ant-tree {
    li {
      white-space: pre-wrap;
      padding: 3px 10px 3px 5px;
      .ant-tree-switcher {
        line-height: 18px;
        width: 16px;
      }
      .ant-tree-node-content-wrapper {
        width: calc(100% - 50px);
        height: auto;
        cursor: default;
        &.ant-tree-node-selected,
        &:hover {
          background: none;
        }
      }
    }
    &.main-selected {
      .ant-tree-switcher {
        display: none;
      }
      .ant-tree-node-content-wrapper {
        padding-right: 0;
        width: calc(100% - 22px);
        // line-height: 1.2em;
        .ant-tree-title {
          @include flex(flex-end, right);
          vertical-align: top;
          span {
            @include flexcol-100();
          }
        }
        .app-name {
          padding-top: 2px;
          text-align: right;
          color: $color-grey-b4;
          line-height: 1.2em;
          padding-left: 10px;
        }
      }
    }
    .ant-tree-checkbox-disabled {
      display: none;
    }
  }
  .custom-title-checkbox {
    cursor: default;
    font-weight: normal;
  }
}
</style>
