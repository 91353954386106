var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('AppLayout',[_c('div',{staticClass:"campaigns-detail-main campaigns-detail-page page page-create-owner-account",class:`${_vm.$route.name}`},[_c('TitleBreadcrumbs',{attrs:{"databreadcrumbs":_vm.breadcrumbs,"title":_vm.lbls.create_account.title_page}}),(_vm.isShowSkeleton)?_c('a-card',[_c('a-skeleton')],1):_c('div',[_c('a-card',{staticClass:"card-active mt-0"},[_c('a-form',{attrs:{"form":_vm.form,"layout":"vertical"},on:{"submit":_vm.handleSave}},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24}},[_c('div',{staticClass:"d-flex flex-center-between"},[_c('div',[_c('h3',[_vm._v(_vm._s(_vm.lbls.create_account.account_details))])]),_c('div',{staticClass:"d-flex align-items-center"},[_c('a-space',[_c('div',[_vm._v(_vm._s(_vm.lbls.create_account.support))]),_c('a-form-item',{staticClass:"pb-0"},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'is_support',
                            {
                              initialValue: _vm.objDataSave.is_support,
                            },
                          ]),expression:"[\n                            'is_support',\n                            {\n                              initialValue: objDataSave.is_support,\n                            },\n                          ]"}],attrs:{"checked":_vm.objDataSave.is_support},on:{"change":(e) => (_vm.objDataSave.is_support = e)}})],1)],1)],1)]),_c('hr'),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',[_c('template',{slot:"label"},[_c('span',[_vm._v(_vm._s(_vm.lbls.create_account.company_name.name || "Company Name"))])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'account_name',
                          {
                            initialValue: _vm.objDataSave.account_name,
                            rules: [
                              {
                                required: true,
                                message:
                                  _vm.lbls.create_account.company_name.required,
                              },
                              {
                                min: 1,
                                message: _vm.lbls.create_account.company_name.min,
                              },
                            ],
                          },
                        ]),expression:"[\n                          'account_name',\n                          {\n                            initialValue: objDataSave.account_name,\n                            rules: [\n                              {\n                                required: true,\n                                message:\n                                  lbls.create_account.company_name.required,\n                              },\n                              {\n                                min: 1,\n                                message: lbls.create_account.company_name.min,\n                              },\n                            ],\n                          },\n                        ]"}],attrs:{"allow-clear":"","placeholder":_vm.lbls.create_account.company_name.placeholder}})],2)],1),_c('a-col',{attrs:{"span":6}},[_c('a-form-item',[_c('template',{slot:"label"},[_c('span',[_vm._v(_vm._s(_vm.lbls.create_account.owner_email.name || "Owner Email"))])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'email',
                          {
                            initialValue: _vm.objDataSave.email,
                            rules: [
                              {
                                required: true,
                                message:
                                  _vm.lbls.create_account.owner_email.required,
                              },
                              {
                                type: 'email',
                                message:
                                  _vm.lbls.create_account.owner_email.valid,
                              },
                            ],
                          },
                        ]),expression:"[\n                          'email',\n                          {\n                            initialValue: objDataSave.email,\n                            rules: [\n                              {\n                                required: true,\n                                message:\n                                  lbls.create_account.owner_email.required,\n                              },\n                              {\n                                type: 'email',\n                                message:\n                                  lbls.create_account.owner_email.valid,\n                              },\n                            ],\n                          },\n                        ]"}],attrs:{"allow-clear":"","placeholder":_vm.lbls.create_account.owner_email.placeholder},on:{"change":_vm.changeEmail}})],2)],1),_c('a-col',{attrs:{"span":4}},[_c('a-form-item',[_c('template',{slot:"label"},[_c('span',[_vm._v(_vm._s(_vm.lbls.create_account.providers_type.name || "Providers Type"))])]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'email_server',
                          {
                            initialValue: _vm.objDataSave.email_server,
                            rules: [
                              {
                                required: true,
                                message:
                                  _vm.lbls.create_account.providers_type.required,
                              },
                            ],
                          },
                        ]),expression:"[\n                          'email_server',\n                          {\n                            initialValue: objDataSave.email_server,\n                            rules: [\n                              {\n                                required: true,\n                                message:\n                                  lbls.create_account.providers_type.required,\n                              },\n                            ],\n                          },\n                        ]"}],attrs:{"show-search":"","placeholder":_vm.lbls.create_account.providers_type.placeholder}},_vm._l((_vm.optionEmailServer),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value,"disabled":item.is_disabled}},[_c('img',{staticClass:"mr-1",staticStyle:{"max-width":"16px"},attrs:{"src":item.img}}),_vm._v(" "+_vm._s(item.name)+" ")])}),1)],2)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":18}},[_c('a-form-item',[_c('template',{slot:"label"},[_c('span',[_vm._v(_vm._s(_vm.lbls.create_account.description.name || "Description"))])]),_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'account_description',
                          {
                            initialValue: _vm.objDataSave.account_description,
                            rules: [
                              {
                                required: false,
                                message:
                                  _vm.lbls.create_account.description.required,
                              },
                              {
                                min: 1,
                                message: _vm.lbls.create_account.description.min,
                              },
                            ],
                          },
                        ]),expression:"[\n                          'account_description',\n                          {\n                            initialValue: objDataSave.account_description,\n                            rules: [\n                              {\n                                required: false,\n                                message:\n                                  lbls.create_account.description.required,\n                              },\n                              {\n                                min: 1,\n                                message: lbls.create_account.description.min,\n                              },\n                            ],\n                          },\n                        ]"}],attrs:{"allow-clear":"","placeholder":_vm.lbls.create_account.description.placeholder,"rows":4}})],2)],1)],1)],1),_c('a-col',{staticClass:"mt-3",attrs:{"span":24}},[_c('h3',[_vm._v(" "+_vm._s(_vm.lbls.create_account.assign_package_and_application)+" ")]),_c('hr'),_c('a-button',{staticClass:"mb-3",attrs:{"type":"primary"},on:{"click":_vm.addItem}},[_c('a-icon',{attrs:{"type":"plus-circle"}}),_vm._v(" "+_vm._s(_vm.lbls.create_account.btn.add_item)+" ")],1),_c('a-row',{attrs:{"gutter":16}},_vm._l((_vm.dataItems),function(item,index){return _c('div',{key:item.key},[_c('a-col',{attrs:{"span":18}},[_c('a-card',{staticClass:"mb-3"},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',[_c('template',{slot:"label"},[_c('span',[_vm._v(_vm._s(_vm.lbls.create_account.package_list.name || "Packcage"))])]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                  'items[' + index + '].package_id',
                                  {
                                    initialValue: item.package,
                                    rules: [
                                      {
                                        required: true,
                                        message:
                                          _vm.lbls.create_account.package_list
                                            .required,
                                      },
                                    ],
                                  },
                                ]),expression:"[\n                                  'items[' + index + '].package_id',\n                                  {\n                                    initialValue: item.package,\n                                    rules: [\n                                      {\n                                        required: true,\n                                        message:\n                                          lbls.create_account.package_list\n                                            .required,\n                                      },\n                                    ],\n                                  },\n                                ]"}],attrs:{"show-search":"","placeholder":_vm.lbls.create_account.package_list.placeholder},on:{"search":_vm.getListPackage,"change":(e) => {
                                    item.package = e;
                                    _vm.handleChangePackage(e);
                                  }}},_vm._l((_vm.optionPackage),function(optionItem,optionIndex){return _c('a-select-option',{key:optionIndex,attrs:{"value":optionItem.id + '||' + optionItem.name,"disabled":optionItem.is_disabled}},[_vm._v(" "+_vm._s(optionItem.name)+" ")])}),1)],2)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',[_c('template',{slot:"label"},[_c('span',[_vm._v(_vm._s(_vm.lbls.create_account.application_id.name || "Application ID"))])]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                  'items[' + index + '].application_ids',
                                  {
                                    initialValue: item.application,
                                    rules: [
                                      {
                                        required: true,
                                        message:
                                          _vm.lbls.create_account.application_id
                                            .required,
                                      },
                                    ],
                                  },
                                ]),expression:"[\n                                  'items[' + index + '].application_ids',\n                                  {\n                                    initialValue: item.application,\n                                    rules: [\n                                      {\n                                        required: true,\n                                        message:\n                                          lbls.create_account.application_id\n                                            .required,\n                                      },\n                                    ],\n                                  },\n                                ]"}],attrs:{"show-search":"","placeholder":_vm.lbls.create_account.application_id
                                    .placeholder,"mode":"multiple"},on:{"search":(e) => _vm.getListApplication(e),"change":(e) => {
                                    item.application = e;
                                  }}},_vm._l((_vm.filteredOptionsApp),function(optionItem,optionIndex){return _c('a-select-option',{key:optionIndex,attrs:{"value":optionItem.id +
                                    ' (' +
                                    optionItem.name +
                                    ')'}},[_vm._v(" "+_vm._s(optionItem.id)+" ("+_vm._s(optionItem.name)+") ")])}),1)],2)],1)],1)],1)],1),_c('a-col',{attrs:{"span":4}},[(index != 0)?_c('a',{staticClass:"remove",on:{"click":function($event){return _vm.handleRemove(item.key)}}},[_c('a-icon',{attrs:{"type":"delete"}}),_vm._v(" "+_vm._s(_vm.lbls.create_account.btn.remove)+" ")],1):_vm._e()])],1)}),0)],1)],1),_c('div',{staticClass:"custom-footer-create-account"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSave}},[_vm._v(" "+_vm._s(_vm.lbls.role_page.btn.save)+" ")])],1)],1)],1)],1),(_vm.isModalResponse)?_c('div',[_c('ModalAlert',{attrs:{"modalalert":_vm.modalAlert,"alertdata":_vm.alertData},on:{"handlemodalok":_vm.handlemodalOk}})],1):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }